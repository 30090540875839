import {Avatar, Card} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {AvatarGroup} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import {fetchMessengerConfiguration, setActivePageConfig} from "../redux/actions/messengerConfigurationAction";
import {getGraphAPI} from "../config/api/graphFbConfig";
import {setAddPage, updatePage} from "../redux/actions/addPage";

const useStyles = makeStyles((theme) => ({
    headerPage: {
        width: '100%',
        display: "flex",
        gridGap: '10px'
    },
    headerPageOff: {
        backgroundColor: '#FFF',
        display: "flex",
        overflow: 'hidden',
        border: `solid 1px ${theme.palette.primary.main}`,
        borderRadius: '32px',
        '&:hover': {
            background: theme.palette.primary.main,
            color: '#FFF'
        },
    },
    headerPageOn: {
        display: "flex",
        overflow: 'hidden',
        background: theme.palette.primary.main,
        color: '#FFF',
        borderRadius: '32px',
    },
    pageName: {
        fontSize: '18px',
        padding: '16px 10px 8px 0px',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        maxWidth: '150px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    pageAvatar: {
        margin: '7px'
    },
    avatarGroupContainer: {
        padding: "6px"
    },
    avatarIconGroup: {
        border: 'solid 1px green',
        padding: '2px'
    }
}))


export default function SwitchFbPages(props) {
    const classes = useStyles();
    const pg = localStorage.getItem('pg');
    const [activeTab, setActiveTab] = useState(0);
    const dispatch = useDispatch();
    const pageList = useSelector(state => state.pageDetail?.page_list) || [];
    const [flag, setFlag] = useState(0);

    let pageDetail;

    useEffect(() => {
        const local_pg_list = JSON.parse(pg)
        pageDetail = local_pg_list[activeTab]

        dispatch(setAddPage(local_pg_list))
        dispatch(setActivePageConfig(pageDetail.id, props.config_type))
        setFlag(1)
    }, [pg])

    useEffect(() => {
        if (flag === 1) {
            async function getPageProfileImage() {
                try {
                    pageList.map((value, i) => {
                        async function GetPagePicture() {
                            try {
                                let response = await getGraphAPI(`/${value.page_id}/picture`, {"redirect": "0"})
                                value['image'] = response.request.responseURL
                                value['index'] = i
                                dispatch(updatePage(value))
                            } catch (e) {
                                console.log('Image Failed to fetched :', e)
                            }
                        }

                        GetPagePicture()
                    })
                } catch (e) {
                    console.log(e)
                }
            }

            getPageProfileImage().then(() => {
            });
        }
    }, [flag])

    const handleClick = (index) => {
        setActiveTab(index)
        pageDetail = pageList[index]
        dispatch(setActivePageConfig(pageDetail.id, props.config_type))
    }


    return (
        <>
            <div className={classes.headerPage}>
                {flag === 1 && pageList.map((value, i) => {
                    if (i === activeTab) {
                        return (
                            <div className={(i === activeTab) ? classes.headerPageOn : classes.headerPageOff}
                                 onClick={value => {
                                     return handleClick(i)
                                 }} key={i}>
                                <Avatar className={classes.pageAvatar} src={value.image}/>
                                <Typography className={classes.pageName} gutterBottom>
                                    {value.name}
                                </Typography>
                            </div>
                        )
                    }
                })}
                <AvatarGroup max={5} className={classes.avatarGroupContainer}>
                    {flag === 1 && pageList.map((value, i) => {
                        if (i !== activeTab) {
                            return (
                                <Avatar className={classes.avatarIconGroup} src={value.image} onClick={value => {
                                    return handleClick(i)
                                }} key={i}/>
                            )
                        } else {
                            return null;
                        }
                    })}
                </AvatarGroup>
            </div>

        </>
    )
}
