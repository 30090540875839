import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {ThemeProvider} from "@material-ui/core/styles";
import axios from "axios";
import config from "./config";
import {Provider} from "react-redux";
import {store} from "./redux/store";
import {BrowserRouter} from "react-router-dom";
import {createTheme} from "@material-ui/core";


const theme = createTheme({
    palette: {
        primary: {
            main: "#0477a8",
        },
        secondary: {
            main: "#393E46",
        },
        action: {
            hover: "#FFFFFF",
        },
        background: {subroot: '#ECEFF2'}

    },
    icon: {
        primary: {
            color: '#393E46'
        },
        action: {
            hover: '#0477a8'
        }
    },
    text: {
        primary: {
            fontSize: '30px',
            color: '#393E46',
            hover: "#FFFFFF",
            selected: '#0477a8'
        },
        secondary: {
            fontSize: '20px',
            color: '#393E46',
            hover: "#FFFFFF",
            selected: '#0477a8'
        }
    },
    layout: {
        margin: '10px 0',
    }
});

axios.defaults.baseURL = config.BASE_URL;
ReactDOM.render(
    // <React.StrictMode>
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            {/*forceRefresh={true}*/}
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </ThemeProvider>,
    // </React.StrictMode>,
    document.getElementById("root")
)
;
