import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Avatar,
    Card,
    CardContent,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import ImgPageImportBanner from "../../../assest/fb-messenger.png"
import {useNavigate} from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import {postAPI} from "../../../config/api/config";
import {useInitFbSDK} from "../../../hooks/fb-hooks";
import SecondaryButton from "../../../reuseableComponents/secondaryButton";
import localStorage from "redux-persist/es/storage";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: "100%",
        height: theme.spacing(68),
        marginTop: theme.spacing(10),
        padding: theme.spacing(6),
    },
    details: {
        width: "50%",
        padding: "1%",
        // display: 'flex',
        flexDirection: 'column',
        height: 'fit-content'
    },
    content: {
        flex: '1 0 auto',
    },
    subject: {
        display: 'inline',
        width: "60%",
        height: theme.spacing(10),
        margin: theme.spacing(10, 2),
        padding: theme.spacing(2),
    },
    cover: {
        width: '60%',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    pageList: {
        width: "50%",
        paddingTop: theme.spacing(10),
        padding: theme.spacing(20),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

export default function AddFacebookPages(props) {
    useInitFbSDK();
    const classes = useStyles();
    const navigate = useNavigate();
    const [fbUserAccessToken, setFbUserAccessToken] = useState()
    const [userId, setUserId] = useState()
    const [isLoggedIn, setLoginStatus] = useState(false)
    const [page, setPage] = useState([])

    const createPageSubscription = async (value) => {
        const userAuth_ = await localStorage.getItem("user_auth")
        const userAuth = JSON.parse(userAuth_)
        const data = {
            page_id: value.id,
            access_token: value.access_token,
            category: value.category,
            name: value.name,
            picture: value.picture,
            user_access_token: userAuth.social_detail.access_token
        }
        let response = await postAPI("/api/page_subscription/create", data, userAuth.access_token);
        console.log(response)
        if (response.status === 200) {
            let res = JSON.parse(response.data)
        } else {
            console.log("Fail to add page")
        }
    }

    const updateFbAccessToken= async (latestAccessToken)=>{
        const userAuth_ = await localStorage.getItem("user_auth")
        const user_Auth = JSON.parse(userAuth_)
        user_Auth['social_detail'] = {access_token:latestAccessToken}
        localStorage.setItem('user_auth',JSON.stringify(user_Auth))
    }

    const pagePermission = async () => {
        try {
            window.FB.login((response) => {
                if (response.status === "connected") {
                    setFbUserAccessToken(response.authResponse.accessToken);
                    setUserId(response.authResponse.userID);
                    setLoginStatus(true);
                    updateFbAccessToken(response.authResponse.accessToken)

                    // Page added to the system by logged in User
                    window.FB.api(
                        `/${response.authResponse.userID}/accounts`, "GET", {access_token: `${response.authResponse.accessToken}`}, function (response) {
                            if (response) {
                                response.data.forEach((value, i) => {

                                    // Fetch Picture of respective page
                                    window.FB.api(
                                        `/${value.id}/picture`, {"redirect": "0"}, function (response) {
                                            if (response) {
                                                value["picture"] = response.data.url
                                                setPage(old_val => [...old_val, value]);

                                                //Save your subscribed pages to your database and [value] must have parameter picture
                                                createPageSubscription(value);
                                            } else {
                                                return "Picture cannot be fetched"
                                            }
                                        }
                                    );

                                    //subscribe fields to allow webhooks
                                    window.FB.api(
                                        `/${value.id}/subscribed_apps`, "POST", {
                                            subscribed_fields: `messages,messaging_postbacks`,
                                            access_token: `${value.access_token}`
                                        }, function (response) {
                                            if (response) {
                                                console.log("Success subscribed")
                                            } else {
                                                console.log("Failed subscribed")
                                            }
                                        }
                                    );

                                    // await postGraphAPI(`/${value.id}/subscribed_apps`,{
                                    //     subscribed_fields: `messages,messaging_postbacks`,
                                    //     access_token: `${value.access_token}`
                                    // })

                                });
                                return "success";
                            } else {
                                return "Failed to fetch Pages"
                            }
                        }
                    );
                    return "success"
                } else {
                    return "Failed to login"
                }
            }, {scope: 'pages_show_list, pages_messaging'});
            // }, {scope: 'pages_show_list, user_messenger_contact, pages_messaging, pages_manage_metadata'});

        } catch (e) {
            console.log("Failed to subscribe page")
        }
    }

    const ViewPermisison = async () => {
        window.FB.api(
            `/${userId}/permissions`, "GET", {access_token: `${fbUserAccessToken}`}, function (response) {
                if (response) {
                    console.log(response);
                }
            }
        );
    }
    const DeletePermission = async () => {
        window.FB.api(
            `/${userId}/permissions`, "DELETE", {access_token: `${fbUserAccessToken}`}, function (response) {
                if (response) {
                    console.log(response);
                }
            }
        );
    }


    const moduleAction = async () => {
        const user_auth_ = await localStorage.getItem('user_auth');
        let user_auth = JSON.parse(user_auth_);
        if (user_auth.access_token) {
            let response = await postAPI("/api/page_subscription/detail", {}, user_auth.access_token);
            if (response.status === 200) {
                let json_res_data = JSON.parse(response.data);
                localStorage.setItem("pg", JSON.stringify(json_res_data.data));
                navigate("/dashboard");
            }
        }
    }

    return (
        <>
            <Card className={classes.root}>
                <div className={classes.details}>
                    <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5">
                            Import Page to manage and access page conversations in Messenger with HeapChat
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary">
                            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to
                            demonstrate the visual form of a document or a typeface without relying on meaningful
                            content. Lorem ipsum may be used as a placeholder before final copy is available.
                        </Typography>
                    </CardContent>

                    {!isLoggedIn && <div className={classes.controls}>
                        <SecondaryButton nameButton={"Add Pages"} triggerAction={pagePermission}/>
                    </div>}


                    {isLoggedIn && <div className={classes.controls}>
                        <SecondaryButton nameButton={"Add Pages from another account"} triggerAction={pagePermission}/>
                    </div>}
                    {isLoggedIn && <div className={classes.controls}>
                        <SecondaryButton nameButton={"Delete Permission"} triggerAction={DeletePermission}/>
                    </div>}

                    {/*<div className={classes.controls}>*/}
                    {/*    <SecondaryButton nameButton={"View Permission"} triggerAction={ViewPermisison}/>*/}
                    {/*</div>*/}

                    {isLoggedIn &&
                        <div className={classes.controls}>
                            <SecondaryButton nameButton={"Let's Start"} triggerAction={moduleAction}/>
                        </div>}
                </div>
                {!isLoggedIn && <CardMedia
                    className={classes.cover}
                    image={ImgPageImportBanner}
                    title="Live from space album cover"
                />}
                {isLoggedIn && <CardContent className={classes.pageList}>
                    <Typography component="h5" variant="h5">
                        Your Pages
                    </Typography>
                    <Divider variant="inset"/>
                    {page.map((object, i) => {
                        return (
                            <>
                                <ListItem key={i}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CardMedia
                                                component="img"
                                                src={object.picture}
                                                // onLoad={PagePicture(object.id)}
                                                alt="Live from space album cover"
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={object.name} secondary={object.category_list[0].name}/>
                                </ListItem>
                                <Divider variant="inset"/>
                            </>)
                    })}
                </CardContent>}


            </Card>
        </>
    );
}
