import React, {useEffect} from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import LoginForm from "../components/loginForm";
import {Navigate} from "react-router-dom";
import CustomizedSnackbars from "../../../reuseableComponents/snackbar/snackbar";
// import TextFieldWrapper from "../../reuseableComponents/Form_UI/TextFieldWrapper";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    image: {
        backgroundImage: "url(https://source.unsplash.com/random)",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignInSide() {
    const classes = useStyles();
    const [open, setOpen] = React.useState({status: false, message: "", type: ""});

    useEffect(() => {
        const interval = window.setTimeout(() => {
            setOpen({status: false, message: "", type: ""})
        }, 6000);

        return () => {
            clearInterval(interval)
        }
    }, [open])

    useEffect(() => {
        if (localStorage.getItem("isLogged") === 'true' && localStorage.getItem("profile_status") === 'true') {
            return <Navigate to="/dashboard"/>;
        }
    }, [])


    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>

            <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                <LoginForm setOpen={setOpen}/>
                <Grid container>
                    <Grid item xs={12}>
                        <CustomizedSnackbars open={open} setOpen={setOpen}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={false} sm={4} md={8} className={classes.image}/>
        </Grid>
    );
}
