import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import {NativeSelect} from "@material-ui/core";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import {ReactSortable} from "react-sortablejs";
import {MessageReceivedContainer, MessageTextButton} from "./messengerComponent";
import Box from "@material-ui/core/Box";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import useStyles from "./flowStyles"
import {
    addFlowComponentPlainData, addFlowQuickReplyPlainData,
    contentControllerOpen,
    setFlowPlainData,
    updateFlowPlainData
} from "../../../redux/actions/automation";
import {useDispatch, useSelector} from "react-redux";
import ShortTextRoundedIcon from '@material-ui/icons/ShortTextRounded';
import InsertPhotoRoundedIcon from '@material-ui/icons/InsertPhotoRounded';
import ViewDayRoundedIcon from '@material-ui/icons/ViewDayRounded';
import ViewCarouselRoundedIcon from '@material-ui/icons/ViewCarouselRounded';
import RecordVoiceOverRoundedIcon from '@material-ui/icons/RecordVoiceOverRounded';
import OndemandVideoRoundedIcon from '@material-ui/icons/OndemandVideoRounded';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import TouchAppRoundedIcon from '@material-ui/icons/TouchAppRounded';
import clsx from "clsx";

export default function ContentContainer(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const automationData = useSelector(state => state.automation)
    let innerContentList = useSelector(state => {
        const flowPlainData = state.automation?.flowPlainData || [];
        return flowPlainData[automationData.activeFlowContainerPath]?.inner_content || []
    })
    const [flag, setFlag] = useState(false)

    const ContentContainerClose = () => {
        dispatch(contentControllerOpen(false))
    }

    let actionTypeList = [
        {
            key: 'quick_reply',
            name: 'Quick Replay',
            icon: <TouchAppRoundedIcon className={classes.contentContainerIcon}/>,
            type: '',
        },
        {
            key: 'text',
            name: 'Text',
            icon: <ShortTextRoundedIcon className={classes.contentContainerIcon}/>,
            type: 'text'
        },
        {
            key: 'image',
            name: 'Image',
            icon: <InsertPhotoRoundedIcon className={classes.contentContainerIcon}/>,
            type: ''
        },
        {
            key: 'text_button',
            name: 'Card',
            icon: <ViewDayRoundedIcon className={classes.contentContainerIcon}/>,
            type: '',
        },
        {
            key: 'gallery',
            name: 'Gallery',
            icon: <ViewCarouselRoundedIcon className={classes.contentContainerIcon}/>,
            type: ''
        },
        {
            key: 'audio',
            name: 'Audio',
            icon: <RecordVoiceOverRoundedIcon className={classes.contentContainerIcon}/>,
            type: '',
        },
        {
            key: 'video',
            name: 'Video',
            icon: <OndemandVideoRoundedIcon className={classes.contentContainerIcon}/>,
            type: '',
        },
        {
            key: 'file',
            name: 'File',
            icon: <AttachFileRoundedIcon className={classes.contentContainerIcon}/>,
            type: '',
        }
    ]

    const handleChange = (event) => {
        // const name = event.target.name;
        // setState({
        //     ...state,
        //     [name]: event.target.value,
        // });
    };

    const onSortingComponent = (value) => {
        dispatch(updateFlowPlainData(automationData.activeFlowContainerPath, value))
        setFlag(true)
    }

    useEffect(() => {
        setFlag(false)
    }, [flag])

    const addMessageComponent = (key) => {
        switch (key) {
            case 'text':
                let newTextContent = {
                    id: innerContentList.length + 1,
                    element_type: 'MessageReceived',
                    data: {
                        text: ''
                    }
                }
                dispatch(addFlowComponentPlainData(automationData.activeFlowContainerPath, newTextContent))
                return props.isContentSorted ? props.setContentSorted(false) : props.setContentSorted(true)

            case 'text_button':
                let newTextButton = {
                    id: innerContentList.length + 1,
                    element_type: 'TextButton',
                    button_count: 1,
                    data: {
                        text: '',
                        button: [
                            {
                                button_name: 'Button 1',
                                // phone number, web link, post back
                                button_type: '',
                                button_value: ''
                            }]
                    }
                }
                dispatch(addFlowComponentPlainData(automationData.activeFlowContainerPath, newTextButton))
                return props.isContentSorted ? props.setContentSorted(false) : props.setContentSorted(true)

            case 'image':
                let imageObj = {
                    id: innerContentList.length + 1,
                    element_type: 'Image',
                    data: {
                        type: '',
                        url: '',
                        path: ''
                    }
                }
                dispatch(addFlowComponentPlainData(automationData.activeFlowContainerPath, imageObj))
                return props.isContentSorted ? props.setContentSorted(false) : props.setContentSorted(true)

            case 'gallery':
                let newGenericTemplate = {
                    id: innerContentList.length + 1,
                    element_type: 'MessageGenericTemplate',
                    data: [
                        {
                            media: '',
                            header: 'Enter Header',
                            subText: 'Enter description',
                            button_count: 0,
                            button: [] // {button_name, button_type, button_value}
                        }
                    ]
                }

                dispatch(addFlowComponentPlainData(automationData.activeFlowContainerPath, newGenericTemplate))
                return props.isContentSorted ? props.setContentSorted(false) : props.setContentSorted(true)

            case 'quick_reply':
                let quickReplyObj = innerContentList.find(obj => obj.element_type === 'QuickReply')
                if (quickReplyObj) {
                    let obj = {
                        title: '',
                        content_type: '',
                        payload: ''
                    }
                    dispatch(addFlowQuickReplyPlainData(automationData.activeFlowContainerPath, obj))

                } else {
                    let quick_reply = {
                        id: innerContentList.length + 1,
                        element_type: 'QuickReply',
                        data: {
                            text: '',
                            quick_replies: [{
                                title: '',
                                content_type: '',
                                payload: ''
                            }]
                        } // 13 values possible
                    }
                    dispatch(addFlowComponentPlainData(automationData.activeFlowContainerPath, quick_reply))
                }

                return props.isContentSorted ? props.setContentSorted(false) : props.setContentSorted(true)


            case 'audio':
                let audioObj = {
                    id: innerContentList.length + 1,
                    element_type: 'Audio',
                    data: {
                        type: '',
                        url: '',
                        path: ''
                    }
                }
                dispatch(addFlowComponentPlainData(automationData.activeFlowContainerPath, audioObj))
                return props.isContentSorted ? props.setContentSorted(false) : props.setContentSorted(true)

            case 'video':
                let videoObj = {
                    id: innerContentList.length + 1,
                    element_type: 'Video',
                    data: {
                        type: '',
                        url: '',
                        path: ''
                    }
                }
                dispatch(addFlowComponentPlainData(automationData.activeFlowContainerPath, videoObj))
                return props.isContentSorted ? props.setContentSorted(false) : props.setContentSorted(true)

            case 'file':
                let attachmentObj = {
                    id: innerContentList.length + 1,
                    element_type: 'Attachment',
                    data: {
                        type: '',
                        url: '',
                        path: ''
                    }
                }
                dispatch(addFlowComponentPlainData(automationData.activeFlowContainerPath, attachmentObj))
                return props.isContentSorted ? props.setContentSorted(false) : props.setContentSorted(true)
        }
    }

    return (
        <div className={classes.contentContainer}>
            <div>
                <div style={{textAlign: 'right', margin: '10px 10px 10px auto'}}
                     onClick={ContentContainerClose}><CloseRoundedIcon/>
                </div>
            </div>
            <div className={classes.topBar}>

                <div className={classes.directionContent}>
                    <ArrowBackIosRoundedIcon className={clsx(classes.contentContainerIcon)}/>
                </div>
                <div className={classes.actionType}>
                    {/*<FormControl className={classes.formControl}>*/}
                    <NativeSelect
                        value={"sd"}
                        onChange={handleChange}
                        name="age"
                        className={classes.selectEmpty}
                        inputProps={{'aria-label': 'age'}}
                    >
                        <option value="">None</option>
                        <option value={10}>Ten</option>
                        <option value={20}>Twenty</option>
                        <option value={30}>Thirty</option>
                    </NativeSelect>
                    {/*<FormHelperText>With visually hidden label</FormHelperText>*/}
                    {/*</FormControl>*/}
                </div>
                <div className={classes.directionContent}>
                    <ArrowForwardIosRoundedIcon className={clsx(classes.contentContainerIcon)}/>
                </div>
            </div>
            <div className={classes.bodyTemplate}>
                {/*<ReactSortable className={classes.sortableContainer}*/}
                {/*               animation={200}*/}
                {/*               delayOnTouchStart={true}*/}
                {/*               handle={'.myhandler'}*/}
                {/*               list={innerContentList}*/}
                {/*               setList={(value) => (onSortingComponent(value))}>*/}
                {innerContentList.map((content, i) => {

                    return (<MessageReceivedContainer msgItem={content}
                                                      flowAsset={false}
                                                      setLoadedContent={props.setLoadedContent}
                                                      isContentLoaded={props.isContentLoaded}
                                                      containerPath={automationData.activeFlowContainerPath}
                                                      componentPath={i}
                                                      key={i}
                    />)

                })}
                {/*</ReactSortable>*/}
            </div>
            <Box className={classes.messageContentType}>
                {actionTypeList.map((value, i) => {
                    return (
                        <div className={classes.actionItem} key={i}
                             onClick={() => addMessageComponent(value.key)}>{value.icon}</div>
                    )
                })}
            </Box>
        </div>
    )
}
