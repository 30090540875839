import React from "react";
import config from "../config"

// Injects the Facebook SDK into the page
const injectFbSDKScript = () => {
    (function (d, s, id) {
        var js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
};

export const useInitFbSDK = () => {
    const [isInitialized, setIsInitialized] = React.useState(false);

    // Initializes the SDK once the script has been loaded
    // https://developers.facebook.com/docs/javascript/quickstart/#loading
    window.fbAsyncInit = function () {
        window.FB.init({
            // appId: "198003718892789",
            appId: config.FB_CLIENT_ID,
            cookie: true,
            xfbml: true,
            status:true,
            oauth:true,
            version: "v12.0",
        });

        window.FB.AppEvents.logPageView();
        setIsInitialized(true);
    };

    injectFbSDKScript();
    return isInitialized;
};