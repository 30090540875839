import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: theme.text.primary.fontSize,
    padding: theme.layout.margin,
    color: theme.text.primary.color,
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    gridGap: '10px',
  },
}))


export default function UserProfile() {
  const classes = useStyles();

  return (
    <>
      <Container maxWidth={false}>
        <Typography sx={{fontSize: 24}} className={classes.header} color="textSecondary">
          Profile
        </Typography>
        <div className={classes.mainContainer}>
          Profile ......
        </div>
      </Container>
    </>
  )
}
