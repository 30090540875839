import {setAddPage} from "../redux/actions/addPage";

const {postAPI} = require("../config/api/config");

export const fetchInitialData = async (dispatch) => {
    const user_auth = JSON.parse(localStorage.getItem("user_auth"));
    let response = await postAPI("/api/page_subscription/detail", {}, user_auth.access_token);
    if (response.status === 200) {
        let json_res_data = JSON.parse(response.data);
        if (json_res_data.data.length) {
            json_res_data.data.forEach(obj => {
                obj['picture'] = ''
                localStorage.setItem("pg", JSON.stringify(json_res_data.data));
                dispatch(setAddPage(json_res_data))
            });
            return true;
        }
        return false;
    }
    return false;
}
