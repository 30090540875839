import {TextareaAutosize} from "@material-ui/core";
import React, {useEffect, useRef, useState} from "react";
import clsx from "clsx";
import useStyles from "../flowStyles";
import {
    activeFlowComponentPath, updateInnerContentData
} from "../../../../redux/actions/automation";
import {useDispatch, useSelector} from "react-redux";
import {Handle} from "react-flow-renderer";
import DragIndicatorRoundedIcon from "@material-ui/icons/DragIndicatorRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import config from "../../../../config";
import IconButton from "@material-ui/core/IconButton";
import ImageSearchRoundedIcon from "@material-ui/icons/ImageSearchRounded";
import InsertLinkRoundedIcon from "@material-ui/icons/InsertLinkRounded";
import {uploadFile} from "../../../../helper/uploadFile";
import {setSnackBar} from "../../../../redux/actions/snackbar";
import DescriptionIcon from '@material-ui/icons/Description';

export default function Attachment(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const initialData = useSelector(state => {
        const flowPlainData = state.automation?.flowPlainData || [];
        const inner_content = flowPlainData[props?.containerPath]?.inner_content || [];
        return inner_content[props?.componentPath]?.data || {}
    })
    const activeComponentId = useSelector(state => state.automation.activeFlowComponentPath)
    const activeContainerPath = useSelector(state => state.automation.activeFlowContainerPath)


    const clickComponentOnContainer = (e) => {
        dispatch(activeFlowComponentPath(props.componentPath))
    }

    useEffect(() => {
        console.log(props.componentPath)
    }, []);


    async function onlyMedia(e) {
        try {
            console.log(e.target.files[0])
            if (e.target.files[0].type === "application/msword" || e.target.files[0].type === "application/vnd.ms-excel" || e.target.files[0].type === "application/vnd.ms-powerpoint" || e.target.files[0].type === "application/pdf") {
                if (e.target.files[0].size < 26214400) {
                    let response = await uploadFile(e.target.files[0])
                    if (response.status === 200) {
                        const res_data = JSON.parse(response.data)
                        const obj = {
                            type: 'attachment',
                            data: {
                                type: "path", url: '', path: res_data.path
                            }
                        }
                        dispatch(updateInnerContentData(props.containerPath, props.componentPath, obj))
                        props.isContentLoaded ? props.setLoadedContent(false) : props.setLoadedContent(true)
                    } else {
                        dispatch(setSnackBar({status: true, message: 'Failed to upload. Retry it.', alert: "error"}))
                    }
                } else {
                    dispatch(setSnackBar({
                        status: true,
                        message: 'File size should be less than 25MB',
                        alert: "error"
                    }))
                }

            } else {
                dispatch(setSnackBar({
                    status: true,
                    message: 'File Type must be pdf excel',
                    alert: "error"
                }))
            }


        } catch (e) {
            dispatch(setSnackBar({status: true, message: 'Failed to upload. Retry it.', alert: "error"}))
        }
    }

    async function onlyMediaUrl(url) {
        const obj = {
            type: 'attachment', data: {
                type: 'url', url: url, path: ''
            }
        }
        dispatch(updateInnerContentData(props.containerPath, props.componentPath, obj))
    }

    return (<>
        {props.flowAsset ? <div className={clsx(classes.msgReceivedImageContainer, classes.flowComponent)}>
            {initialData.type ? <TextareaAutosize
                disabled
                className={clsx(classes.InputLink, classes.msgTextArea, (activeComponentId === props.componentPath) ? classes.msgActive : classes.msgRoot)}
                value={initialData.type === 'path' ? config.BASE_URL + '/' + initialData.path : initialData.url}/> : ''}
        </div> : <>
            <div className={clsx(classes.msgEditableContainer)}>
                <div className={classes.msgImg}>
                    <img alt={"reply-message"} style={{width: '100%', height: '100%'}}
                         src={'https://pbblogassets.s3.amazonaws.com/uploads/2020/10/23073326/smash-cut-audio-cover.jpg'}></img>

                    <div className={classes.imageButtonUrl} onClick={() => clickComponentOnContainer()}>
                        <input
                            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf"
                            hidden id="icon-button-file" onChange={(e) => onlyMedia(e)} type="file"/>
                        <label htmlFor="icon-button-file">
                            <IconButton color="primary" aria-label="upload picture" component="span">
                                <DescriptionIcon/>
                            </IconButton>
                        </label>
                        |
                        <label htmlFor="icon-button-link" onClick={() => onlyMediaUrl('')}>
                            <IconButton color="primary" aria-label="upload picture" component="span">
                                <InsertLinkRoundedIcon/>
                            </IconButton>
                        </label>
                    </div>
                </div>
                <div className={clsx(classes.msgAction, 'myhandler')}>
                    <DragIndicatorRoundedIcon/>
                </div>
                <div className={clsx(classes.msgAction)}><CloseRoundedIcon/></div>

            </div>
            <TextareaAutosize
                className={clsx(classes.InputLink, classes.msgTextArea, (activeComponentId === props.componentPath) ? classes.msgActive : classes.msgRoot)}
                placeholder={"Enter Audio Link"}
                value={initialData.type === 'path' ? config.BASE_URL + '/' + initialData.path : initialData.url}
                onClick={() => clickComponentOnContainer()}
                onChange={(e) => onlyMediaUrl(e.target.value)}/>
        </>}
    </>)
}