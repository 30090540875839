import { ActionTypes } from "../contants/action-types";
export const setAddPage = (page_list) => {
    return {
        type: ActionTypes.SET_ADD_PAGE_DATA,
        payload: page_list,
    };
};

export const updatePage = (page_list) => {
    return (dispatch, getState) => {
        const {pageDetail} = getState();
        dispatch({
            type: ActionTypes.UPDATE_PAGE_DATA,
            pageDetail: pageDetail,
            payload: page_list
        })
    };
};