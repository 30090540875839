async function LoginFacebook(setUserId, setFbUserAccessToken) {
    try {
        await window.FB.login((response) => {
            if (response.authResponse) {
                setFbUserAccessToken(response.authResponse.accessToken);
                setUserId(response.authResponse.userID);
                return true
            } else {
                return false
            }
        }, {scope: 'email, public_profile'});
    } catch (e) {
        return false
    }
}

export {LoginFacebook}