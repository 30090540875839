import Typography from "@material-ui/core/Typography";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Avatar, Button, Container, TextField} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import '../index.css'
import AutomationFlowDiagram from "../components/flowDiagram";
import {postAPI} from "../../../config/api/config";
import {useDispatch, useSelector} from "react-redux";
import {setSnackBar} from "../../../redux/actions/snackbar";
import {fetchAutomationFlowWithUuid, updateAutomationFlow, updateFlowName} from "../../../redux/actions/automation";
import {useParams} from "react-router-dom";
import TextCounterPopper from "../../../reuseableComponents/popper/textCounterPopper";

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: theme.text.primary.fontSize,
        padding: theme.layout.margin,
        color: theme.text.primary.color,
    },
    automationWrapper: {
        height: '100%',
    },
    controllersContainer: {
        display: "flex",
    },
    button: {
        margin: theme.spacing(1),
    },
}))


export default function MyAutomation() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const flow_name = useSelector(state => state.automation?.flow_name || '')
    const onSave = () => {
        dispatch(updateAutomationFlow())
    }

    const changeFlowName = (name) => {
        dispatch(updateFlowName(name))
    }

    const {uuid} = useParams();

    // On Load Page Fetching Flow Plain Data from Server
    useEffect(() => {
        dispatch(fetchAutomationFlowWithUuid(uuid))
    }, [uuid])

    return (

        <Container maxWidth={false}>
            <div className={classes.automationWrapper}>
                <div className={classes.controllersContainer}>
                    <Typography sx={{fontSize: 24}} className={classes.header} color="textSecondary">
                        Automation
                    </Typography>
                    <div style={{width: '400px', padding: "10px 38px", display: 'flex'}}>
                        <TextField id="outlined-basic" variant="outlined" style={{minWidth: '200px'}}
                                   onChange={(e) => changeFlowName(e.target.value)}
                                   value={flow_name}/>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                            onClick={() => onSave()}>
                            Save
                        </Button>
                    </div>
                </div>
                <AutomationFlowDiagram/>
            </div>
            <TextCounterPopper/>
        </Container>
    )
}
