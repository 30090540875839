import axios from "axios";
import config from "../../config";


export const instance = axios.create({
    baseURL: config.BASE_URL,
    timeout: 10000,
});

export const getAPI = async (url, param, token) => {
    let response;
    try {
        response = await instance({
            method: "GET",
            url: `${url}`,
            params: param,
            headers: {
                "access_token": token,
            },
            transformResponse: [
                function (responseData) {
                    return responseData
                    // return JSON.parse(responseData)
                }
            ],
        });
    } catch (error) {
        return (error.response)
    }
    return response
};


export const postAPI = async (url, data, token) => {
    let response;
    try {
        response = await instance({
            method: "POST",
            url: config.BASE_URL + url,
            data: data,
            headers: {
                "access_token": token,
                // "Content-Type":"multipart/form-data     // For File Type multipart/form-data should be set
            },
            transformResponse: [
                function (responseData) {
                    // console.log(responseData)
                    return responseData
                }
            ],
        });
    } catch (error) {
        return (error.response)
    }
    return response
}


export const resData = async (resData) => {
    let resDataJson = JSON.parse(resData.data);
    let resDataString = JSON.stringify(resData.data);
    return {
        json: resDataJson,
        string: resDataString
    }

}
