import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
// import {useSpring, animated} from 'react-spring/web.cjs';
import {useDispatch, useSelector} from "react-redux"; // web.cjs is required for IE 11 support

const useStyles = makeStyles((theme) => ({
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
    const {in: open, children, onEnter, onExited, ...other} = props;
    // const style = useSpring({
    //     from: {opacity: 0},
    //     to: {opacity: open ? 1 : 0},
    //     onStart: () => {
    //         if (open && onEnter) {
    //             onEnter();
    //         }
    //     },
    //     onRest: () => {
    //         if (!open && onExited) {
    //             onExited();
    //         }
    //     },
    // });
    //
    // return (
    //     <animated.div ref={ref} style={style} {...other}>
    //         {children}
    //     </animated.div>
    // );
});

Fade.propTypes = {
    children: PropTypes.element,
    in: PropTypes.bool,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
};

export default function TextCounterPopper() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const target = useSelector(state => state.textCounterPopper.target)
    // const [anchorEl, setAnchorEl] = React.useState(null);

    // const handleClick = (event) => {
    //     setAnchorEl(anchorEl ? null : event.currentTarget);
    // };

    // const open = Boolean(anchorEl);
    const open = Boolean(target);
    const id = open ? 'spring-popper' : undefined;

    return (
        <div>
            <Popper id={id} open={open} anchorEl={target} transition>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps}>
                        <div className={classes.paper}>The content of the Popper.</div>
                    </Fade>
                )}
            </Popper>
        </div>
    );
}