import React, {useEffect, useRef, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from "@material-ui/core/styles";
import "../index.css";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Chip, InputBase, TextareaAutosize, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import Paper from "@material-ui/core/Paper";
import Carousel from "react-material-ui-carousel";
import '../index.css'
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import PermContactCalendarRoundedIcon from '@material-ui/icons/PermContactCalendarRounded';
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import {assignTagCustomer} from "../../../redux/actions/pageContact";
import ProfileTags from "./profileTags";
import {setSnackBar} from "../../../redux/actions/snackbar";
import LanguageIcon from '@material-ui/icons/Language';
import { FaTransgenderAlt, FaLanguage } from "react-icons/fa";

const useStyle = makeStyles((theme) => ({
    profileMainContainer: {
        display: 'flex',
        gridGap: '22px',
        height: '520px'
    },
    highlightContainer: {
        width: 'fit-content',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    profileAvatar: {height: '50%', width: 'fit-content', margin: '0px auto'},
    profileImage: {width: '200px', height: '200px'},
    profileHighlight: {height: '60%', width: '100%', textTransform: 'capitalize'},
    detailContainer: {
        width: '70%',
        height: 'fit-content',
        borderRadius: '10px',
        display: 'flex',
        gridGap: '10px',
        flexDirection: 'column',
        boxShadow: 'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
        padding: '10px'

    }, profileName: {
        width: 'fit-content',
        margin: '10px auto',
        fontSize: '22px',
        color: theme.text.primary.color,
        fontWeight: 'bold',
    },profileMore: {
        margin: '10px auto',
        fontSize: '16px',
        color: theme.text.primary.color,
    },
    labels: {
        fontSize: '16px',
        margin: '6px 0px',
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },
    tags: {
        display: 'flex',
        gridGap: '10px'
    },
    quickChatElements: {
        display: 'flex',
        margin: '10px auto 10px 0px',
    },
    sendText: {
        width: '218px',
        height: 'fit-content',
        padding: '12px 8px',
        fontFamily: 'SF UI Text, Regular',
        fontSize: '17px',
        lineHeight: '20px',
        textAlign: 'left',
        backgroundColor: '#F1F0F0',
        // border:'dotted 2px #6a7fd28a',
        borderRadius: '18px',
        border: `solid 1px ${theme.palette.primary.main} !important`
    },
    msgTextArea: {
        resize: 'none',
        border: 'none',
        overflow: 'auto',
        outline: 'none'
    }, button: {
        margin: '4px 10px',
        color: '#0084FF',
        width: '40px',
        height: '40px',
        '&:hover': {
            margin: '4px 15px',
            color: '#0084FF'
        }
    }, savedContent: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        overflow: 'auto',
        overflowX: 'scroll',
        overflowY: 'hidden',/* Hide Vertical scrollbar */
        gridGap: '10px',
        flexDirection: 'row',
        scrollbarWidth: 'none'
    }, savedItem: {
        minWidth: '180px',
        height: '80px',
        // boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
        padding: '6px',
        overflow: 'hidden',
        margin: '10px'
    },
    chipList: {
        display: 'block',
        padding: '10px',
        width: '400px'
    }, selectItem: {
        margin: '0px 0px 0px auto',
        width: '200px',
        // border:`solid 1px ${theme.palette.primary.main}`,
        // borderRadius:'30px'
    }
}))


function FaceIcon() {
    return null;
}

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


export default function ProfileModal(props) {
    const classes = useStyle()
    const dispatch = useDispatch()
    const activeCustomerId = useSelector(state => state.pageContact.activeCustomerId)
    const userDetail = useSelector(state => state.pageContact.page.contact_detail.find((contact) => contact.id === activeCustomerId))
    // const user_tag_list = userDetail?.tag_list || [];
    const user_tag_list = useSelector(state => state.pageContact.page.contact_detail.find((contact) => contact.id === activeCustomerId))?.tag_list || [];
    const tag_list = useSelector(state => state.pageContact?.tag_list || [])
    const [flag, setFlag] = useState(true)


    const saveTextMessages = [
        "I want 2 shirts",
        "Order no. 44445",
        "Order no. 44445",
        "Order no. 44445",
        "Order no. 44445",
        "Complain Bad experience with this store",
        "Drop Product at Sanothimi",
        "Item picked"
    ]


    const handleChange = (event) => {
        if (user_tag_list.find(obj => obj.tag_id === parseInt(event.target.value))) {
            dispatch(setSnackBar({status: true, message: "This tag is already assigned to customer", alert: "error"}))
        } else {
            dispatch(assignTagCustomer(activeCustomerId, parseInt(event.target.value)))
            setFlag(false)
        }
    }

    useEffect(() => {
        setFlag(true)
    }, [flag])

    return (
        <div>
            <Dialog open={props.open} onClose={props.handleClose} maxWidth="md" aria-labelledby="form-dialog-title">
                {/*<DialogTitle id="form-dialog-title">Profile</DialogTitle>*/}
                <DialogContent>
                    <div className={classes.profileMainContainer}>
                        <div className={classes.detailContainer}>
                            <div className={classes.labels}>CONTACT INFORMATION</div>
                            <div>Email:</div>
                            <div>Contact Detail:</div>
                            <div className={classes.labels}>TAGS</div>
                            <div className={classes.tags}>
                                <div className={classes.chipList}>
                                    {userDetail && <ProfileTags/>}
                                </div>
                                <NativeSelect
                                    className={classes.selectItem}
                                    onChange={handleChange}
                                    input={<BootstrapInput/>}
                                    defaultValue={''}
                                    label="Age">
                                    <option value={''} disabled>Select Tag</option>
                                    {tag_list.map((value, i) =>
                                        (<option value={value.id} key={i}>{value.tag}</option>)
                                    )}
                                </NativeSelect>

                            </div>

                            <div className={classes.labels}>SAVED CONTENT</div>
                            <div className={clsx(classes.savedContent, '::-webkit-scrollbar')}>
                                {saveTextMessages.map((value, i) => (
                                    <div className={classes.savedItem} key={i}>{value}</div>
                                ))}
                            </div>
                            <div className={classes.labels}>QUICK CHAT</div>
                            <div className={classes.quickChatElements}>
                                <TextareaAutosize
                                    className={clsx(classes.sendText, classes.msgTextArea)}
                                    aria-label="empty textarea"
                                    placeholder="Send Quick Message"
                                    // value={initialData.text}
                                    // onClick={() => clickComponentOnContainer()}
                                    // onChange={(e) => {
                                    //     onChangeValue(e)}}
                                />
                                <div>
                                    <SendRoundedIcon className={classes.button}/>
                                </div>
                            </div>


                        </div>
                        <div className={classes.highlightContainer}>
                            <div className={classes.profileAvatar}>
                                <Avatar className={classes.profileImage} src={userDetail.profile_pic}/>
                            </div>
                            <div className={classes.profileHighlight}>
                                <Typography className={classes.profileName} color="textSecondary"
                                            gutterBottom>
                                    {userDetail.first_name} {userDetail.last_name}
                                </Typography>
                                <Typography className={classes.profileMore} color="textSecondary"
                                            gutterBottom>
                                    <FaTransgenderAlt/> {userDetail.gender}
                                </Typography>
                                <Typography className={classes.profileMore} color="textSecondary"
                                            gutterBottom>
                                    <FaLanguage /> {userDetail.locale}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
