import React, {useEffect, useRef, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {Autocomplete} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import SaveIcon from "@material-ui/icons/Save";
import clsx from "clsx";
import TimelineIcon from '@material-ui/icons/Timeline';
import CreateIcon from '@material-ui/icons/Create';
import {fetchAutomationFlowList} from "../../../redux/actions/automationFlowList";
import SwitchFbPages from "../../../primaryComponent/switchFbPages";
import {savePromotionList} from "../../../redux/actions/promotionAction";
import {
    addNewPersistentMenu, publishCustomUserSettings, publishMessengerConfigurations,
    updateConfiguration,
    updateMessengerConfiguration
} from "../../../redux/actions/messengerConfigurationAction";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    Layout: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }, mainContent: {
        width: '80%',
        margin: '40px auto'
    }, mainContentItems: {
        margin: '10px'
    }, controllerContainer: {
        display: "flex"
    },
    items: {
        margin: '10px'
    },
    previewContainer: {
        height: '100%',
        margin: '30px',
    },
    image: {
        width: '400px',
        height: '200px',
        objectFit: 'contain'
    }

}));

export default function PersistentMenu() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [createPersistentMenu, setCreatePersistentMenu] = useState(false)
    const [newPersistentTitle, setNewPersistentTitle] = useState('')
    const [selectedFlow, setSelectedFlow] = useState(null)
    const flowList = useSelector(state => {
        return state.flowList.automationFlowList || []
    })
    const newTitle = useRef('')
    const persistent_menu = useSelector(state => state.messengerConfig?.persistent_menu);
    const activePageId = useSelector(state => state.messengerConfig?.activePageId);
    const messengerConfig = useSelector(state => state.messengerConfig);

    useEffect(() => {
        dispatch(fetchAutomationFlowList())
        setCreatePersistentMenu(false)
        setSelectedFlow(null)
        setNewPersistentTitle('')
    }, [persistent_menu])

    const newPersistentMenu = () => {
        const data = {
            type: 'postback',
            title: newPersistentTitle,
            flow_id: selectedFlow
        }
        dispatch(addNewPersistentMenu(data))
    }

    const updateObject = (i, title, flow_id) => {
        const data = {
            index: i,
            type: 'postback',
            title: title,
            flow_id: flow_id
        }
        dispatch(updateConfiguration('persistent_menu', data))
    }

    const savePersistentMenu = () => {
        dispatch(updateMessengerConfiguration('persistent_menu'))
    }

    const publishPersistentMenu = () => {
        dispatch(publishCustomUserSettings('persistent_menu'))
    }
    let count_persistent = persistent_menu?.call_to_actions.length || 0
    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={12} sm={4} md={8} component={"div"} elevation={6}>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Persistent Menu
                    </Typography>
                    <div className={classes.Layout}>
                        <Typography variant="h5" align="center" color="textSecondary" component="p">
                            Quickly build an effective pricing table for your potential customers with this layout.
                            It&apos;s built with default Material-UI components with little customization.
                        </Typography>
                        <SwitchFbPages config_type={"persistent_menu"}/>

                        {count_persistent < 13 && <Button variant="contained"
                                                          size="medium"
                                                          color="primary"
                                                          startIcon={<TimelineIcon/>}
                                                          onClick={() => setCreatePersistentMenu(true)}
                                                          className={clsx(classes.items)}>
                            Create New Promotion
                        </Button>}

                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            className={clsx(classes.items)}
                            startIcon={<SaveIcon/>}
                            onClick={() => publishPersistentMenu()}
                        >
                            Publish
                        </Button>
                        <Grid container spacing={3} direction="column" className={classes.mainContent}
                              justifyContent={"center"}>
                            {createPersistentMenu && <Grid item xs={12} direction={"column"}>
                                <div className={classes.mainContentItems}>
                                    <TextField
                                        inputRef={newTitle}
                                        onChange={() => setNewPersistentTitle(newTitle.current.value)}
                                        variant="outlined"
                                        placeholder="MENU: Enter menu title"
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.controllerContainer}>
                                    <div className={classes.mainContentItems}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={flowList}
                                            getOptionLabel={(option) => option?.flow_name || ''}
                                            onChange={(e, value) => {
                                                return value ? setSelectedFlow(value.id) : ''
                                            }}
                                            style={{width: 300}}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                           placeholder={"Select your flow"}
                                                           variant="outlined"/>}
                                        />
                                    </div>
                                    <div className={classes.mainContentItems}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            className={clsx(classes.items)}
                                            onClick={() => newPersistentMenu()}
                                            startIcon={<SaveIcon/>}
                                        >
                                            Save
                                        </Button>
                                        <Button variant="contained"
                                                size="medium"
                                                color="primary"
                                                startIcon={<TimelineIcon/>}
                                                className={clsx(classes.items)}>
                                            View Flow
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                            }


                            {persistent_menu !== null ?
                                persistent_menu.call_to_actions.map((value, i) => (
                                    <Grid item xs={12} key={i}>
                                        <div className={classes.mainContentItems}>
                                            <TextField
                                                value={activePageId === messengerConfig.activePageId ? value.title : ''}
                                                onChange={(e) => updateObject(i, e.target.value, value.flow_id)}
                                                variant="outlined"
                                                placeholder="MENU: Enter menu title"
                                                fullWidth
                                            />
                                        </div>
                                        <div className={classes.controllerContainer}>
                                            <div className={classes.mainContentItems}>
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={flowList}
                                                    getOptionLabel={(option) => option?.flow_name || ''}
                                                    value={persistent_menu !== null ? flowList.find((option) => option.id === value?.flow_id) || null : null}
                                                    onChange={(e, flowObject) => {
                                                        return value ? updateObject(i, value.title, flowObject.id) : ''
                                                    }}
                                                    style={{width: 300}}
                                                    renderInput={(params) =>
                                                        <TextField {...params}
                                                                   placeholder={"Select your flow"}
                                                                   variant="outlined"/>}
                                                />
                                            </div>
                                            <div className={classes.mainContentItems}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    size="medium"
                                                    className={clsx(classes.items)}
                                                    onClick={() => savePersistentMenu()}
                                                    startIcon={<SaveIcon/>}
                                                >
                                                    Save
                                                </Button>
                                                <Button variant="contained"
                                                        size="medium"
                                                        color="primary"
                                                        startIcon={<TimelineIcon/>}
                                                        className={clsx(classes.items)}>
                                                    View Flow
                                                </Button>
                                            </div>
                                        </div>
                                    </Grid>
                                )) : ''
                            }
                        </Grid>
                    </div>
                </div>
            </Grid>
            <Grid item xs={false} sm={8} md={4}>
                <div className={classes.previewContainer}>
                    <img src="/welcome_greeting.png" className={classes.image}></img>
                </div>

            </Grid>
        </Grid>
    );
}