import {ActionTypes} from "../contants/action-types";

const initialState = {
    status: false,
    message: 'alert closed',
    alert: 'success'
};
export const setSnackBarReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.SET_SNACKBAR:
            return {...state, status:payload.status, message: payload.message, alert: payload.alert};
        default:
            return state;
    }
};