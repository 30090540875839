import {ActionTypes} from "../contants/action-types";

const initialState = {
    user_profile: {},
    address: {
        country: "",
        country_code: "",
        state: "",
        postal_code: "",
        city: "",
        local: "",
    }
};
export const userProfileReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.SET_USER_PROFILE_DATA:
            return {...state, user_profile: payload};

        case ActionTypes.SET_ADDRESS:
            return {...state, address: payload};

        default:
            return state;
    }
};
