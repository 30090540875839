import React, {useEffect, useRef, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {Autocomplete} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import SaveIcon from "@material-ui/icons/Save";
import clsx from "clsx";
import TimelineIcon from '@material-ui/icons/Timeline';
import CreateIcon from '@material-ui/icons/Create';
import {fetchAutomationFlowList} from "../../../redux/actions/automationFlowList";
import {Chip} from "@material-ui/core";
import {
    fetchPromotionList, removeKeywordPromotion,
    savePromotionList, setActivePromotion,
    updatePromotionFlowId,
    updatePromotionKeyword, updatePromotionList
} from "../../../redux/actions/promotionAction";
import {useNavigate} from "react-router-dom";
import SwitchFbPages from "../../../primaryComponent/switchFbPages";
import {addNewPromotion} from "../../../redux/actions/messengerConfigurationAction";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    Layout: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }, mainContent: {
        width: '80%',
        margin: '40px auto'
    }, mainContentItems: {
        margin: '10px'
    }, controllerContainer: {
        display: "flex"
    },
    items: {
        margin: '10px'
    },
    previewContainer: {
        height: '100%',
        margin: '30px',
    },
    image: {
        width: '400px',
        height: '200px',
        objectFit: 'contain'
    }

}));

export default function Promotion() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const flowList = useSelector(state => {
        return state.flowList.automationFlowList || []
    })
    const promotion_list = useSelector(state => {
        return state.promotion.promotionList || []
    })
    const navigate = useNavigate()
    const [createPromotion, setCreatePromotion] = useState(false)
    const [new_keyword_list, setKeywordList] = useState([])
    const [selectedFlow, setSelectedFlow] = useState(null)
    const keyword = useRef('')
    const updatedKeyword = useRef('')

    useEffect(() => {
        dispatch(fetchAutomationFlowList())
        setCreatePromotion(false)
        setKeywordList([])
        setSelectedFlow(null)
    }, [promotion_list])

    // NEW PROMOTION
    const handleDelete = (data) => {
        setKeywordList(keywords => keywords.filter(value => value !== data))
    }

    const addNewChip = (e) => {
        if (e.key === 'Enter') {
            setKeywordList(prevKeys => [...prevKeys, keyword.current.value])
        }
    }

    const updateNewChip = (e) => {
        if (e.key === 'Enter') {
            dispatch(updatePromotionKeyword(e.target.value))
        }
    }


    // OLD PROMOTION

    const onChangeFlowNewPromotion = (e, value) => {
        setSelectedFlow(value.id)
    }

    const onUpdateFlowPromotion = (e, value) => {
        dispatch(updatePromotionFlowId(value.id))
    }

    const saveUpdatedPromotion = () => {
        dispatch(updatePromotionList())
    }

    const navigateTo = (flow_id) => {
        navigate("/playground/" + flowList.find(obj => obj.id === flow_id).uuid)
    }

    const setPromotionIdActive = (id) => {
        dispatch(setActivePromotion(id))
    }

    const newPromotionCreate = () => {
        const data = {
            "keyword_list": new_keyword_list,
            "flow_id": selectedFlow
        }
        dispatch(savePromotionList(data))
    }

    const removeKeyword = (id, data) => {
        dispatch(removeKeywordPromotion(id, data))
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={12} sm={5} md={8} component={"div"} elevation={6}>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Promotion
                    </Typography>
                    <div className={classes.Layout}>
                        <Typography variant="h5" align="center" color="textSecondary" component="p">
                            Quickly build an effective pricing table for your potential customers with this layout.
                            It&apos;s built with default Material-UI components with little customization.
                        </Typography>
                        <SwitchFbPages config_type={"promotion"}/>
                        <Button variant="contained"
                                size="medium"
                                color="primary"
                                startIcon={<TimelineIcon/>}
                                onClick={() => setCreatePromotion(true)}
                                className={clsx(classes.items)}>
                            Create New Promotion
                        </Button>
                        <Grid container spacing={3} className={classes.mainContent}
                              justifyContent={"center"}>
                            {createPromotion && <Grid item xs={12}>
                                <div className={classes.mainContentItems}>
                                    <div>
                                        {new_keyword_list.map((data, i) => {
                                            return (
                                                <Chip
                                                    label={data}
                                                    onDelete={() => handleDelete(data)}
                                                    className={classes.chip}
                                                    key={i}
                                                />
                                            );
                                        })}
                                    </div>
                                    <TextField
                                        inputRef={keyword}
                                        onKeyPress={(e) => addNewChip(e)}
                                        variant="outlined"
                                    />
                                </div>
                                <div className={classes.controllerContainer}>

                                    <div className={classes.mainContentItems}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={flowList}
                                            getOptionLabel={(option) => option?.flow_name || ''}
                                            style={{width: 300}}
                                            onChange={(e, value) => {
                                                return value ? onChangeFlowNewPromotion(e, value) : ''
                                            }
                                            }
                                            renderInput={(params) => <TextField {...params}
                                                                                variant="outlined"/>}/>
                                    </div>
                                    <div className={classes.mainContentItems}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            onClick={() => newPromotionCreate()}
                                            className={clsx(classes.items)}
                                            startIcon={<SaveIcon/>}
                                        >
                                            Add
                                        </Button>
                                        <Button variant="contained"
                                                size="medium"
                                                color="primary"
                                                startIcon={<TimelineIcon/>}
                                                className={clsx(classes.items)}>
                                            View Flow
                                        </Button>
                                    </div>

                                </div>
                            </Grid>}

                            {promotion_list.length > 0 && promotion_list.map((value, i) => (
                                <Grid item xs={12}
                                      onClick={() => setPromotionIdActive(value.uuid)} key={i}>
                                    <div className={classes.mainContentItems}>
                                        <div>
                                            {value.keyword.map((data, i) => {
                                                return (
                                                    <Chip
                                                        label={data}
                                                        onDelete={() => removeKeyword(value.uuid, data)}
                                                        className={classes.chip}
                                                        key={i}

                                                    />
                                                );
                                            })}
                                        </div>
                                        <TextField
                                            inputRef={updatedKeyword}
                                            onKeyPress={(e) => updateNewChip(e)}
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className={classes.controllerContainer}>

                                        <div className={classes.mainContentItems}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={flowList}
                                                getOptionLabel={(option) => option?.flow_name || ''}
                                                value={promotion_list !== null ? flowList.find((option) => option.id === value?.flow_id) || null : null}
                                                style={{width: 300}}
                                                onChange={(e, value) => {
                                                    {
                                                        return value ? onUpdateFlowPromotion(e, value) : ''
                                                    }
                                                }}
                                                renderInput={(params) => <TextField {...params}
                                                                                    placeholder={'Select Flow'}
                                                                                    variant="outlined"/>}/>
                                        </div>
                                        <div className={classes.mainContentItems}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="medium"
                                                onClick={() => saveUpdatedPromotion()}
                                                className={clsx(classes.items)}
                                                startIcon={<SaveIcon/>}
                                            >
                                                Save
                                            </Button>
                                            <Button variant="contained"
                                                    size="medium"
                                                    color="primary"
                                                    startIcon={<TimelineIcon/>}
                                                    onClick={() => navigateTo(value.flow_id)}
                                                    className={clsx(classes.items)}>
                                                View Flow
                                            </Button>
                                        </div>

                                    </div>
                                </Grid>
                            ))
                            }
                        </Grid>
                    </div>
                </div>
            </Grid>
            <Grid item xs={false} sm={7} md={4}>
                <div className={classes.previewContainer}>
                    <img src="/welcome_greeting.png" className={classes.image}></img>
                </div>

            </Grid>
        </Grid>
    );
}