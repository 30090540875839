import React, {useRef, useState} from "react";
import {Typography} from "@material-ui/core";
import {makeStyles, Grid, Link} from "@material-ui/core";
import Logo from "../../../reuseableComponents/logo";
import TextFieldWrapper from "../../../reuseableComponents/Form_UI/TextFieldWrapper";
import ButtonWrapper from "../../../reuseableComponents/Form_UI/ButtonWrapper";
import {Formik} from "formik";
import * as Yup from "yup";
import {useDispatch} from "react-redux";
import {setUserAuth} from "../../../redux/actions/userAccount";
import {useNavigate} from "react-router-dom";
import FacebookButton from "../../../home/components/facebookButton";
import GoogleButton from "../../../home/components/googleButton";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../config";
import {postAPI, resData} from "../../../config/api/config";
import {fetchProfileDetail} from "../../../helper/profile_detail";
import {fetchInitialData} from "../../../helper/fetchInitialData";
import {setSnackBar} from "../../../redux/actions/snackbar";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    image: {
        backgroundImage: "url(https://source.unsplash.com/random)",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#00adb5",
        "&:hover": {
            backgroundColor: "#393e46",
        },
    },
    textField: {
        borderColor: "#00adb5",
        backgroundColor: "#fff",
        "&:hover": {
            borderColor: "#00adb5 !important",
        },
    },
    banner: {
        fontWeight: "bold",
        color: "#00adb5",
        fontSize: 30,
    },
    sub_banner: {
        fontSize: 18,
        color: "#393e46",
        margin: theme.spacing(2, 0),
    },
    socialBtns: {
        margin: theme.spacing(4, 0),
    },
    redirectLink: {
        cursor: "pointer",
    }

}));

const INITIAL_FORM_STATE = {
    email: "",
    password: "",
    token: "",
};

const FORM_VALIDATION = Yup.object({
    email: Yup.string()
        .email("Invalid Email Address Pattern")
        .required("Email is required."),
    password: Yup.string().required("Please provide password."),
});

function LoginForm(props) {
    let navigate = useNavigate();

    const dispatch = useDispatch();
    const classes = useStyles();
    const reRef = useRef();

    const UserAuthentication = async (data) => {
        try {
            let response = await postAPI('/api/user/signin/', data);
            let main_data = JSON.parse(response.data);
            if (response.status === 200) {
                localStorage.setItem("user_auth", JSON.stringify(main_data.data));
                localStorage.setItem("isLogged", 'true');
                localStorage.setItem('profile_status', main_data.data.profile_status);

                if(main_data.data.profile_status){
                    dispatch(setUserAuth(main_data.data));
                    await fetchProfileDetail(main_data.data.id, main_data.data.access_token)
                    let pgCheck = await fetchInitialData(dispatch);
                    navigate(pgCheck ? "/dashboard" : "/modules");
                }else{
                    navigate("/profile");
                }

                dispatch(setSnackBar({status: true, message: "Logged in successfully", alert: "success"}))
            } else {
                dispatch(setSnackBar({status: true, message: main_data.message, alert: "error"}))
            }
        } catch (err) {
            dispatch(setSnackBar({status: true, message: err.message, alert: "error"}))
        }
    };

    const goToSignUp = () => {
        navigate("/signup");
    }

    const goToForgetPassword = () => {
        navigate("/forgetPassword");
    }


    return (
        <Formik
            initialValues={{...INITIAL_FORM_STATE}}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (values) => {
                const token = await reRef.current.executeAsync();
                reRef.current.reset();
                values.token = token
                UserAuthentication(values);

            }}
        >
            <div className={classes.paper}>
                <Logo/>
                <Typography component="h1" variant="h5" className={classes.sub_banner}>
                    Sign In
                </Typography>
                <form className={classes.form} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextFieldWrapper name="email" label="Email" id="email"/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextFieldWrapper
                                name="password"
                                label="Password"
                                id="password"
                                type="password"
                            />
                        </Grid>

                        <ReCAPTCHA sitekey={config.PUBLIC_RECAPTCHA_SITE_KEY} size="invisible" ref={reRef}/>

                        <Grid item xs={12}>
                            <ButtonWrapper children="Sign In"/>
                        </Grid>

                        <Grid container direction="row" className={classes.socialBtns} spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <GoogleButton paddingLeft={0}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FacebookButton paddingLeft={0} setOpen={props.setOpen}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs>
                            <Link onClick={() => goToForgetPassword()} variant="body2" className={classes.redirectLink}>
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link onClick={() => goToSignUp()} variant="body2" className={classes.redirectLink}>
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>

                </form>
            </div>
        </Formik>
    );
}

export default LoginForm;
