import React, {useEffect, useState} from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles, Grid} from "@material-ui/core";
import ButtonWrapper from "../../../reuseableComponents/Form_UI/ButtonWrapper";
import {Formik} from "formik";
import * as Yup from "yup";
import TextFieldWrapper from "../../../reuseableComponents/Form_UI/TextFieldWrapper";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import GoogleSearchLocationInput
    from "../../../reuseableComponents/AutoCompleteSearchLocation/autoCompleteSearchLocation";
import {postAPI} from "../../../config/api/config";
import {setUserProfileDetail} from "../../../redux/actions/userProfile";
import {fetchInitialData} from "../../../helper/fetchInitialData";
// import GoogleMaps from "../../reuseableComponents/googleAddress";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#00adb5",
        "&:hover": {
            backgroundColor: "#393e46",
        },
    },
    banner: {
        fontWeight: "bold",
        color: "#00adb5",
        fontSize: 30,
    },
    sub_banner: {
        fontSize: 18,
        color: "#393e46",
        margin: theme.spacing(2, 0),
    },
}));

const FORM_VALIDATION = Yup.object({
    firstName: Yup.string().required("Provide your first name"),
    lastName: Yup.string().required("Provide your last name"),
});


function ProfileForm(props) {
    const userAuthDetail = useSelector((state) => state.accountDetail.user_auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const address = useSelector(state => state.profileDetail.address)

    let INITIAL_FORM_STATE_ADDRESS = {
        firstName: '',
        lastName: ''
    }

    const createProfile = async (data) => {
        const userAuth = JSON.parse(localStorage.getItem("user_auth"))
        try {
            let response = await postAPI("/api/profile/create/", data, userAuth.access_token)
            if (response.status === 200) {
                let response_json = JSON.parse(response.data);
                dispatch(setUserProfileDetail(response_json.data));
                localStorage.setItem('isLogged', 'true');
                localStorage.setItem('profile_status', 'true');
                localStorage.setItem('profile_detail', JSON.stringify(response_json.data));
                let pgCheck = await fetchInitialData();
                if (!pgCheck) {
                    navigate("/modules");
                } else {
                    navigate("/dashboard")
                }
            }
        } catch (e) {
            console.log(e.response);
        }
    };


    return (
        <Formik
            initialValues={{...INITIAL_FORM_STATE_ADDRESS}}
            validationSchema={FORM_VALIDATION}
            onSubmit={(values) => {
                let objectAddress = address
                objectAddress.firstName = values.firstName
                objectAddress.lastName = values.lastName
                createProfile(objectAddress);
            }}>

            <form className={classes.form}>
                <CssBaseline/>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextFieldWrapper
                            name="firstName"
                            id="firstName"
                            label="First Name"/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldWrapper name="lastName" id="lastName" label="Last Name"/>
                    </Grid>
                    <Grid item xs={12}>
                        <GoogleSearchLocationInput/>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonWrapper children="Save Your Profile"/>
                    </Grid>
                </Grid>

            </form>
        </Formik>
    );
}

export default ProfileForm;
