import {combineReducers} from "redux";
import {userAccountReducer} from "./userAccountReducer";
import {userProfileReducer} from "./userProfileReducer";
import {addPageReducer} from "./addPageReducer";
import {pageContactReducer} from "./pageContactReducer";
import {setSnackBarReducer} from "./snackbarReducer";
import {setAutomation} from "./automationReducer";
import {setAutomationFlowList} from "./automationFlowListReducer";
import {promotionReducer} from "./promotionReducer";
import {accountSettingReducer} from "./accountSettingReducer";
import {messengerConfigurationReducer} from "./messengerConfigurationReducer";
import {setTextCounterPopper} from "./textCounterPopperReducer";

const reducers = combineReducers({
    accountDetail: userAccountReducer,
    profileDetail: userProfileReducer,
    pageDetail: addPageReducer,
    pageContact: pageContactReducer,
    snackbar: setSnackBarReducer,
    flowList: setAutomationFlowList,
    automation: setAutomation,
    promotion: promotionReducer,
    messengerConfig: messengerConfigurationReducer,
    accountSetting: accountSettingReducer,
    textCounterPopper:setTextCounterPopper
});


export default reducers;
