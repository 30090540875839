import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme)=>({
    root: {
        maxWidth: 345,
        height:theme.spacing(60)
    },
}));

export default function ModuleCard(Props) {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    alt="Contemplative Reptile"
                    height="240"
                    image={Props.imageBanner}
                    title="Contemplative Reptile"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {Props.moduleTitle}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {Props.moduleDescription}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                {Props.moduleAction}
            </CardActions>

        </Card>
    );
}