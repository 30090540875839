import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {Autocomplete} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import SaveIcon from "@material-ui/icons/Save";
import clsx from "clsx";
import TimelineIcon from '@material-ui/icons/Timeline';
import CreateIcon from '@material-ui/icons/Create';
import {fetchAutomationFlowList} from "../../../redux/actions/automationFlowList";
import {
    fetchMessengerConfiguration, publishMessengerConfigurations,
    setActivePageConfig, updateConfiguration,
    updateMessengerConfiguration
} from "../../../redux/actions/messengerConfigurationAction";
import PageBar from "../../contacts/components/pageBar";
import SwitchFbPages from "../../../primaryComponent/switchFbPages";
import logo from "../../../reuseableComponents/logo";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    Layout: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }, mainContent: {
        width: '80%',
        margin: '180px auto'
    }, mainContentItems: {
        margin: '20px'
    }, items: {
        margin: '10px'
    },
    previewContainer: {
        height: '100%',
        margin: '30px',
    },
    image: {
        width: '400px',
        height: '200px',
        objectFit: 'contain'
    }

}));

export default function WelcomeMessage() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const flowList = useSelector(state => {
        return state.flowList.automationFlowList || []
    })
    const welcome_message = useSelector(state => state.messengerConfig?.welcome_message);


    useEffect(() => {
        dispatch(fetchAutomationFlowList())
    }, [])

    const changeFlow = (event, value) => {
        dispatch(updateConfiguration('welcome_message', value))
    }

    const updatedFlow = () => {
        dispatch(updateMessengerConfiguration('welcome_message'))
    }

    const publishWelcomeMessage = () => {
        dispatch(publishMessengerConfigurations('welcome_message'))
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={12} sm={4} md={8} component={"div"} elevation={6}>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Welcome Message
                    </Typography>
                    <div className={classes.Layout}>
                        <Typography variant="h5" align="center" color="textSecondary" component="p">
                            Quickly build an effective pricing table for your potential customers with this layout.
                            It&apos;s built with default Material-UI components with little customization.
                        </Typography>
                        <SwitchFbPages config_type={"welcome_message"}/>
                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            className={clsx(classes.items)}
                            startIcon={<SaveIcon/>}
                            onClick={() => publishWelcomeMessage()}
                        >
                            Publish
                        </Button>
                        <Grid container spacing={3} className={classes.mainContent} justifyContent={"center"}>
                            <div className={classes.mainContentItems}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={flowList}
                                    getOptionLabel={(option) => option?.flow_name || ''}
                                    value={welcome_message !== null ? flowList.find((option) => option.id === welcome_message?.flow_id) || null : null}
                                    style={{width: 300}}
                                    onChange={(event, value) => {
                                        return value ? changeFlow(event, value) : ''
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                                   placeholder={"Select your flow"}
                                                   variant="outlined"/>}
                                />
                            </div>
                            <div className={clsx(classes.mainContentItems)}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    onClick={() => updatedFlow()}
                                    className={clsx(classes.items)}
                                    startIcon={<SaveIcon/>}
                                >
                                    Save
                                </Button>
                            </div>
                            <div className={classes.mainContentItems}>
                                <Button variant="contained"
                                        size="medium"
                                        color="primary"
                                        startIcon={<TimelineIcon/>}
                                        className={clsx(classes.items)}>
                                    View Flow
                                </Button>
                            </div>
                        </Grid>

                    </div>
                </div>
            </Grid>
            <Grid item xs={false} sm={8} md={4}>
                <div className={classes.previewContainer}>
                    <img src="/welcome_greeting.png" className={classes.image}></img>
                </div>

            </Grid>
        </Grid>
    );
}