import Typography from "@material-ui/core/Typography";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Button, Container, TextField} from "@material-ui/core";
import '../index.css'
import clsx from 'clsx';
import {useDispatch, useSelector} from "react-redux";
import {DataGrid} from "@material-ui/data-grid";
import {fetchAutomationFlowList} from "../../../redux/actions/automationFlowList";
import {Link, useNavigate} from "react-router-dom";
import {
    clearAutomationHistory,
    createNewAutomationFlow,
    setActiveAutomationFlowId
} from "../../../redux/actions/automation";
import SaveIcon from "@material-ui/icons/Save";
import {postAPI} from "../../../config/api/config";
import {setSnackBar} from "../../../redux/actions/snackbar";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import {red} from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from "@material-ui/core/Grid";
import {Autocomplete} from "@material-ui/lab";
import PrimaryButton from "../../../reuseableComponents/primaryButton";
import SecondaryButton from "../../../reuseableComponents/secondaryButton";
import AutomationFlowList from "./automationFlowList";
import SwitchFbPages from "../../../primaryComponent/switchFbPages";


const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: theme.text.primary.fontSize,
        padding: theme.layout.margin,
        color: theme.text.primary.color,
    },
    automationWrapper: {
        height: '100%',
    }, button: {
        margin: theme.spacing(1),
    }, root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {

        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    link: {
        minWidth: '185px',
    },
}))

const columns = [
    {field: 'id', headerName: 'ID', width: 90},
    {
        field: 'flow_name',
        headerName: 'Title',
        width: 150,
    },
    {
        field: 'createdAt',
        headerName: 'Created',
        width: 150,
    },
    {
        field: 'status',
        headerName: 'Active',
        width: 200,
    },
];


export default function AutomationBasic() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const flowList = useSelector(state => {
        return state.flowList.automationFlowList || []
    })

    const activeAutomationFlowUuid = useSelector(state => {
        return state.automation?.activeAutomationFlowUuid || ''
    })

    useEffect(() => {
        dispatch(fetchAutomationFlowList())
        dispatch(clearAutomationHistory())
    }, [])

    const onNewFlow = async () => {
        let flowDetail;
        try {
            const userAuth_ = localStorage.getItem("user_auth")
            const data = {
                flow_name: "Untitled",
                flow: [{
                    "flow_info": {
                        "id": "1",
                        "type": "input",
                        "position": {
                            "x": 250,
                            "y": 5
                        }
                    },
                    "inner_content": []
                }],
                status: false
            }
            const response = await postAPI('/api/automation_flow_design/create', data, JSON.parse(userAuth_).access_token)

            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                flowDetail = JSON.parse(response.data)?.data
                dispatch(createNewAutomationFlow(flowDetail))
                dispatch(setSnackBar({status: true, message: "Flow successfully stored", alert: "success"}))
                navigate(`/playground/${flowDetail.uuid}`)
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    }

    const onRowClick = (value => {
        dispatch(setActiveAutomationFlowId(value.id))
        const uuid = Object.values(flowList).find(obj => obj.id === value.id).uuid
        navigate(`/playground/${uuid}`)
    })

    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    let basic_automation_list = [
        {id: 1, name: "Welcome Message", path: "/automation/welcome_message/"},
        {id: 2, name: "Icebreaker", path: "/automation/ice_breaker/"},
        {id: 3, name: "Greeting", path: "/automation/greeting/"},
        {id: 4, name: "Presistent Menu", path: "/automation/presistent_menu/"},
        {id: 5, name: "Question And Answer", path: ""},
        {id: 6, name: "Automation Rules", path: ""},
        {id: 7, name: "Promotions", path: "/automation/promotion/"}
    ]

    return (

        <Container maxWidth={false}>
            <div className={classes.automationWrapper}>
                <Typography sx={{fontSize: 24}} className={classes.header} color="textSecondary">
                    Automation
                </Typography>
                <Grid container spacing={3}>
                    {basic_automation_list.map((value, i) =>
                        (
                            <Grid item xs={3} component={Link} to={value.path} key={i} className={classes.link}>
                                <Card className={classes.root}>
                                    <CardHeader
                                        avatar={
                                            <Avatar aria-label="recipe" className={classes.avatar}>
                                                R
                                            </Avatar>
                                        }
                                        title={value.name}
                                        subheader="last updated"
                                    />
                                </Card>
                            </Grid>
                        )
                    )}
                </Grid>

                <AutomationFlowList/>
            </div>
        </Container>
    )
}
