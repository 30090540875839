import {postAPIFile} from "../config/api/config";
import axios from "axios";
import {setSnackBar} from "../redux/actions/snackbar";

const {getAPI, postAPI} = require("../config/api/config");


export const uploadFile = async (data) => {
    try{
        const user_auth = JSON.parse(localStorage.getItem("user_auth"));
        const formData = new FormData();
        formData.append('file', data)
        let response = await postAPI("/api/automation_flow_design/static/", formData, user_auth.access_token);
        return response
    }catch (e) {
        console.log(e)
    }
}