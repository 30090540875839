import {Button, CssBaseline} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
    },
    btnOutlined: {
        // backgroundColor: "#00adb5",
        // padding: theme.spacing(2, 12),
        "&:hover": {
            backgroundColor: "#393e46",
            color: "#FFF",
            boxShadow: "none",
        },
    },
}));


function SecondaryButton(props) {
    const classes = useStyles();
    return (
        <>
            <CssBaseline/>
            <Button fullWidth={true} variant="outlined" color="primary" size="large" className={classes.btnOutlined}
                    onClick={() =>props.triggerAction()}>
                {props.nameButton}
            </Button>
        </>
    );
}

export default SecondaryButton;
