import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';

export default memo(({ data, isConnectable }) => {
    return (
        <div>
            <div>
                {data.label}
            </div>
            <Handle
                type="source"
                position="right"
                id="a"
                style={{ top: 10, background: '#555' }}
                isConnectable={isConnectable}
            />
        </div>
    );
});