import React from "react";

import {Grid, Paper} from "@material-ui/core";
import ModuleCard from "../components/moduleCard";
import {makeStyles} from "@material-ui/core/styles";
import SecondaryButton from "../../../reuseableComponents/secondaryButton";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(10, 2),
        padding: theme.spacing(6),
        // width: "100%",
        // height: theme.spacing(68),
        alignItems: "bottom",
        // '& > *': {
        //     margin: theme.spacing(10,2),
        //     // width: theme.spacing(180),
        //     // height: theme.spacing(80),
        //     padding: theme.spacing(6),
        //
        // },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(10, 2),
            padding: theme.spacing(0)
        }

        // position: 'relative',
    },
    mainContainer: {
        // backgroundColor:'#eeeeee',
        height: theme.spacing(100),
    },
    childPaper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: "center",
        height: theme.spacing(60),
        padding: theme.spacing(2),
    }
}));

export default function SelectModuleContainer(props) {
    const classes = useStyles();

    const moduleAction = () => {
        props.setActiveContent("AddFacebookPages");
    }

    return (
        <Paper xs={12} sm={12} elevation={3} className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={4}>
                    <ModuleCard style={{height:'fit-content'}} imageBanner={'messangerModule.jpg'} moduleTitle={"Page conversation in Messenger"}
                                moduleDescription={"Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging\n" +
                                    "across all continents except Antarctica"}
                                moduleAction={<SecondaryButton nameButton={"Let's Start"}
                                                               triggerAction={moduleAction}/>}/>
                </Grid>
                {/*<Grid item xs={12} sm={12} lg={4}>*/}
                {/*    <ModuleCard imageBanner={'facebookPageModule.png'} moduleTitle={"Messanger Connect People"}*/}
                {/*                moduleDescription={"Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging\n" +*/}
                {/*                "across all continents except Antarctica"}*/}
                {/*                moduleAction={<SecondaryButton nameButton={"Let's Start"}/>}/>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12} sm={12} lg={4}>*/}
                {/*    <ModuleCard imageBanner={'facebookPageModule.png'} moduleTitle={"Facebook Page"}*/}
                {/*                moduleDescription={"Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging\n" +*/}
                {/*                "across all continents except Antarctica"}*/}
                {/*                moduleAction={<FacebookButton xs={12} paddingLeft={12}/>}/>*/}
                {/*</Grid>*/}
            </Grid>
        </Paper>
    );
}