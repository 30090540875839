import {ActionTypes} from "../contants/action-types";
import {postAPI} from "../../config/api/config";
import {setSnackBar} from "./snackbar";
import {useSelector} from "react-redux";

export const setActiveAutomationFlowId = (id) => {
    return {
        type: ActionTypes.ACTIVE_AUTOMATION_FLOW_ID,
        payload: id,
    };
};

export const contentControllerOpen = (bool) => {
    return {
        type: ActionTypes.OPEN_CONTENT_CONTROLLER,
        payload: bool,
    };
};

// Fetch Plain Data from Server
export const setFlowPlainData = (value) => {
    return {
        type: ActionTypes.STORE_FLOW_PLAIN_DATA,
        payload: value,
    };
};

// Add New Container for component (Plain Data)
export const addFlowPlainData = (value) => {
    return {
        type: ActionTypes.CREATE_FLOW_PLAIN_DATA,
        payload: value,
    };
};


// Sort Plain Data
export const updateFlowPlainData = (containerPath, plain_list) => {
    return (dispatch, getState) => {
        const {automation} = getState();
        dispatch({
            type: ActionTypes.UPDATE_FLOW_PLAIN_DATA,
            automationDetail: automation,
            payload: {
                containerPath: containerPath,
                plain_list: plain_list
            }
        })
    };
};

// Update Flow Container Info and Position
export const updateFlowContainerData = (containerId, flowData) => {
    return (dispatch, getState) => {
        const {automation} = getState();
        const flowPlainData = automation?.flowPlainData || []

        Object.values(flowPlainData).find(obj => obj.flow_info.id === containerId).flow_info = flowData

        dispatch({
            type: ActionTypes.UPDATE_FLOW_CONTAINER_DATA,
            payload: {
                flowPlainData: flowPlainData
            }
        })
    };
};

// Add new plain data component
export const addFlowComponentPlainData = (containerPath, value) => {
    return (dispatch, getState) => {
        const {automation} = getState();
        console.log(automation.flowPlainData[containerPath])
        automation.flowPlainData[containerPath].inner_content.push(value)
        dispatch({
            type: ActionTypes.ADD_FLOW_COMPONENT_PLAIN_DATA,
            payload: {
                flowPlainData: automation.flowPlainData
            }
        })
    };
};

export const addFlowQuickReplyPlainData = (containerPath, value) => {
    return (dispatch, getState) => {
        const {automation} = getState();
        let quickReplyIndex = automation.flowPlainData[containerPath].inner_content.findIndex(obj => obj.element_type === 'QuickReply')
        automation.flowPlainData[containerPath].inner_content[quickReplyIndex].data.quick_replies.push(value)
        dispatch({
            type: ActionTypes.ADD_FLOW_COMPONENT_QUICK_REPLY_PLAIN_DATA,
            payload: {
                flowPlainData: automation.flowPlainData
            }
        })
    };
};


// Update inner content of Component
export const updateInnerContentData = (containerPath, componentPath, data) => {
    return (dispatch, getState) => {
        const {automation} = getState();


        if (data.type === 'button_name') {
            automation.flowPlainData[containerPath].inner_content[componentPath].data.button[data.index].button_name = data.button_name
        } else if (data.type === 'button_value_type') {
            automation.flowPlainData[containerPath].inner_content[componentPath].data.button[data.index].button_type = data.button_type
            automation.flowPlainData[containerPath].inner_content[componentPath].data.button[data.index].button_value = data.button_value
        } else if (data.type === 'template') {
            delete data['type']
            automation.flowPlainData[containerPath].inner_content[componentPath].data.push(data)
        } else if (data.type === 'text') {
            automation.flowPlainData[containerPath].inner_content[componentPath].data = data
        } else if (data.type === 'image') {
            automation.flowPlainData[containerPath].inner_content[componentPath].data = data.data
        } else if (data.type === 'quick_reply') {
            if (data.subType === 'content_type') {
                automation.flowPlainData[containerPath].inner_content[componentPath].data.quick_replies[automation.activeFlowSubComponentPath].content_type = data.content_type
            } else if (data.subType === 'title') {
                automation.flowPlainData[containerPath].inner_content[componentPath].data.quick_replies[automation.activeFlowSubComponentPath].title = data.title
            } else if (data.subType === 'payload') {
                automation.flowPlainData[containerPath].inner_content[componentPath].data.quick_replies[data.index].payload = data.payload
            } else if (data.subType === 'text') {
                automation.flowPlainData[containerPath].inner_content[componentPath].data.text = data.text
            }
        }else if (data.type === 'audio') {
            console.log(containerPath)
            console.log(componentPath)
            automation.flowPlainData[containerPath].inner_content[componentPath].data = data.data
        }else if (data.type === 'video') {
            automation.flowPlainData[containerPath].inner_content[componentPath].data = data.data
        }else if (data.type === 'attachment') {
            automation.flowPlainData[containerPath].inner_content[componentPath].data = data.data
        }


        dispatch({
            type: ActionTypes.UPDATE_INNER_CONTENT_DATA,
            payload: {
                flowPlainData: automation.flowPlainData,
            }
        })
        dispatch(updateAutomationFlow())
    };
};


// Update inner content of Component
export const updateGalleryContentData = (containerPath, componentPath, galleryIndex, data) => {
    return (dispatch, getState) => {
        const {automation} = getState();
        console.log(containerPath)
        console.log(componentPath)
        console.log(galleryIndex)
        let flowPlainData = automation?.flowPlainData
        let gallery_data = flowPlainData[containerPath].inner_content[componentPath].data[galleryIndex]
        if (data.type === 'button_name') {
            delete data['type']
            const button_list = gallery_data.button.push(data) // new updated array
            gallery_data = button_list // store new updated array
        } else if (data.type === 'header') {
            delete data['type']
            gallery_data.header = data.header
        } else if (data.type === 'button_value_type') {
            gallery_data.button[data.index].button_type = data.button_type
            gallery_data.button[data.index].button_value = data.button_value
        }
        dispatch({
            type: ActionTypes.UPDATE_GALLERY_CONTENT_DATA,
            payload: {
                flowPlainData: automation.flowPlainData
            }
        })

        dispatch(updateAutomationFlow())
    };
};

export const setFlowDiagram = (value) => {
    return {
        type: ActionTypes.STORE_FLOW_DIAGRAM,
        payload: value,
    };
};

export const activeFlowContainerPath = (value) => {
    return {
        type: ActionTypes.ACTIVE_FLOW_CONTAINER_PATH,
        payload: value,
    };
};

// Pass the id of the respective component
export const activeFlowComponentPath = (id) => {
    return {
        type: ActionTypes.ACTIVE_FLOW_COMPONENT_PATH,
        payload: id,
    };
};

export const activeFlowSubComponentPath = (id) => {
    return {
        type: ActionTypes.ACTIVE_FLOW_SUB_COMPONENT_PATH,
        payload: id,
    };
};

export const activeFlowGalleryComponentPath = (id) => {
    return {
        type: ActionTypes.ACTIVE_FLOW_GALLERY_COMPONENT_PATH,
        payload: id,
    };
};

export const createNewAutomationFlow = (flowDetail) => {
    return async (dispatch, getState) => {

        dispatch({
            type: ActionTypes.CREATE_NEW_AUTOMATION_FLOW,
            payload: {
                activeAutomationFlowId: flowDetail.id,
                activeAutomationFlowUuid: flowDetail.uuid,
                flow_name: flowDetail.flow_name,
                flowPlainData: flowDetail.flow,
                status: flowDetail.status
            }
        })
    };
};


export const updateAutomationFlow = () => {
    return async (dispatch, getState) => {
        const {automation, plainFlowData} = getState();

        let flowDetail;
        try {
            const userAuth_ = localStorage.getItem("user_auth")
            const userAuth = JSON.parse(userAuth_)
            const data = {
                flow_name: automation.flow_name,
                flow: automation.flowPlainData,
                flow_id: automation.activeAutomationFlowId
            }
            const response = await postAPI('/api/automation_flow_design/update/flow', data, userAuth.access_token)

            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                flowDetail = JSON.parse(response.data)?.data
                dispatch(setSnackBar({status: true, message: "Flow updated successfully", alert: "success"}))
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }

        dispatch({
            type: ActionTypes.UPDATE_CURRENT_AUTOMATION_FLOW,
            payload: {
                activeAutomationFlowId: flowDetail.id,
                activeAutomationFlowUuid: flowDetail.uuid,
                flow_name: flowDetail.flow_name,
                flowPlainData: flowDetail.flow,
                status: flowDetail.status
            }
        })
    };
};


export const fetchAutomationFlowWithUuid = (uuid) => {
    return async (dispatch, getState) => {
        const {automation} = getState();

        let flowDetail;
        try {
            const userAuth_ = localStorage.getItem("user_auth")
            const userAuth = JSON.parse(userAuth_)
            const data = {
                uuid: uuid
            }
            const response = await postAPI('/api/automation_flow_design/detail', data, userAuth.access_token)

            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                flowDetail = JSON.parse(response.data)?.flowDetail
                dispatch({
                    type: ActionTypes.FETCH_AUTOMATION_FLOW,
                    payload: {
                        activeAutomationFlowId: flowDetail.id,
                        activeAutomationFlowUuid: flowDetail.uuid,
                        flow_name: flowDetail.flow_name,
                        flowPlainData: flowDetail.flow,
                        status: flowDetail.status
                    }
                })
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }

    };
};

export const updateFlowName = (name) => {
    return {
        type: ActionTypes.UPDATE_FLOW_NAME,
        payload: name,
    };
};


export const createMessengerEvent = (new_edge, flow_id) => {
        return async (dispatch, getState) => {
            const {automation} = getState();
            const containerPath = automation?.activeFlowContainerPath
            const componentPath = automation?.activeFlowComponentPath
            const subComponentPath = automation?.activeFlowSubComponentPath
            const galleryComponentPath = automation?.activeFlowGalleryComponentPath
            const handleComponent = automation?.activeHandleComponent

            let messengerEvent;
            let buttonData;
            try {
                const userAuth_ = localStorage.getItem("user_auth")
                const userAuth = JSON.parse(userAuth_)
                const data = {
                    "keyword_list": [],
                    "source": new_edge.source,
                    "target": new_edge.target,
                    "status": true,
                    "flow_id": flow_id
                }

                const response = await postAPI('/api/messenger_event/create', data, userAuth.access_token)

                if (response.status !== 200) {
                    dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
                } else {
                    messengerEvent = JSON.parse(response.data)?.data
                    automation.messengerEvent.push(messengerEvent)


                    dispatch({
                        type: ActionTypes.CREATE_MESSENGER_EVENT,
                        payload: {
                            messengerEventList: automation.messengerEvent
                        }
                    })

                    buttonData = {
                        type: 'button_value_type',
                        button_value: messengerEvent.trigger,
                        button_type: 'postback',
                    }
                    console.log(buttonData)


                    if (handleComponent === 'text_button') {
                        buttonData.index = subComponentPath
                        dispatch(updateInnerContentData(containerPath, componentPath, buttonData))

                    } else if (handleComponent === 'gallery') {
                        console.log('gallery')
                        buttonData.index = galleryComponentPath
                        dispatch(updateGalleryContentData(containerPath, componentPath, subComponentPath, buttonData))

                    } else if (handleComponent === 'quick_reply') {
                        buttonData = {
                            type: 'quick_reply',
                            subType: 'payload',
                            payload: messengerEvent.trigger,
                            index: subComponentPath
                        }
                        dispatch(updateInnerContentData(containerPath, componentPath, buttonData))
                    }

                }
            } catch (e) {
                dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
            }

        };
    }
;

export const activeHandleComponent = (component_name) => {
    return {
        type: ActionTypes.ACTIVE_HANDLE_COMPONENT,
        payload: component_name,
    };
};

export const clearAutomationHistory = () => {
    return {
        type: ActionTypes.CLEAR_AUTOMATION_HISTORY,
        payload: {
            flowPlainData: [],
            flowDiagram: []
        }
    }
}