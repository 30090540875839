import Logo from "../../../reuseableComponents/logo";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import {Button} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    container: {
        margin: '120px auto',
        padding: '40px',
        width: '460px',
        textAlign: 'center',
    },
    content: {
        color: theme.text.primary.color,
        margin: '20px auto',
        fontSize: '150px',
        fontWeight: 'bold',
    },
    subContent: {
        color: theme.text.primary.color,
        margin: '20px auto',
        fontSize: '30px',
    },
    helpTextContent: {
        color: theme.text.primary.color,
        margin: '18px auto',
        fontSize: '20px',
    },
    redirectContainer: {
        margin: '0px auto',
    }
}))

export default function PageNotFound() {
    const classes = useStyle()
    return (
        <div className={classes.container}>
            <Typography component="h1" variant="h5" className={classes.content}>
                404
            </Typography>
            <Typography component="h1" variant="h5" className={classes.subContent}>
                Page Not Found !
            </Typography>
            <Typography component="h1" variant="h5" className={classes.helpTextContent}>
                The page you are trying to find does not exist. Please click below option to redirect to other
                pages.
            </Typography>
            <div className={classes.redirectContainer}>
                {localStorage.getItem('isLogged') === 'true' ? <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    component={Link}
                    to={'/dashboard'}>
                    Dashboard
                </Button> : <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    component={Link}
                    to={'/'}>
                    Login
                </Button>}
            </div>
        </div>
    )
}
