import {useRef, useEffect} from 'react';

function UsePrevious<T>(value?: T) {
    const ref = useRef<T>();

    //store current value in ref
    useEffect(()=>{
        ref.current = value;
    }, [value]); // Only re-run if value changes

    return ref.current;
}

export default UsePrevious;