import React, {useEffect, useRef, useState} from "react";
import TextFieldWrapper from "../Form_UI/TextFieldWrapper";
import {makeStyles} from "@material-ui/core/styles";
import './style.css'
import {Grid} from "@material-ui/core";
import config from "../../config";
import {useDispatch, useSelector} from "react-redux";
import {changeAddressValue, setAddress} from "../../redux/actions/userProfile";

let autoComplete;

//dynamically load JavaScript files in our html with callback when finished

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    // when script state is ready  and loaded or complete we will call callback
    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === 'loaded' || script.readyState === 'complete') {
                script.onreadystatechange = null;
                callback();
            }
        }
    } else {
        script.onload = () => callback();
    }

    script.src = url; // load by url
    document.getElementsByTagName("head")[0].appendChild(script); //append to head
}

const useStyles = makeStyles((theme) => ({
    option: {
        color: "pink", // backgroundColor:"Green"
    }
}))


// handle when the script is loaded we will assign autoCompleteRef with google maps place autocomplete

function handleScriptLoad(updateQuery, autoCompleteRef, dispatch) {

    //assign autoComplete with Google maps place on time

    autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current, {
        types: ["address"]
    });

    autoComplete.setFields(["address_component", "formatted_address", "geometry", "international_phone_number"]); //specify what properties we will get

    // add a listener to handle when the place is selected
    autoComplete.addListener("place_changed", () => handlePlaceSelect(updateQuery, dispatch));
}


async function handlePlaceSelect(updateQuery, dispatch) {
    const addressObject = autoComplete.getPlace(); // get place from google api
    const query = addressObject.formatted_address;
    updateQuery(query);
    console.log(addressObject.address_components)
    let initAutoComplete = {
        country: "", country_code: "", state: "", postal_code: "", city: "", local: "",
    }

    for (const component of addressObject.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];
        switch (componentType) {
            case "postal_code": {
                initAutoComplete.postal_code = component.long_name;
                break;
            }
            case "locality": {
                initAutoComplete.local = component.long_name;
                break;
            }
            case "colloquial_area": {
                initAutoComplete.local = component.long_name;
                break;
            }
            case "administrative_area_level_1": {
                initAutoComplete.state = component.long_name;
                break;
            }
            case "administrative_area_level_2": {
                initAutoComplete.city = component.long_name;
                break;
            }
            case "country": {
                initAutoComplete.country_code = component.short_name;
                initAutoComplete.country = component.long_name;
                break;
            }
            default:
                break;

        }
    }
    dispatch(setAddress(initAutoComplete))
}


function GoogleSearchLocationInput(props) {
    const classes = useStyles();
    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);
    const dispatch = useDispatch()
    const textChange = useRef('')
    const initAutoComplete = useSelector(state => state.profileDetail?.address)

    useEffect(() => {
        loadScript(`https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_MAP_API_KEY}&libraries=places`, () => handleScriptLoad(setQuery, autoCompleteRef, dispatch));
    }, []);

    let inputValueChange = (e, key) => {
        dispatch(changeAddressValue(key, e.target.value))
    }

    return (<Grid container spacing={2}>
        <Grid item xs={12} style={{margin: "22px 0px 10px 0px "}}>
            <TextFieldWrapper inputRef={autoCompleteRef} name="address" id="address" label="Search Address"
                              InputLabelProps={{
                                  shrink: true
                              }} className={classes.option} onChange={event => setQuery(event.target.value)}
                              placeholder="Search your street location" value={query} type="search"/>

        </Grid>
        <Grid item xs={8}>
            <TextFieldWrapper name="country" id="country" label="Country / Region"
                              value={initAutoComplete.country} onChange={(e) => inputValueChange(e, 'country')}/>
        </Grid>
        <Grid item xs={4}>
            <TextFieldWrapper name="country_code" id="country_code" label="Code"
                              value={initAutoComplete.country_code}
                              onChange={(e) => inputValueChange(e, 'country_code')}/>
        </Grid>
        <Grid item xs={6}>
            <TextFieldWrapper name="state" id="state" label="State / Province"
                              value={initAutoComplete.state} onChange={(e) => inputValueChange(e, 'state')}/>
        </Grid>
        <Grid item xs={6}>
            <TextFieldWrapper name="postal_code" id="postal_code" label="Postal Code"
                              value={initAutoComplete.postal_code}
                              onChange={(e) => inputValueChange(e, 'postal_code')}/>
        </Grid>
        <Grid item xs={12}>
            <TextFieldWrapper name="city" id="city" label="City"
                              value={initAutoComplete.city} onChange={(e) => inputValueChange(e, 'city')}/>
        </Grid>
        <Grid item xs={12}>
            <TextFieldWrapper name="local" id="local" label="Local"
                              value={initAutoComplete.local} onChange={(e) => inputValueChange(e, 'local')}/>
        </Grid>
    </Grid>)
}

export default GoogleSearchLocationInput;
