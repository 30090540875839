import axios from "axios";
import config from "../../config";
import * as fs from "fs";



export const instance = axios.create({
    baseURL: config.FB_GRAPH_URL,
    timeout: 10000,
    // validateStatus:status => status >= 200 && status <300
});

export const getGraphAPI = async (url, param, token) => {
    let response;
    try {
        response = await instance({
            method: "GET",
            url:`${url}`,
            param: param,
            transformResponse: [
                function (responseData) {
                    return responseData
                }
            ],
        });

    } catch (error) {
        return (error.response)
    }
    return response
};


export const postGraphAPI = async (url, data, token) => {
    let response;
    try {
        response = await instance({
            method: "POST",
            url: `${url}`,
            data: data,
            params:{},
            headers: {
                "access_token": token,
            },
            transformResponse: [
                function (responseData) {
                    console.log(responseData)
                    return responseData
                }
            ],
        });
    } catch (error) {
        return (error.response)
    }
    return response
}