import React, {useRef} from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles, Grid} from "@material-ui/core";
import {Formik} from "formik";
import * as Yup from "yup";
import TextFieldWrapper from "../../../reuseableComponents/Form_UI/TextFieldWrapper";
import ButtonWrapper from "../../../reuseableComponents/Form_UI/ButtonWrapper";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setUserAuth} from "../../../redux/actions/userAccount";
import Link from "@material-ui/core/Link";
import {useNavigate} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import config from "../../../config";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#00adb5",
        "&:hover": {
            backgroundColor: "#393e46",
        },
    },
    banner: {
        fontWeight: "bold",
        color: "#00adb5",
        fontSize: 30,
    },
    sub_banner: {
        fontSize: 18,
        color: "#393e46",
        margin: theme.spacing(2, 0),
    },
    redirectLink: {
        margin: theme.spacing(4, 0),
    },
}));


const InitialValueSignUpForm = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes = useStyles();
    const reRef = useRef();

    const INITIAL_FORM_STATE = {
        email: props.email,
        password: "",
        confirmPassword: "",
        token: "",
    };

    const FORM_VALIDATION = Yup.object({
        email: Yup.string()
            .email("Invalid Email Address Pattern")
            .required("Email is required."),
        password: Yup.string().required("Please provide password.").min(8),
        confirmPassword: Yup.string()
            .required("Password Confirmation is required.")
            .oneOf([Yup.ref("password"), null], "Password must match")
    });


    const fetchSignUp = async (data) => {
        try {
            const res = await axios.post("/api/user/create/", data);
            if (res.status === 200) {
                dispatch(setUserAuth(res.data));
                // localStorage.setItem('isLogged', true);
                localStorage.setItem('profile_status', res.data.data.profile_status);
                localStorage.setItem('user_auth', JSON.stringify(res.data.data));
                props.method("ProfileForm");
            } else {
                props.setOpen({status: true, message: res.data.message, type: "error"})
            }
        } catch (err) {
            props.setOpen({status: true, message: err.response.data.message, type: "error"})
        }
    };

    const preventDefault = (event) => {
        event.preventDefault();
        navigate("/");
    }

    return (
        <Formik
            initialValues={{...INITIAL_FORM_STATE}}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (values) => {
                const token = await reRef.current.executeAsync();
                reRef.current.reset();
                values.token = token
                fetchSignUp(values);
            }}
        >

            <form className={classes.form}>
                <CssBaseline/>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextFieldWrapper name="email" label="Email" id="email" InputProps={{
                            readOnly: true,
                        }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldWrapper
                            name="password"
                            label="Password"
                            id="password"
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldWrapper
                            name="confirmPassword"
                            label="Confirm Password"
                            id="confirmPassword"
                            type="password"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl required component="fieldset" className={classes.formControl}>
                        <FormControlLabel
                            control={<Checkbox  color="primary" />}
                            label="I agree to terms and conditions."
                        />
                        </FormControl>
                        {/*<FormHelperText>You can display an error</FormHelperText>*/}
                    </Grid>
                    <ReCAPTCHA sitekey={config.PUBLIC_RECAPTCHA_SITE_KEY} size="invisible" ref={reRef}/>
                    <Grid item xs={12}>
                        <ButtonWrapper children="Submit"/>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" className={classes.redirectLink}>
                    <Grid item xs={12} sm={7}>
                        <Link component="button" variant="body2" onClick={preventDefault}>
                            Already have an account? Sign in
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </Formik>
    );
};

export default InitialValueSignUpForm;
