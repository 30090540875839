import React, {useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {Avatar, Button, Fab, ListItemAvatar} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import CardMedia from "@material-ui/core/CardMedia";
import {useInitFbSDK} from "../../../hooks/fb-hooks";
import {getGraphAPI} from "../../../config/api/graphFbConfig";
import LibraryAddRoundedIcon from '@material-ui/icons/LibraryAddRounded';
import {Link} from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: theme.text.primary.fontSize,
        padding: theme.layout.margin,
        color: theme.text.primary.color,
    },
    container: {
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    button: {
        width: '100%',
    },
}));

export default function Dashboard() {
    const classes = useStyles();
    const navigate = useNavigate();
    useInitFbSDK();
    const [pageList, setPageList] = React.useState([]);


    const logout = () => {
        localStorage.clear()
        navigate("/")
    }
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

    useEffect(async () => {
        const pg = JSON.parse(localStorage.getItem("pg"))
        if (pg) {
            if (pg.length > 0) {
                async function GetPageProfileImage() {
                    for (let object of pg) {
                        let page_id = object.page_id;
                        let page_picture;
                        try {
                            let response = await getGraphAPI(`/${page_id}/picture`, {"redirect": "0"})
                            page_picture = response.request.responseURL
                            object['image'] = page_picture
                            setPageList(old_val => [...old_val, object])
                        } catch (e) {
                            console.log(e)
                        }
                    }
                }

                await GetPageProfileImage()
            }
        }

    }, []);

    return (
        <Container maxWidth={false} className={classes.container}>
            <Typography sx={{fontSize: 24}} className={classes.header} color="textSecondary">
                Dashboard
            </Typography>
            <Grid container spacing={3}>
                {/* Chart */}
                <Grid item xs={12} md={8} lg={9}>
                    <Paper className={fixedHeightPaper}/>
                </Grid>
                {/* Recent Deposits */}
                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={fixedHeightPaper}>
                        {pageList.map((object, i) => {
                            return (
                                // <>
                                <ListItem key={i}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <CardMedia
                                                component="img"
                                                src={object.image}
                                                alt="Live from space album cover"
                                            />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={object.name} secondary={object.category}/>
                                    {/*<Divider variant="inset"/>*/}
                                </ListItem>
                                // </>
                            )
                        })}
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<LibraryAddRoundedIcon/>}
                            component={Link} to={'/modules'}
                        >
                            Subscribe Modules
                        </Button>
                    </Paper>
                </Grid>
                {/* Recent Orders */}
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        {/*<Orders />*/}
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
