import {ActionTypes} from "../contants/action-types";

const initialState = {
    activePageId: null,
    welcome_message: null,
    persistent_menu: null,
    icebreaker: null,
    greeting: null,
    promotion: null
};

export const messengerConfigurationReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.FETCH_WELCOME_MESSAGE:
            return {...state, welcome_message: payload};

        case ActionTypes.FETCH_ICEBREAKER:
            return {...state, icebreaker: payload};

        case ActionTypes.FETCH_PERSISTENT_MENU:
            return {...state, persistent_menu: payload};

        case ActionTypes.FETCH_GREETING:
            return {...state, greeting: payload};

        case ActionTypes.FETCH_PROMOTION:
            return {...state, promotion: payload};


        case ActionTypes.SET_ACTIVE_PAGE_CONFIG:
            return {...state, activePageId: payload.data};

        case ActionTypes.UPDATE_MESSENGER_CONFIGURATION:
            return {
                ...state,
                activePageId: payload.activePageId,
                welcome_message: payload.welcome_message,
                persistent_menu: payload.persistent_menu,
                icebreaker: payload.icebreaker,
                greeting: payload.greeting,
            }

        case ActionTypes.UPDATE_TEMP_CONFIGURATION:
            return {
                ...state,
                activePageId: payload.activePageId,
                welcome_message: payload.welcome_message,
                persistent_menu: payload.persistent_menu,
                icebreaker: payload.icebreaker,
                greeting: payload.greeting,
            }

        default:
            return state;
    }
};
