import React, {useEffect, useRef, useState} from "react";
import {Avatar, Button, InputLabel, MobileStepper, Select, TextareaAutosize, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    activeFlowComponentPath,
    activeFlowContainerPath,
    activeFlowSubComponentPath,
    activeHandleComponent,
    addFlowComponentPlainData,
    addFlowPlainData,
    contentControllerOpen,
    createMessengerEvent,
    setFlowPlainData,
    updateGalleryContentData,
    updateInnerContentData
} from "../../../redux/actions/automation";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import clsx from 'clsx';
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded';
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {uploadFile} from "../../../helper/uploadFile";
import {setSnackBar} from "../../../redux/actions/snackbar";
import config from '../../../config';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import {addEdge, Handle} from 'react-flow-renderer';
import ImageSearchRoundedIcon from '@material-ui/icons/ImageSearchRounded';
import IconButton from "@material-ui/core/IconButton";
import InsertLinkRoundedIcon from '@material-ui/icons/InsertLinkRounded';
import QueueRoundedIcon from '@material-ui/icons/QueueRounded';
import SwipeableViews from 'react-swipeable-views';
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import logo from "../../../reuseableComponents/logo";
import FormControl from "@material-ui/core/FormControl";
import QuickReply from "./messengerComponent/quickReply";
import useStyles from "./flowStyles";
import MessageGenericTemplate from "./messengerComponent/messageGenericTemplate";
import Image from "./messengerComponent/image";
import Audio from "./messengerComponent/audio";
import Video from "./messengerComponent/video";
import Attachment from "./messengerComponent/attachment";


export const MessageReceivedContainer = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const activeComponentId = useSelector(state => state.automation.activeFlowComponentPath)
    const activeSubComponentId = useSelector(state => state.automation.activeFlowSubComponentPath)
    const activeContainerPath = useSelector(state => state.automation.activeFlowContainerPath)
    const theme = useTheme();
    const elements = useSelector(state => state.automation?.flowDiagram || [])

    // Fetch Flow Data to update the latest changes
    const element_type = useSelector(state => {
        const flowPlainData = state.automation?.flowPlainData || [];
        const inner_content = flowPlainData[props?.containerPath]?.inner_content || [];
        return inner_content[props.componentPath]?.element_type || ''
    })

    const contentObject = useSelector(state => {
        const flowPlainData = state.automation?.flowPlainData || [];
        const inner_content = flowPlainData[props?.containerPath]?.inner_content || [];
        return inner_content[props?.componentPath]
    })

    const initialData = useSelector(state => {
        const flowPlainData = state.automation?.flowPlainData || [];
        const inner_content = flowPlainData[props?.containerPath]?.inner_content || [];
        return inner_content[props?.componentPath]?.data || {}
    })

    const [flag, setFlag] = useState(false)


    const clickComponentOnContainer = () => {
        dispatch(activeFlowComponentPath(props.componentPath))
    }

    const clickSubComponentOnContainer = (value) => {
        dispatch(activeHandleComponent('text_button'))
        dispatch(activeFlowSubComponentPath(value))
        dispatch(activeFlowComponentPath(props.componentPath))
        dispatch(activeFlowContainerPath(props.containerPath))
    }

    useEffect(() => {
        setFlag(false)
    }, [flag])


    const customHandle = (subId) => {
        return (props.containerPath).toString() + "_" + (props.componentPath).toString() + "_" + subId;
    }


    switch (element_type) {
        case 'MessageReceived':
            return (<>
                {props.flowAsset === true && <div
                    className={clsx(classes.msgReceivedContainer, activeComponentId === props.componentPath ? classes.msgActive : classes.msgRoot)}
                    onClick={() => clickComponentOnContainer()}>
                    {initialData.text}
                </div>}
                {props.flowAsset === false && <div className={classes.msgEditableContainer}>
                    <TextareaAutosize
                        className={clsx(classes.msgReceivedContainer, classes.msgTextArea, activeComponentId === props.componentPath ? classes.msgActive : classes.msgRoot)}
                        aria-label="empty textarea"
                        placeholder="Empty"
                        defaultValue={props.msgItem.data.text}
                        onClick={() => clickComponentOnContainer()}
                        onChange={(e) => {
                            dispatch(updateInnerContentData(props.containerPath, props.componentPath, {
                                type: 'text', text: e.target.value
                            }))
                        }}/>
                    <div className={clsx(classes.msgAction, 'myhandler')}><DragIndicatorRoundedIcon/></div>
                    <div className={clsx(classes.msgAction)}><CloseRoundedIcon/></div>
                </div>}
            </>)
        case 'TextButton':
            return (<>
                {props.flowAsset === true &&
                    <div className={clsx(classes.messageTextButtonMain, classes.flowComponent)}
                         onClick={clickComponentOnContainer}>
                        <div
                            className={clsx(classes.mtbText, classes.msgTextArea, (activeSubComponentId === 0 && activeComponentId === props.componentPath) ? classes.msgActive : classes.msgRoot)}
                            onClick={() => clickSubComponentOnContainer(0)}>
                            {initialData.text}
                        </div>
                        <div
                            className={clsx(contentObject.button_count === 1 ? classes.mtbEndButton : classes.mtbNormalButton, (activeSubComponentId === 1 && activeComponentId === props.componentPath) ? classes.msgActive : classes.msgRoot)}
                            onClick={() => clickSubComponentOnContainer(1)}>
                            {initialData.button[0].button_name}
                            <Handle
                                type="source"
                                id={customHandle("a")}
                                position="right"
                                className={classes.handlerOnBtn}
                                isValidConnection={() => {
                                    clickSubComponentOnContainer(0)
                                    return true
                                }}
                                style={{background: '#fffFFFFFF'}}
                            />
                        </div>
                        {contentObject.button_count > 1 && <div
                            className={clsx(contentObject.button_count === 2 ? classes.mtbEndButton : classes.mtbNormalButton, (activeSubComponentId === 2 && activeComponentId === props.componentPath) ? classes.msgActive : classes.msgRoot)}
                            onClick={() => clickSubComponentOnContainer(2)}>
                            {initialData.button[1].button_name}
                            <Handle
                                type="source"
                                id={customHandle("b")}
                                position="right"
                                className={classes.handlerOnBtn}
                                isValidConnection={() => {
                                    clickSubComponentOnContainer(1)
                                    return true
                                }}
                                style={{background: '#fffFFFFFF'}}
                            />
                        </div>}
                        {contentObject.button_count > 2 && <div
                            className={clsx(classes.mtbEndButton, (activeSubComponentId === 3 && activeComponentId === props.componentPath) ? classes.msgActive : classes.msgRoot)}
                            onClick={() => clickSubComponentOnContainer(3)}>
                            {initialData.button[2].button_name}
                            <Handle
                                type="source"
                                id={customHandle("c")}
                                position="right"
                                className={classes.handlerOnBtn}
                                isValidConnection={() => {
                                    clickSubComponentOnContainer(2)
                                    return true
                                }}
                                style={{background: '#fffFFFFFF'}}
                            />
                        </div>}
                    </div>}

                {props.flowAsset === false && <div className={(classes.msgEditableContainer)}>
                    <div className={clsx(classes.messageTextButtonMain)}>
                        <div>
                            <TextareaAutosize
                                className={clsx(classes.mtbText, classes.msgTextArea, (activeSubComponentId === 0 && activeComponentId === props.componentPath) ? classes.msgActive : classes.msgRoot)}
                                style={{width: '89%'}}
                                placeholder="Enter Your Text Here"
                                defaultValue={initialData.text}
                                onClick={() => clickComponentOnContainer()}
                                onChange={(e) => {
                                    const data = {
                                        type: 'text', text: e.target.value, button: initialData.button
                                    }
                                    dispatch(updateInnerContentData(props.containerPath, props.componentPath, data))
                                }}/>
                        </div>

                        {/*_____________________Buttons_________________*/}
                        <div>
                            <TextareaAutosize
                                className={clsx(contentObject.button_count === 1 ? classes.mtbEndButton : classes.mtbNormalButton, classes.msgTextArea, activeSubComponentId === 1 ? classes.msgActive : classes.msgRoot)}
                                style={{width: '89%'}}
                                placeholder="Button 1"
                                defaultValue={initialData.button[0].button_name}
                                onClick={() => clickComponentOnContainer()}
                                onChange={(e) => {
                                    const data = {
                                        type: 'button_name', index: 0, button_name: e.target.value,
                                    }
                                    dispatch(updateInnerContentData(props.containerPath, props.componentPath, data))
                                    props.isContentLoaded ? props.setLoadedContent(false) : props.setLoadedContent(true)
                                }}
                            />
                        </div>
                        {contentObject.button_count > 1 && <div>
                            <TextareaAutosize
                                className={clsx(contentObject.button_count === 2 ? classes.mtbEndButton : classes.mtbNormalButton, classes.msgTextArea, activeSubComponentId === 2 ? classes.msgActive : classes.msgRoot)}
                                style={{width: '89%'}}
                                placeholder="Button 1"
                                defaultValue={initialData.button[1].button_name}
                                onClick={() => clickComponentOnContainer()}
                                onChange={(e) => {
                                    const data = {
                                        type: 'button_name', index: 1, button_name: e.target.value,
                                    }
                                    dispatch(updateInnerContentData(props.containerPath, props.componentPath, data))
                                    props.isContentLoaded ? props.setLoadedContent(false) : props.setLoadedContent(true)
                                }}
                            />
                        </div>}

                        {contentObject.button_count > 2 && < div>
                            < TextareaAutosize
                                className={clsx(classes.mtbEndButton, classes.msgTextArea, activeSubComponentId === 3 ? classes.msgActive : classes.msgRoot)}
                                style={{width: '89%'}}
                                placeholder="Button 1"
                                defaultValue={initialData.button[2].button_name}
                                onClick={() => clickComponentOnContainer()}
                                onChange={(e) => {
                                    const data = {
                                        type: 'button_name', index: 2, button_name: e.target.value,
                                    }
                                    dispatch(updateInnerContentData(props.containerPath, props.componentPath, data))
                                    props.isContentLoaded ? props.setLoadedContent(false) : props.setLoadedContent(true)
                                }}
                            />
                        </div>}


                    </div>
                    <div className={clsx(classes.msgAction, 'myhandler', classes.extraConfig)}>
                        <DragIndicatorRoundedIcon className={'myhandler'}/>
                        {contentObject.button_count < 3 && <AddBoxRoundedIcon onClick={() => {
                            contentObject.button_count += 1
                            let handle_id = customHandle(contentObject.button_count === 1 ? "a" : contentObject.button_count === 2 ? "b" : contentObject.button_count === 3 ? "c" : '')
                            const new_button = {
                                button_name: 'Button' + ' ' + contentObject.button_count, // phone number, web link, post back
                                button_type: '', button_value: ''
                            }
                            contentObject.data.button.push(new_button)
                            dispatch(updateInnerContentData(props.containerPath, props.componentPath, contentObject.data))
                            props.isContentLoaded ? props.setLoadedContent(false) : props.setLoadedContent(true)
                        }}/>}
                    </div>
                    <div className={clsx(classes.msgAction)}><CloseRoundedIcon/></div>
                </div>}

            </>)
        case 'Image':
            return (<>
                <Image {...props} />
            </>)
        case 'MessageGenericTemplate':
            return (<>
                {props.flowAsset === true && <MessageGenericTemplate flowAsset={true}
                                                                     containerPath={props.containerPath}
                                                                     componentPath={props.componentPath}
                />}

                {props.flowAsset === false && <MessageGenericTemplate flowAsset={false}
                                                                      containerPath={props.containerPath}
                                                                      componentPath={props.componentPath}
                                                                      isContentLoaded={props.isContentLoaded}
                                                                      setLoadedContent={props.setLoadedContent}
                />}
            </>)
        case 'QuickReply':
            let quick_replies = initialData?.quick_replies || false;
            return (<>
                <div className={clsx(classes.msgQuickContainer)}
                     onClick={() => clickComponentOnContainer()}>
                    <div>
                        <TextareaAutosize
                            className={clsx(classes.msgReceivedContainer, classes.msgTextArea, classes.msgRoot)}
                            style={{width: '89%', margin: '1px -10px !important'}}
                            disabled={props.flowAsset ? true : false}
                            placeholder="Enter Your Text Here"
                            defaultValue={initialData.text}
                            onClick={() => clickComponentOnContainer()}
                            onChange={(e) => {
                                const data = {
                                    type: 'quick_reply', text: e.target.value, subType: 'text'
                                }
                                dispatch(updateInnerContentData(props.containerPath, props.componentPath, data))
                            }}/>
                    </div>
                    {quick_replies ?
                        initialData.quick_replies.map((value, i) => {

                            return (<QuickReply flowAsset={props.flowAsset}
                                                containerPath={props.containerPath}
                                                componentPath={props.componentPath}
                                                index={i}
                                                key={i}
                                                value={value}

                            />)
                        }) : null}
                </div>
            </>)

        case 'Audio':
            return (<>
                <Audio {...props}/>
            </>)

        case 'Video':
            return (<>
                <Video {...props}/>
            </>)

        case 'Attachment':
            return (<>
                <Attachment {...props}/>
            </>)

        default:
            return ''
    }
}



