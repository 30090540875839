import {ActionTypes} from "../contants/action-types";
import {postAPI} from "../../config/api/config";
import {setSnackBar} from "./snackbar";

export const fetchAutomationFlowList = () => {
    return async (dispatch, getState) => {
        let flowListData;
        try {
            const userAuth_ = localStorage.getItem("user_auth")
            const userAuth = JSON.parse(userAuth_)

            const response = await postAPI('/api/automation_flow_design/list', {}, userAuth.access_token)
            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                flowListData = JSON.parse(response.data)

                dispatch({
                    type: ActionTypes.FETCH_AUTOMATION_FLOW_LIST,
                    payload: {
                        total_flow: flowListData.total_flow,
                        automationFlowList: flowListData.flow_list
                    }
                })
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    };
};

//Update status of Flow
export const updateFlowStatus = (flow_id, status) => {
    return async (dispatch, getState) => {
        try {
            let flowListData
            const {flowList} = getState();
            const userAuth_ = localStorage.getItem("user_auth")
            const userAuth = JSON.parse(userAuth_)
            let data = {
                flow_id: flow_id,
                status: status,
            }

            const response = await postAPI('/api/automation_flow_design/update/status', data, userAuth.access_token)
            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                flowListData = JSON.parse(response.data)
                Object.assign(flowList.automationFlowList.find(obj => obj.id === flowListData.data.id), flowListData.data)
                dispatch({
                    type: ActionTypes.FETCH_AUTOMATION_FLOW_LIST,
                    payload: {
                        automationFlowList: flowListData.automationFlowList
                    }
                })
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }

    }
}


