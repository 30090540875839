import FormControl from "@material-ui/core/FormControl";
import {InputLabel, Select, TextareaAutosize, TextField} from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import useStyles from "../flowStyles";
import {
    activeFlowComponentPath, activeFlowContainerPath,
    activeFlowSubComponentPath, activeHandleComponent, updateGalleryContentData,
    updateInnerContentData
} from "../../../../redux/actions/automation";
import {useDispatch, useSelector} from "react-redux";
import {Handle} from "react-flow-renderer";
import DragIndicatorRoundedIcon from "@material-ui/icons/DragIndicatorRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {setTextCounterPopper} from "../../../../redux/actions/textCounterPopperAction";

export default function QuickReply(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const quick_replies = useSelector(state => {
        const flowPlainData = state.automation?.flowPlainData || [];
        const inner_content = flowPlainData[props?.containerPath]?.inner_content || [];
        return inner_content[props?.componentPath]?.data.quick_replies || {}
    })

    const update_reply = (e) => {
        const data = {
            type: 'quick_reply',
            subType: 'content_type',
            content_type: e.target.value,
        }
        dispatch(updateInnerContentData(props.containerPath, props.componentPath, data))
    }

    const clickSubComponentOnContainer = (value) => {
        dispatch(activeHandleComponent('quick_reply'))
        dispatch(activeFlowSubComponentPath(value))
    }

    const clickComponentOnContainer = () => {
        dispatch(activeFlowComponentPath(props.componentPath))
    }


    const customHandle = (subId) => {
        return ('q' + '_' + props.containerPath).toString() + "_" + (props.componentPath).toString() + "_" + subId;
    }
    let checkContentType = quick_replies.length < 1 ? false : quick_replies[props.index].content_type === 'email' ? false : quick_replies[props.index].content_type !== 'phone_number'
    return (
        <div style={{display: "flex", flexDirection: "column"}}
             onClick={() => clickSubComponentOnContainer(props.index)}>

            <div style={{display: 'flex', position: 'relative'}}>

                {props.flowAsset ?
                    <div
                        className={clsx(classes.msgReceivedContainer, classes.msgRoot)}>
                        {quick_replies[props.index].title}
                        {checkContentType &&
                            <Handle
                                type="source"
                                id={customHandle(props.index)}
                                className={classes.handlerQuickReply}
                                position="right"
                                isValidConnection={() => {
                                    clickSubComponentOnContainer(props.index)
                                    clickComponentOnContainer()
                                    return true
                                }}
                            />}
                    </div>
                    : <>
                        <select
                            // native
                            className={classes.selectQuickReply}
                            onChange={(e) => update_reply(e)}>
                            {quick_replies[props.index].content_type ?
                                <option value={quick_replies[props.index].content_type.toString()} selected>
                                    {quick_replies[props.index].content_type === 'text' ? 'Text' :
                                        quick_replies[props.index].content_type === 'phone_number' ? 'Phone Number' :
                                            quick_replies[props.index].content_type === 'email' ? 'Email' : ''
                                    }
                                </option> : ''}

                            <option aria-label="None" value="" />
                            <option value={'text'}>Text</option>
                            <option value={'phone_number'}>Phone Number</option>
                            <option value={'email'}>Email</option>
                        </select>

                        <input type={"text"} defaultValue={quick_replies[props.index].title}
                               className={props.flowAsset ? classes.quickReply : classes.inputQuick}
                               onChange={(e) => {
                                   const data = {
                                       type: 'quick_reply',
                                       subType: 'title',
                                       title: e.target.value,
                                   }
                                   // dispatch(setTextCounterPopper({target: e.currentTarget}))
                                   dispatch(updateInnerContentData(props.containerPath, props.componentPath, data))
                               }}
                        />
                        <div className={clsx(classes.msgAction)}><CloseRoundedIcon/></div>
                    </>
                }
            </div>

        </div>
    )
}