import {ActionTypes} from "../contants/action-types";

const initialState = {
    page_list: []
};
export const addPageReducer = (state = initialState, {type, payload, pageDetail}) => {
    switch (type) {
        case ActionTypes.SET_ADD_PAGE_DATA:
            return {...state, page_list: payload};

        case ActionTypes.UPDATE_PAGE_DATA:
            let newArray = [...pageDetail.page_list]
            let index = newArray.find(value => value.id === payload.id).index
            newArray[index] = payload
            return {...state, page_list: newArray}
        default:
            return state;
    }
};
