import {ActionTypes} from "../contants/action-types";

export const setUserProfileDetail = (profile) => {
    return {
        type: ActionTypes.SET_USER_PROFILE_DATA,
        payload: profile,
    };
};

export const setAddress = (address) => {
    return {
        type: ActionTypes.SET_ADDRESS,
        payload: address,
    };
};

export const changeAddressValue = (key, value) => {

    return async (dispatch, getState) => {
        let {profileDetail} = getState()
        profileDetail.address[key] = value
        dispatch({
            type: ActionTypes.SET_ADDRESS,
            payload: profileDetail.address,
        });
    }
};