import { ActionTypes } from "../contants/action-types";

const initialState = {
    promotionList: [],
    activePromotionId: null,
};

export const promotionReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case ActionTypes.CREATE_PROMOTION_LIST:
            return { ...state, promotionList: payload.data };

        case ActionTypes.FETCH_PROMOTION_LIST:
            return { ...state, promotionList: payload.data };

        case ActionTypes.UPDATE_PROMOTION_LIST:
            return { ...state, promotionList: payload.data };

        case ActionTypes.SET_ACTIVE_PROMOTION_ID:
            return { ...state, activePromotionId: payload.data };


        default:
            return state;
    }
};
