import { ActionTypes } from "../contants/action-types";

const initialState = {
  user_auth: {},
};
export const userAccountReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_USER_AUTH_DATA:
      return { ...state, user_auth: payload.data };
    default:
      return state;
  }
};
