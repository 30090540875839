import "./App.css";
import {Routes, Route} from "react-router-dom";
import Dashboard from "./page/dashboard/containers/dashboardContainer";
import MainModuleContainer from "./page/configModules/containers/mainModuleContainer";
import ForgetPassword from "./page/signup/containers/forgetPasswordContainer";
import SignUp from "./page/signup/containers/signupContainer";
import SignInSide from "./page/login/containers/loginContainer";
import Contacts from "./page/contacts/containers/contactContainer";
import MyAutomation from "./page/automation/containers/automationContainer";
import PageNotFound from "./page/Error/containers/PageNotFound";
import {PublicRoute, ConditionalRoute, PrivateRoute} from "./middleware/authRoute";
import CustomizedSnackbars from "./reuseableComponents/snackbar/snackbar";
import {useInitFbSDK} from "./hooks/fb-hooks";
import AutomationFlowList from "./page/automation/containers/automationFlowList";
import AutomationBasic from "./page/automation/containers/basic_automation";
import WelcomeMessage from "./page/automation/containers/welcome_message";
import IceBreaker from "./page/automation/containers/ice_breaker";
import Greeting from "./page/automation/containers/greeting";
import PersistentMenu from "./page/automation/containers/persistent_menu";
import Promotion from "./page/automation/containers/promotion";
import AccountSetting from "./page/settings/containers/settingContainer";
import UserProfile from "./page/profile/containers/profileContainer";

function App() {
    useInitFbSDK();
    return (
        <>
            <CustomizedSnackbars/>
            <Routes>
                <Route path="/" element={<PublicRoute><SignInSide/></PublicRoute>}/>
                <Route path="/signup" element={<PublicRoute><SignUp/></PublicRoute>}/>
                <Route path="/forgetPassword" element={<PublicRoute><ForgetPassword/></PublicRoute>}/>
                <Route path="/modules" element={<ConditionalRoute><MainModuleContainer/></ConditionalRoute>}/>
                <Route path="/profile" element={<ConditionalRoute><SignUp formType={"ProfileForm"}/></ConditionalRoute>}/>
                <Route path="/dashboard" element={<PrivateRoute><Dashboard/></PrivateRoute>}/>
                <Route path="/contacts" element={<PrivateRoute><Contacts/></PrivateRoute>}/>
                {/*<Route path="/automation" element={<PrivateRoute><AutomationFlowList/></PrivateRoute>}/>*/}
                <Route path="/playground/:uuid" element={<PrivateRoute><MyAutomation/></PrivateRoute>}/>
                <Route path="/automation" element={<PrivateRoute><AutomationBasic/></PrivateRoute>}/>
                <Route path="/automation/welcome_message/" element={<PrivateRoute><WelcomeMessage/></PrivateRoute>}/>
                <Route path="/automation/ice_breaker/" element={<PrivateRoute><IceBreaker/></PrivateRoute>}/>
                <Route path="/automation/greeting/" element={<PrivateRoute><Greeting/></PrivateRoute>}/>
                <Route path="/automation/presistent_menu/" element={<PrivateRoute><PersistentMenu/></PrivateRoute>}/>
                <Route path="/automation/promotion/" element={<PrivateRoute><Promotion/></PrivateRoute>}/>
                <Route path="/userProfile" element={<PrivateRoute><UserProfile/></PrivateRoute>}/>
                <Route path="/settings" element={<PrivateRoute><AccountSetting/></PrivateRoute>}/>
                <Route path="*" element={<PrivateRoute><PageNotFound/></PrivateRoute>}/>
            </Routes>
        </>
    );
};

export default App;
