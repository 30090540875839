export const mainContainer = {
    background: '#FFF',
    color: '#333',
    border: '1px solid #222138',
    width: 300,
    height: 'fit-content',
    borderRadius: 20,
    borderColor: '#0084FF',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
}

export const msgSentContainer = {
    width: '218px',
    padding: '12px 8px',
    margin: '10px 0px 10px auto',
    fontFamily: 'SF UI Text, Regular',
    fontSize: '17px',
    lineHeight: '20px',
    textAlign: 'left',
    backgroundColor: '#0084FF',
    color: '#FFFFFF',
    // border:'dotted 2px #6a7fd28a',
    borderRadius: '18px',
}


export const msgReceivedBubble2 = {
    padding: '12px 8px',
    margin: '10px auto 10px 0px',
    fontFamily: 'SF UI Text, Regular',
    fontSize: '17px',
    lineHeight: '20px',
    textAlign: 'left',
    backgroundColor: '#F1F0F0',
    color: '#000000',
    // border:'dotted 2px #6a7fd28a',
    borderRadius: '4px 18px 18px 18px'
}

export const msgReceivedBubble1 = {
    padding: '12px 8px',
    margin: '10px auto 10px 0px',
    fontFamily: 'SF UI Text, Regular',
    fontSize: '17px',
    lineHeight: '20px',
    textAlign: 'left',
    backgroundColor: '#F1F0F0',
    color: '#000000',
    borderRadius: '18px 18px 18px 4px'
}

export const containerHeader = {
    backgroundImage: '',
    height: '52px',
    display: 'flex',
    gridGap: '10px',
    fontSize: '26px',
    fontWeight: 'bold',
    fontFamily: 'SF UI Text, Bold',
    marginBottom: '2px',
    padding: '4px 47px'
}

export const messengerHeader = {
    backgroundImage: '',
    height: '52px',
    display: 'flex',
    gridGap: '10px',
    fontSize: '26px',
    fontWeight: 'bold',
    fontFamily: 'SF UI Text, Bold',
    marginBottom: '2px',
    padding: '4px 81px'
}