import React, {memo} from 'react';
import {Handle} from 'react-flow-renderer';

export default memo(({data, isConnectable}) => {
    return (
        <div>
            <Handle
                type="target"
                id="x"
                position="left"
                style={{background: '#555'}}
                onConnect={(params) => console.log('handle onConnect', params)}
                isConnectable={isConnectable}
            />
            <div>
                {data.label}
            </div>
        </div>
    );
});