import React, {useState} from 'react';
import OTPInput from "../../../reuseableComponents/otpInput";
import "../style.css"
import {Formik} from "formik";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Grid, makeStyles} from "@material-ui/core";
import ButtonWrapper from "../../../reuseableComponents/Form_UI/ButtonWrapper";
import {postAPI} from "../../../config/api/config";


const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    helpText: {
        textAlign: "center",
        fontWeight: "bold"
    },
    error:{
        color:'red',
        textAlign:'center',
    }

}));


function OtpVerification(props) {
    const classes = useStyles();
    const [otp, setOTP] = useState("");

    const INITIAL_FORM_STATE = {
        code: "",
        email: props.email
    };

    const FORM_VALIDATION = (values) => {
        const errors = {}
        if (!otp) {
            errors.code = 'Code must be entered.';
        } else if (otp.length !== 4) {
            errors.code = 'Code must be 4 numbers'
        }
        return errors;
    }


    const submitVerificationCode = async (data) => {
        const response = await postAPI('/api/user/verifyEmailCode/', data)
        const res_data = JSON.parse(response.data)
        if (response.status === 200) {
            if (props.formType === 'forget_password') {
                props.method("ConfirmPasswordForm")

            } else {
                props.method("SignUpForm")
            }
            await props.setOpen({status: true, message: res_data.message, type: "success"})

        } else {
            await props.setOpen({status: true, message: res_data.message, type: "error"})
        }
    }

    return (
        <Formik
            initialValues={{...INITIAL_FORM_STATE}}
            validate={FORM_VALIDATION}
            onSubmit={async (values) => {
                values.code = otp;
                await submitVerificationCode(values);
            }}
        >{({errors, touched, handleBlur}) => (
            <form className={classes.form}>
                <CssBaseline/>
                <Grid container spacing={2}>
                    <Grid item xs={12} className={classes.helpText}>
                        Please Enter Code Sent to {props.email}.
                    </Grid>
                    <Grid item xs={12}>
                        <OTPInput autoFocus
                            // onBlur={handleBlur}
                                  isNumberInput
                                  length={4}
                                  className="otpContainer"
                                  inputClassName="otpInput"
                                  onChangeOTP={(otp) => setOTP(otp)}/>
                        {errors.code && touched.code && <p className={classes.error}>{errors.code}</p> }
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonWrapper children="Submit"/>
                    </Grid>
                </Grid>
            </form>
        )}
        </Formik>
    );
}

export default OtpVerification;
