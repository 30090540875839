import {postAPI} from "../../config/api/config";
import {setSnackBar} from "./snackbar";
import {ActionTypes} from "../contants/action-types";
import {fetchPromotionList} from "./promotionAction";

export const fetchMessengerConfiguration = (page_id, config_type) => {
    return async (dispatch, getState) => {
        let returnData;
        let action;
        try {
            const userAuth_ = localStorage.getItem("user_auth")
            const response = await postAPI('/api/messenger_config/detail', {
                config_type: config_type,
                page_id: page_id
            }, JSON.parse(userAuth_).access_token)

            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                returnData = JSON.parse(response.data).data
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }

        if (config_type === 'welcome_message') {
            action = ActionTypes.FETCH_WELCOME_MESSAGE
        } else if (config_type === 'icebreaker') {
            action = ActionTypes.FETCH_ICEBREAKER
        } else if (config_type === 'persistent_menu') {
            action = ActionTypes.FETCH_PERSISTENT_MENU
        } else if (config_type === 'greeting') {
            action = ActionTypes.FETCH_GREETING
        } else if (config_type === 'promotion') {
            action = ActionTypes.FETCH_PROMOTION
        }

        dispatch({
            type: action,
            payload: returnData
        })
    }
};


export const setActivePageConfig = (page_id, config_type) => {
    return async (dispatch, getState) => {
        if (config_type === 'promotion') {
            dispatch(fetchPromotionList(page_id))
        } else {
            dispatch(fetchMessengerConfiguration(page_id, config_type))
        }
        dispatch({
                type: ActionTypes.SET_ACTIVE_PAGE_CONFIG,
                payload: {
                    data: page_id
                }
            }
        )
    }
}


export const updateMessengerConfiguration = (config_type) => {
    return async (dispatch, getState) => {
        let {messengerConfig, pageDetail} = getState();
        let returnData;
        let action;
        let page_id = pageDetail?.page_list.find(obj => obj.id === messengerConfig.activePageId)?.page_id || null
        let instance = {config_type: config_type, page_id: page_id};

        try {
            if (config_type === 'welcome_message') {
                instance.flow_id = messengerConfig.welcome_message?.flow_id || null;
            } else if (config_type === 'persistent_menu') {
                instance.call_to_actions = messengerConfig.persistent_menu?.call_to_actions || null
            } else if (config_type === 'icebreaker') {
                instance.ice_breakers = messengerConfig.icebreaker?.ice_breakers || null
            }

            const userAuth_ = localStorage.getItem("user_auth")
            const response = await postAPI('/api/messenger_config/update', instance, JSON.parse(userAuth_).access_token)

            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                returnData = JSON.parse(response.data).data

                dispatch({
                    type: ActionTypes.UPDATE_MESSENGER_CONFIGURATION,
                    payload: {
                        activePageId: returnData.page_id,
                        welcome_message: returnData.welcome_message,
                        persistent_menu: returnData.persistent_menu,
                        icebreaker: returnData.icebreaker,
                        greeting: returnData.greeting,
                    }

                })

                dispatch(setSnackBar({status: true, message: "Changes Stored Successfully", alert: "success"}))
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    }
};

export const updateConfiguration = (config_type, obj) => {
    return async (dispatch, getState) => {
        let {messengerConfig} = getState();

        if (config_type === 'welcome_message' && obj?.id) {
            if (messengerConfig.welcome_message) {
                messengerConfig.welcome_message.flow_id = obj.id
            } else {
                messengerConfig.welcome_message = {flow_id: obj.id}
            }

            dispatch({
                type: ActionTypes.UPDATE_TEMP_CONFIGURATION,
                payload: {
                    activePageId: messengerConfig.activePageId,
                    welcome_message: messengerConfig.welcome_message,
                    persistent_menu: messengerConfig.persistent_menu,
                    icebreaker: messengerConfig.icebreaker,
                    greeting: messengerConfig.greeting,
                }

            })

            // dispatch(updateMessengerConfiguration('welcome_mesage'))

        } else if (config_type === 'persistent_menu') {
            messengerConfig.persistent_menu.call_to_actions[obj.index].title = obj.title
            messengerConfig.persistent_menu.call_to_actions[obj.index].flow_id = obj.flow_id
            dispatch(updateMessengerConfiguration('persistent_menu'))
        } else if (config_type === 'icebreaker') {
            messengerConfig.icebreaker.ice_breakers[obj.index].question = obj.question
            messengerConfig.icebreaker.ice_breakers[obj.index].flow_id = obj.flow_id

            dispatch(updateMessengerConfiguration('icebreaker'))
        }
    }
}

export const addNewPersistentMenu = (obj) => {
    return async (dispatch, getState) => {
        let {messengerConfig, pageDetail} = getState();
        let page_id = pageDetail?.page_list.find(obj => obj.id === messengerConfig.activePageId)?.page_id || null
        let instance = {config_type: 'persistent_menu', page_id: page_id, call_to_actions: []};
        let returnData;
        try {
            if (messengerConfig.persistent_menu !== null) {
                instance.call_to_actions = messengerConfig.persistent_menu.call_to_actions
            }
            instance.call_to_actions.push(obj)

            const userAuth_ = localStorage.getItem("user_auth")
            const response = await postAPI('/api/messenger_config/update', instance, JSON.parse(userAuth_).access_token)

            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                returnData = JSON.parse(response.data).data

                dispatch({
                    type: ActionTypes.UPDATE_MESSENGER_CONFIGURATION,
                    payload: {
                        activePageId: returnData.page_id,
                        welcome_message: returnData.welcome_message,
                        persistent_menu: returnData.persistent_menu,
                        icebreaker: returnData.icebreaker,
                        greeting: returnData.greeting,
                    }

                })

                dispatch(setSnackBar({status: true, message: "Changes Stored Successfully", alert: "success"}))
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    }
}


export const addNewIceBreaker = (obj) => {
    return async (dispatch, getState) => {
        let {messengerConfig, pageDetail} = getState();
        let page_id = pageDetail?.page_list.find(obj => obj.id === messengerConfig.activePageId)?.page_id || null
        let instance = {config_type: 'icebreaker', page_id: page_id, ice_breakers: []};
        let returnData;
        try {
            if (messengerConfig.icebreaker !== null) {
                instance.ice_breakers = messengerConfig.icebreaker.ice_breakers
            }
            instance.ice_breakers.push(obj)
            const userAuth_ = localStorage.getItem("user_auth")
            const response = await postAPI('/api/messenger_config/update', instance, JSON.parse(userAuth_).access_token)
            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                returnData = JSON.parse(response.data).data

                dispatch({
                    type: ActionTypes.UPDATE_MESSENGER_CONFIGURATION,
                    payload: {
                        activePageId: returnData.page_id,
                        welcome_message: returnData.welcome_message,
                        persistent_menu: returnData.persistent_menu,
                        icebreaker: returnData.icebreaker,
                        greeting: returnData.greeting,
                    }

                })
                dispatch(setSnackBar({status: true, message: "Changes Stored Successfully", alert: "success"}))
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    }
}


export const addNewPromotion = (obj) => {
    return async (dispatch, getState) => {
        let {messengerConfig, pageDetail} = getState();
        let page_id = pageDetail?.page_list.find(obj => obj.id === messengerConfig.activePageId)?.page_id || null
        let instance = {
            config_type: 'promotion',
            page_id: page_id,
            promotion_list: []
        };
        let returnData;
        try {
            if (messengerConfig.promotion !== null) {
                instance.promotion_list = messengerConfig.promotion.promotion_list
            }
            instance.promotion_list.push(obj)
            const userAuth_ = localStorage.getItem("user_auth")
            const response = await postAPI('/api/messenger_config/update', instance, JSON.parse(userAuth_).access_token)
            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                returnData = JSON.parse(response.data).data

                dispatch({
                    type: ActionTypes.UPDATE_MESSENGER_CONFIGURATION,
                    payload: {
                        activePageId: returnData.page_id,
                        welcome_message: returnData.welcome_message,
                        persistent_menu: returnData.persistent_menu,
                        icebreaker: returnData.icebreaker,
                        greeting: returnData.greeting,
                        promotion: returnData.promotion,
                    }

                })
                dispatch(setSnackBar({status: true, message: "Changes Stored Successfully", alert: "success"}))
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    }
}


export const publishMessengerConfigurations = (config_type) => {
    return async (dispatch, getState) => {
        let {messengerConfig, pageDetail} = getState();
        let page_id = pageDetail?.page_list.find(obj => obj.id === messengerConfig.activePageId)?.page_id || null

        let instance = {
            config_type: config_type,
            page_id: page_id,
        };

        let returnData;
        try {
            const userAuth_ = localStorage.getItem("user_auth")
            const response = await postAPI('/api/messenger_config/publish', instance, JSON.parse(userAuth_).access_token)
            returnData = JSON.parse(response.data)
            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: returnData.message, alert: "error"}))
            } else {
                dispatch(setSnackBar({status: true, message: returnData.message, alert: "success"}))
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    }
}


export const publishCustomUserSettings = (config_type) => {
    return async (dispatch, getState) => {
        let {messengerConfig, pageDetail} = getState();
        let page_id = pageDetail?.page_list.find(obj => obj.id === messengerConfig.activePageId)?.page_id || null

        let instance = {
            page_id: page_id,
        };

        let returnData;
        try {
            const userAuth_ = localStorage.getItem("user_auth")
            const response = await postAPI('/api/messenger_config/persistent_menu/publish', instance, JSON.parse(userAuth_).access_token)
            returnData = JSON.parse(response.data)
            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: returnData.message, alert: "error"}))
            } else {
                dispatch(setSnackBar({status: true, message: returnData.message, alert: "success"}))
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    }
}