import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from "react-redux";
import SaveIcon from "@material-ui/icons/Save";
import clsx from "clsx";
import {fetchAutomationFlowList} from "../../../redux/actions/automationFlowList";
import {TextareaAutosize} from "@material-ui/core";
import SwitchFbPages from "../../../primaryComponent/switchFbPages";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    Layout: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }, mainContent: {
        width: '80%',
        margin: '40px auto'
    }, mainContentItems: {
        margin: '10px'
    }, controllerContainer: {
        display: "flex"
    },
    items: {
        margin: '10px'
    },
    previewContainer: {
        height: '100%',
        margin: '30px',
    },
    image: {
        width: '400px',
        height: '200px',
        objectFit: 'contain'
    }

}));

export default function Greeting() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const flowList = useSelector(state => {
        return state.flowList.automationFlowList || []
    })

    const greeting = useSelector(state => {
        return state.messengerConfig.greeting || []
    })
    const [update, setUpdate] = useState(false)
    useEffect(() => {
        dispatch(fetchAutomationFlowList())
    }, [])

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={12} sm={4} md={8} component={"div"} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Greeting
                    </Typography>
                    <SwitchFbPages config_type={"greeting"}/>
                    <div className={classes.Layout}>
                        <Typography variant="h5" align="center" color="textSecondary" component="p">
                            Quickly build an effective pricing table for your potential customers with this layout.
                            It&apos;s built with default Material-UI components with little customization.
                        </Typography>
                        <Grid container spacing={3} direction="column" className={classes.mainContent}
                              justifyContent={"center"}>
                            <Grid item xs={12} direction={"column"}>
                                <div className={classes.mainContentItems}>
                                    <TextField
                                        // defaultValue="Hello World"
                                        variant="outlined"
                                        placeholder="Question: Place you question here to start conversation."
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.mainContentItems}>
                                    <TextField
                                        // defaultValue="Hello World"
                                        variant="outlined"
                                        row={6}
                                        placeholder="Question: Place you question here to start conversation."
                                        fullWidth
                                    />
                                    <TextareaAutosize maxRows={6} aria-label="empty textarea" placeholder="Empty"
                                                      aria-label="maximum height"/>
                                </div>
                                <div className={classes.controllerContainer}>
                                    <div className={classes.mainContentItems}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            className={clsx(classes.items)}
                                            startIcon={<SaveIcon/>}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Grid>
            <Grid item xs={false} sm={8} md={4}>
                <div className={classes.previewContainer}>
                    <img src="/welcome_greeting.png" className={classes.image}></img>
                </div>
            </Grid>
        </Grid>
    );
}