import React, {useEffect, useState} from 'react'
import {DataGrid} from "@material-ui/data-grid";
import {getAPI} from "../../../config/api/config";
import {useInitFbSDK} from "../../../hooks/fb-hooks";
import {Avatar, styled} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {activeProfileModel, storePageContact} from "../../../redux/actions/pageContact";
import {getGraphAPI} from "../../../config/api/graphFbConfig";
import ProfileModal from "./profileModal";
import {setSnackBar} from "../../../redux/actions/snackbar";

const columns = [
    {field: 'counter', headerName: 'SN', width: 100},
    {
        field: "profile_pic",
        headerName: 'Avatar',
        width: 150,
        // renderCell: (params) => console.log(params),
        renderCell: (params) => <Avatar variant="circular" size={2} alt='' src={params.value}/>,
    },
    {
        field: 'first_name',
        headerName: 'First name',
        width: 150,
        editable: true,
    },
    {
        field: 'last_name',
        headerName: 'Last name',
        width: 150,
        editable: true,
    },
    {
        field: 'gender',
        headerName: 'Gender',
        type: 'text',
        width: 110,
        editable: true,
    },
    {
        field: 'createdAt',
        headerName: 'Joined',
        type: 'text',
        width: 160,
        editable: true,
    },
    {
        field: 'updatedAt',
        headerName: 'Last Messaged',
        type: 'text',
        width: 160,
        editable: true,
    },
];

const fetchContacts = async (pageId) => {
    try {
        let user_auth = localStorage.getItem('user_auth')
        let access_token = JSON.parse(user_auth).access_token
        let params = {
            page_id: pageId,
            page: 0,
            page_limit: 6
        }
        return await getAPI('api/contact/userlist', params, access_token)
    } catch (e) {
        console.log(e)
    }
}

export default function ContactTable(props) {
    useInitFbSDK();
    let pg_string = localStorage.getItem('pg')
    let pg_list = JSON.parse(pg_string)
    let pg_id = pg_list ? pg_list[props.pageIndex]?.page_id : null;
    let pg_access_token = pg_list ? pg_list[props.pageIndex]?.access_token : null;
    const activeContact = useSelector(store => store.pageContact.activeContact)
    const activeProfileId = useSelector(store => store.pageContact.id)
    let rows = useSelector(store => store.pageContact?.page.contact_detail) || []
    const dispatch = useDispatch();

    useEffect(() => {
        if (pg_id) {
            async function FetchContactsLists() {
                try {
                    let contactDetail = await fetchContacts(pg_id);
                    dispatch(storePageContact(pg_id, JSON.parse(contactDetail.data)))
                } catch (e) {
                    dispatch(setSnackBar({status: true, message: 'Fail to fetch Contacts', alert: "error"}));
                }
            }

            FetchContactsLists().then(() => {
            });
        }
    }, [props.pageIndex, window.FB])

    const handleClickOpen = (value) => {
        dispatch(activeProfileModel(true, value.row.id))
    };

    const handleClose = () => {
        dispatch(activeProfileModel(false, 1))
    };

    let new_rows = []
    if (rows.length !== 0) {
        rows.map((value, index) => {
            new_rows.push({counter: index + 1, ...value})
        })
    }


    return (
        <>
            <DataGrid
                rows={new_rows ? new_rows : []}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                onRowClick={(value) => {
                    handleClickOpen(value)
                }}
            />
            {activeContact &&
                <ProfileModal selectedRow={activeProfileId} open={activeContact} handleClose={handleClose}/>}
        </>
    )
}
