import {ActionTypes} from "../contants/action-types";
// import store from '../store';
const initialState = {
    activePage: "",
    page: {},
    activeContact: false,
    activeCustomerId: 1,
    activeFilterComponent: false,
    tag_list: [],
    selectedTags:[]
};
let contactDetail = [];
export const pageContactReducer = (state = initialState, {type, page_contact, payload}) => {
    switch (type) {
        case ActionTypes.ACTIVE_PROFILE_MODEL:
            return {...state, activeContact: payload.status, activeCustomerId: payload.id};

        case ActionTypes.SET_ACTIVE_PAGE_CONTACT:
            return {...state, activePage: payload};

        case ActionTypes.ACTIVE_FILTER_COMPONENT:
            return {...state, activeFilterComponent: payload};

        case ActionTypes.ADD_NEW_TAG:
            return {...state, tag_list: [...state.tag_list, payload]};

        case ActionTypes.FETCH_CLIENT_TAGS:
            return {...state, tag_list: payload};

        case ActionTypes.ASSIGN_TAG_CUSTOMER:
            return {...state, page: payload.page};

        case ActionTypes.REMOVE_TAG_CUSTOMER:
            return {...state, page: payload.page};

        case ActionTypes.STORE_PAGE_CONTACTS_DATA:
            return {...state, page: payload};

        case ActionTypes.FILTER_SELECTED_TAGS:
            return {...state, selectedTags: [...state.selectedTags, payload]};

        case ActionTypes.FILTER_UPDATE_SELECTED_TAGS:
            return {...state, selectedTags: payload};
        default:
            return state;
    }
};
