import {applyMiddleware, compose, createStore} from "redux";
import thunk from 'redux-thunk';
import ReduxPromise from "redux-promise";
import reducers from "./reducers";

const middlewares = [thunk, ReduxPromise];

export const store = createStore(reducers,  applyMiddleware(...middlewares));

// const composedEnhancers = compose(
//     applyMiddleware(...middlewares),
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// )
//
// export const store = createStore(
//     reducers,
//     {},
//     composedEnhancers
// );
//
// export default store
