import {postAPI} from "../config/api/config";

export const fetchProfileDetail = async (account_id, access_token) => {
    try {
        const resProfile = await postAPI("/api/profile/detail", {
            'account_id': account_id,
        }, access_token);
        if (resProfile.status === 200) {
            const resData = JSON.parse(resProfile.data)
            let res_profile_string = JSON.stringify(resData.data)
            let checkProfileComplete = resData.profile_complete
            localStorage.setItem("profile_detail", res_profile_string)
            return checkProfileComplete
        } else {
            localStorage.setItem("profile_detail", null)
            return false
        }
    } catch (e) {
        console.log('Profile Failed to import')
    }
}