import {ActionTypes} from "../contants/action-types";
import {getAPI, postAPI} from "../../config/api/config";
import {setSnackBar} from "./snackbar";
import axios from "axios";

export const storePageContact = (page_id, contactDetail) => {
    return (dispatch, getState) => {
        const {pageContact, pageDetail} = getState();
        const pg_access_token = pageDetail.page_list.find(object => object.page_id === page_id).access_token
        dispatch({
            type: ActionTypes.STORE_PAGE_CONTACTS_DATA, page_contact: pageContact, payload: {
                page_id: page_id,
                totalItems: contactDetail.totalItems,
                totalPage: contactDetail.totalPage,
                currentPage: contactDetail.currentPage,
                contact_detail: contactDetail.data
            }
        })
    };
};

export const setActivePageContact = (value) => {
    return {
        type: ActionTypes.SET_ACTIVE_PAGE_CONTACT, payload: value,
    };
};

export const activeProfileModel = (status, id) => {
    return {
        type: ActionTypes.ACTIVE_PROFILE_MODEL, payload: {
            status: status, id: id
        },
    };
};

export const activeFilterComponent = (value) => {
    return {
        type: ActionTypes.ACTIVE_FILTER_COMPONENT, payload: value
    };
};

export const loadClientTags = () => {
    return async (dispatch, getState) => {

        try {
            const user_auth = JSON.parse(localStorage.getItem("user_auth"));
            const response = await getAPI('/api/tag/list', {}, user_auth.access_token)

            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: 'Connection failed with Server', alert: "error"}));
            }

            let tag_list = JSON.parse(response.data).data

            dispatch({
                type: ActionTypes.FETCH_CLIENT_TAGS, payload: tag_list
            })

        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }


    };

};

export const addNewTag = async (value) => {
    return async (dispatch, getState) => {
        try {
            const user_auth = JSON.parse(localStorage.getItem("user_auth"));
            const data = {
                tag_name: value
            }
            const response = await postAPI('/api/tag/create', data, user_auth.access_token)
            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: JSON.parse(response.data).message, alert: "error"}));
            } else {
                const new_tag = JSON.parse(response.data).data
                dispatch({
                    type: ActionTypes.ADD_NEW_TAG, payload: new_tag
                })
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    };
};

export const assignTagCustomer = (customerId, tag_id) => {
    return async (dispatch, getState) => {
        const {pageContact} = getState();
        try {
            let user_auth = localStorage.getItem('user_auth')
            const data = {
                tag_id: tag_id, contact_id: customerId,
            }

            let response = await postAPI('/api/tag/assign_tag', data, JSON.parse(user_auth).access_token)
            if (response.status !== 200) {
                dispatch(setSnackBar({
                    status: true, message: JSON.parse(response.data).message, alert: "error"
                }));
            } else {
                const assigned_tag_detail = JSON.parse(response.data).data
                const contact_detail = pageContact.page?.contact_detail || []
                const customer_obj = contact_detail.find(object => object.id === customerId)?.tag_list.push(assigned_tag_detail) || []

                dispatch({
                    type: ActionTypes.ASSIGN_TAG_CUSTOMER, payload: {
                        page: pageContact.page
                    }
                })
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    };
};


export const removeTagCustomer = (customer_id, tag_id) => {
    return async (dispatch, getState) => {
        const {pageContact} = getState();
        try {
            let user_auth = localStorage.getItem('user_auth')
            const data = {
                tag_id: tag_id, contact_id: customer_id,
            }
            let response = await postAPI('/api/tag/delete/assign_tag', data, JSON.parse(user_auth).access_token)
            if (response.status !== 200) {

                dispatch(setSnackBar({
                    status: true, message: 'Connection failed with Server :' + response.message, alert: "error"
                }));
            } else {
                dispatch(setSnackBar({
                    status: true, message: 'Tag Unassigned Successfully', alert: "error"
                }));
                const contact_detail = pageContact.page?.contact_detail || [];
                const tag_index = contact_detail.find(object => object.id === customer_id)?.tag_list.findIndex(tag_obj => tag_obj.id === tag_id)
                const tag_obj = contact_detail.find(object => object.id === customer_id)?.tag_list.splice(tag_index, 1) || []

                dispatch({
                    type: ActionTypes.REMOVE_TAG_CUSTOMER, payload: {
                        page: pageContact.page
                    }
                })
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    };
};


export const searchContactByText = (page_id, keyword) => {
    return async (dispatch, getState) => {
        let response;
        try {
            let user_auth = localStorage.getItem('user_auth')
            const param = {
                page_id: page_id, page: 0, //pagination page
                page_limit: 10, //pagination data per page
                text: keyword
            }
            response = await getAPI('/api/contact/searchByString', param, JSON.parse(user_auth).access_token)
            if (response.status !== 200) {
                dispatch(setSnackBar({
                    status: true, message: JSON.parse(response.data).message, alert: "error"
                }));
            } else {
                let contactList = JSON.parse(response.data)
                dispatch({
                    type: ActionTypes.STORE_PAGE_CONTACTS_DATA, payload: {
                        page_id: page_id,
                        totalItems: contactList.totalItems,
                        totalPage: contactList.totalPage,
                        currentPage: contactList.currentPage,
                        contact_detail: contactList.data
                    }
                })

            }

        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    };
};


export const searchContactByTagId = (page_id, tag_id_list) => {
    return async (dispatch, getState) => {
        let response;
        try {
            let user_auth = localStorage.getItem('user_auth')
            const param = {
                page_id: page_id, page: 0, //pagination page
                page_limit: 10, //pagination data per page
                tag_id_list: tag_id_list.toString()
            }
            response = await getAPI('/api/contact/searchByTagId', param, JSON.parse(user_auth).access_token)
            if (response.status !== 200) {
                dispatch(setSnackBar({
                    status: true, message: JSON.parse(response.data).message, alert: "error"
                }));
            } else {
                let contactList = JSON.parse(response.data)
                dispatch({
                    type: ActionTypes.STORE_PAGE_CONTACTS_DATA, payload: {
                        page_id: page_id,
                        totalItems: contactList.totalItems,
                        totalPage: contactList.totalPage,
                        currentPage: contactList.currentPage,
                        contact_detail: contactList.data
                    }
                })

            }

        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    };
};

export const filterSelectedTag = (selected_tag) => {
    return {
        type: ActionTypes.FILTER_SELECTED_TAGS, payload: parseInt(selected_tag)
    }
};

export const filterUpdateSelectedTag = (selected_tag) => {
    return (dispatch, getState) => {
        const {pageContact} = getState();
        pageContact?.selectedTags.splice(pageContact?.selectedTags.indexOf(parseInt(selected_tag)), 1)
        dispatch({
            type: ActionTypes.FILTER_UPDATE_SELECTED_TAGS,
            payload: pageContact.selectedTags.length > 0 ? pageContact.selectedTags : []
        })
    };
};

export const deleteClientTag = (tag_id) => {
    return async (dispatch, getState) => {
        try {
            let user_auth = localStorage.getItem('user_auth')
            const data = {
                tag_id: tag_id,
            }
            let response = await postAPI('/api/tag/delete', data, JSON.parse(user_auth).access_token)
            if (response.status !== 200) {
                dispatch(setSnackBar({
                    status: true, message: JSON.parse(response.data).message, alert: "error"
                }));
            } else {
                dispatch(loadClientTags())
            }

        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    }
}