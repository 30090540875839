import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import {Paper} from "@material-ui/core";
import SelectModuleContainer from "./selectModuleContainer";
import AddFacebookPages from "./addFacebookPages";


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        margin: theme.spacing(10, 2),
        padding: theme.spacing(6),
        // width: "100%",
        // height: theme.spacing(68),
        alignItems: "bottom",
        // '& > *': {
        //     margin: theme.spacing(10,2),
        //     // width: theme.spacing(180),
        //     // height: theme.spacing(80),
        //     padding: theme.spacing(6),
        //
        // },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(10, 2),
            padding: theme.spacing(0)
        }

        // position: 'relative',
    },
    mainContainer: {
        // backgroundColor:'#eeeeee',
        height: theme.spacing(100),
    },
    childPaper: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: "center",
        height: theme.spacing(60),
        padding: theme.spacing(2),
    }
}));


// const steps = ['SelectModuleContainer'];
//
// function getStepContent(step) {
//     switch (step) {
//         case 0:
//             return <SelectModuleContainer />;
//         // case 1:
//         //     return <PaymentForm />;
//         // case 2:
//         //     return <Review />;
//         default:
//             throw new Error('Unknown step');
//     }
// }

export default function MainModuleContainer(props) {

    const classes = useStyles();
    const [activeContent, setActiveContent] = React.useState("SelectModuleContainer");


    return (
        <Container maxWidth="lg" className={classes.mainContainer}>
            <CssBaseline/>
                {activeContent === "SelectModuleContainer" && <SelectModuleContainer setActiveContent={setActiveContent}/>}
                {activeContent === "AddFacebookPages" && <AddFacebookPages />}
        </Container>
    );
}

// export const MultipleFormRegistration = (Data) => {
//     const FormType = RegisterForm[Data.FormName];
//     return <FormType method={Data.method} changeEmail={Data.changeEmail} email={Data.email} setOpen={Data.setOpen}
//                      setDisplayBar={Data.setDisplayBar}/>;
// };
