import {GoogleLogin} from "react-google-login";
import {Button, makeStyles} from "@material-ui/core";
import {AiOutlineGoogle} from "react-icons/ai";
import config from "../../config"
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setUserAuth} from "../../redux/actions/userAccount";
import {postAPI} from "../../config/api/config";
import {fetchProfileDetail} from "../../helper/profile_detail";
import {fetchInitialData} from "../../helper/fetchInitialData";
import {setSnackBar} from "../../redux/actions/snackbar";

function GoogleButton(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const useStyles = makeStyles((theme) => ({
        root: {
            // backgroundColor: "#",
            color: "#000",
            fontSize: 15,
            padding: theme.spacing(2, props.paddingLeft),
            "&:hover": {
                backgroundColor: "#393e46",
                color: "#FFF",
                boxShadow: "none",
            },
        },
    }));
    const classes = useStyles();

    const onSuccess = async (googleUser) => {
        await authorizeGoogle({}, googleUser.wc.access_token);
    };

    const onFailure = (error) => {
        console.log('error', error);
    }

    async function authorizeGoogle(data, config) {
        try {
            let response = await postAPI("/api/user/auth/google", data, config);
            const user_auth = JSON.parse(response.data)
            if (response.status === 200) {
                dispatch(setUserAuth(user_auth));
                localStorage.setItem("isLogged", 'true');
                localStorage.setItem("user_auth", JSON.stringify(user_auth));
                localStorage.setItem('profile_status', user_auth.profile_status);

                if (user_auth.profile_status) {
                    dispatch(setUserAuth(user_auth));
                    await fetchProfileDetail(user_auth.id, user_auth.access_token)
                    let pgCheck = await fetchInitialData(dispatch);
                    navigate(pgCheck ? "/dashboard" : "/modules");
                } else {
                    navigate("/profile");
                }

                dispatch(setSnackBar({status: true, message: "Logged in successfully", alert: "success"}))
            } else {
                dispatch(setSnackBar({status: true, message: user_auth.message, alert: "error"}))
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: 'Failed to login through Google' + e.message, alert: "error"}))
        }
    }

    return (
        <GoogleLogin
            clientId={config.Google_CLIENT_ID}
            render={renderProps => (
                <Button
                    variant="outlined"
                    className={classes.root}
                    size="large"
                    fullWidth={true}
                    onClick={renderProps.onClick}
                    startIcon={<AiOutlineGoogle/>}
                >
                    Google
                </Button>
            )}
            onSuccess={onSuccess}
            onFailure={onFailure}
        />
    );
}

export default GoogleButton;
