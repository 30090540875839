export const ActionTypes = {
    SET_USER_AUTH_DATA: "SET_USER_AUTH_DATA",
    SET_USER_PROFILE_DATA: "SET_USER_PROFILE_DATA",
    SET_ADDRESS: "SET_ADDRESS",

    SET_ADD_PAGE_DATA: "SET_ADD_PAGE_DATA",
    UPDATE_PAGE_DATA: "UPDATE_PAGE_DATA",


    // Contacts
    STORE_PAGE_CONTACTS_DATA: "STORE_PAGE_CONTACTS_DATA",
    SET_ACTIVE_PAGE_CONTACT: "SET_ACTIVE_PAGE_CONTACT",
    ACTIVE_PROFILE_MODEL: "ACTIVE_PROFILE_MODEL",
    ACTIVE_FILTER_COMPONENT: "ACTIVE_FILTER_COMPONENT",
    ADD_NEW_TAG: "ADD_NEW_TAG",
    FETCH_CLIENT_TAGS: "FETCH_CLIENT_TAGS",
    ASSIGN_TAG_CUSTOMER: "ASSIGN_TAG_CUSTOMER",
    REMOVE_TAG_CUSTOMER: "REMOVE_TAG_CUSTOMER",
    SEARCH_CONTACT_BY_TEXT: "SEARCH_CONTACT_BY_TEXT",
    FILTER_SELECTED_TAGS: "FILTER_SELECTED_TAGS",
    FILTER_UPDATE_SELECTED_TAGS: "FILTER_UPDATE_SELECTED_TAGS",
    DELETE_TAG: "DELETE_TAG",

    SET_SNACKBAR: "SET_SNACKBAR",

    // Automation PlayGround
    CREATE_NEW_AUTOMATION_FLOW: "CREATE_NEW_AUTOMATION_FLOW",
    UPDATE_CURRENT_AUTOMATION_FLOW: "UPDATE_CURRENT_AUTOMATION_FLOW",
    FETCH_AUTOMATION_FLOW: "FETCH_AUTOMATION_FLOW",
    ACTIVE_AUTOMATION_FLOW_ID: "ACTIVE_AUTOMATION_FLOW_ID",
    OPEN_CONTENT_CONTROLLER: "OPEN_CONTENT_CONTROLLER",
    STORE_FLOW_DIAGRAM: "STORE_FLOW_DIAGRAM",
    ACTIVE_FLOW_CONTAINER_PATH: "ACTIVE_FLOW_CONTAINER_PATH",
    ACTIVE_FLOW_COMPONENT_PATH: "ACTIVE_FLOW_COMPONENT_PATH",
    ACTIVE_FLOW_SUB_COMPONENT_PATH: "ACTIVE_FLOW_SUB_COMPONENT_PATH",
    STORE_FLOW_PLAIN_DATA: "STORE_FLOW_PLAIN_DATA",
    CREATE_FLOW_PLAIN_DATA: "CREATE_FLOW_PLAIN_DATA",
    UPDATE_FLOW_PLAIN_DATA: "UPDATE_FLOW_PLAIN_DATA",
    ADD_FLOW_COMPONENT_PLAIN_DATA: "ADD_FLOW_COMPONENT_PLAIN_DATA",
    UPDATE_INNER_CONTENT_DATA: "UPDATE_INNER_CONTENT_DATA",
    UPDATE_GALLERY_CONTENT_DATA: "UPDATE_GALLERY_CONTENT_DATA",
    UPDATE_FLOW_CONTAINER_DATA: "UPDATE_FLOW_CONTAINER_DATA",
    UPDATE_FLOW_NAME: "UPDATE_FLOW_NAME",
    ADD_FLOW_COMPONENT_QUICK_REPLY_PLAIN_DATA: "ADD_FLOW_COMPONENT_QUICK_REPLY_PLAIN_DATA",
    ACTIVE_FLOW_GALLERY_COMPONENT_PATH: "ACTIVE_FLOW_GALLERY_COMPONENT_PATH",
    ACTIVE_HANDLE_COMPONENT: "ACTIVE_HANDLE_COMPONENT",
    CLEAR_AUTOMATION_HISTORY: "CLEAR_AUTOMATION_HISTORY",

    SET_TEXT_COUNTER: "SET_TEXT_COUNTER",

    //Messenger Events
    CREATE_MESSENGER_EVENT: "CREATE_MESSENGER_EVENT",


    //Automation Flow List
    FETCH_AUTOMATION_FLOW_LIST: "FETCH_AUTOMATION_FLOW_LIST",
    UPDATE_FLOW_STATUS: "UPDATE_FLOW_STATUS",


    //promotion
    FETCH_PROMOTION_LIST: "FETCH_PROMOTION_LIST",
    CREATE_PROMOTION_LIST: "CREATE_PROMOTION_LIST",
    UPDATE_PROMOTION_LIST: "UPDATE_PROMOTION_LIST",
    SET_ACTIVE_PROMOTION_ID: "SET_ACTIVE_PROMOTION_ID",

    //MessageConfiguration
    FETCH_WELCOME_MESSAGE: "FETCH_WELCOME_MESSAGE",
    FETCH_PERSISTENT_MENU: "FETCH_PERSISTENT_MENU",
    FETCH_GREETING: "FETCH_GREETING",
    FETCH_ICEBREAKER: "FETCH_ICEBREAKER",
    FETCH_PROMOTION: "FETCH_PROMOTION",
    SET_ACTIVE_PAGE_CONFIG:"SET_ACTIVE_PAGE_CONFIG",
    UPDATE_MESSENGER_CONFIGURATION:"UPDATE_MESSENGER_CONFIGURATION",
    UPDATE_TEMP_CONFIGURATION:"UPDATE_TEMP_CONFIGURATION",

    //Account Setting
    MANAGE_ACCOUNT_SETTING: "MANAGE_ACCOUNT_SETTING",
};
