import {Avatar} from "@material-ui/core";
import React from "react";
import {containerHeader, msgReceivedBubble1, msgReceivedBubble2, msgSentContainer} from "./flowCoreStyle";
import PhoneIcon from '@material-ui/icons/Phone';


export const MessageSentContainer = (text) => {
    return (
        <>
            <div style={msgSentContainer}>
                {text}
            </div>
        </>
    )
}

export const MessageReceivedBubble1 = (text) => {
    return (
        <>
            <div style={msgReceivedBubble1}>
                {text}
            </div>
        </>
    )
}

export const MessageReceivedBubble2 = (text) => {
    return (
        <>
            <div style={msgReceivedBubble2}>
                {text}
            </div>
        </>
    )
}

export const MessengerLogoName = () => {
    return (
        <div style={containerHeader}>
            <Avatar draggable={'false'} varient="square"
                    src="https://upload.wikimedia.org/wikipedia/commons/1/12/Facebook_Messenger_logo_2013.svg"/>Messenger
        </div>
    )
}


export const PhoneLogoName = () => {
    return (
        <div style={containerHeader}>
            <PhoneIcon/> Contact Number
        </div>
    )
}


export const WebsiteLogoName = () => {
    return (
        <div style={containerHeader}>
            <PhoneIcon/> Website Link
        </div>
    )
}
