import React, {useEffect, useRef, useState} from "react";
import {Avatar, Button, InputLabel, MobileStepper, Select, TextareaAutosize, TextField} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {
    activeFlowComponentPath,
    activeFlowContainerPath,
    activeFlowGalleryComponentPath,
    activeFlowSubComponentPath, activeHandleComponent,
    addFlowComponentPlainData,
    addFlowPlainData,
    contentControllerOpen,
    createMessengerEvent,
    setFlowPlainData,
    updateGalleryContentData,
    updateInnerContentData
} from "../../../../redux/actions/automation";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import clsx from 'clsx';
import DragIndicatorRoundedIcon from '@material-ui/icons/DragIndicatorRounded';
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {uploadFile} from "../../../../helper/uploadFile";
import {setSnackBar} from "../../../../redux/actions/snackbar";
import config from '../../../../config';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import {addEdge, Handle} from 'react-flow-renderer';
import ImageSearchRoundedIcon from '@material-ui/icons/ImageSearchRounded';
import IconButton from "@material-ui/core/IconButton";
import InsertLinkRoundedIcon from '@material-ui/icons/InsertLinkRounded';
import QueueRoundedIcon from '@material-ui/icons/QueueRounded';
import SwipeableViews from 'react-swipeable-views';
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import logo from "../../../../reuseableComponents/logo";
import useStyles from "../flowStyles";

export default function MessageGenericTemplate(props) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0)
    const activeComponentId = useSelector(state => state.automation.activeFlowComponentPath)
    const activeSubComponentId = useSelector(state => state.automation.activeFlowSubComponentPath)
    const activeGalleryComponentId = useSelector(state => state.automation.activeFlowGalleryComponentPath)
    const activeContainerPath = useSelector(state => state.automation.activeFlowContainerPath)

    const initialData = useSelector(state => {
        const flowPlainData = state.automation?.flowPlainData || [];
        const inner_content = flowPlainData[props?.containerPath]?.inner_content || [];
        return inner_content[props?.componentPath]?.data || {}
    })

    const contentObject = useSelector(state => {
        const flowPlainData = state.automation?.flowPlainData || [];
        const inner_content = flowPlainData[props?.containerPath]?.inner_content || [];
        return inner_content[props?.componentPath]
    })

    const clickComponentOnContainer = () => {
        dispatch(activeFlowComponentPath(props.componentPath))
    }

    const clickSubComponentOnContainer = (value) => {
        dispatch(activeFlowSubComponentPath(value))
    }

    const clickGalleryComponentOnContainer = (value) => {
        dispatch(activeHandleComponent('gallery'))
        dispatch(activeFlowGalleryComponentPath(value))
        dispatch(activeFlowComponentPath(props.componentPath))
        dispatch(activeFlowContainerPath(props.containerPath))
    }

    const customHandleGallery = (index, subIndex) => {
        return (props.containerPath).toString() + "_" + (props.componentPath).toString() + "_" + index.toString() + "_" + subIndex.toString();
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    async function galleryContentUpdate(e, i) {
        try {
            let response = await uploadFile(e.target.files[0])
            if (response.status === 200) {
                const res_data = JSON.parse(response.data)
                initialData[i].media = res_data.path
                dispatch(updateGalleryContentData(props.containerPath, props.componentPath, i, initialData[i]))
                props.isContentLoaded ? props.setLoadedContent(false) : props.setLoadedContent(true)
            } else {
                console.log(response)

            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: 'Failed to upload. Retry it.', alert: "error"}))
        }
    }

    const maxSteps = initialData.length;
    return (
        <>
            {
                props.flowAsset ?
                    <div className={clsx(classes.multiMessageGenericTemplateMain, classes.flowComponent)} onClick={()=>clickComponentOnContainer()}>
                        {initialData.map((value, i) => {
                            return (
                                <div
                                    className={clsx(classes.messageGenericTemplateMain, 'item', i === 0 ? 'active' : '')}
                                    key={i}
                                    onClick={() => clickSubComponentOnContainer(i)}>

                                    <div onClick={() => clickGalleryComponentOnContainer(0)}>
                                        {initialData && initialData[i].media ?
                                            <img alt={"reply-message"} style={{width: '100%', height: '100%'}}
                                                 className={classes.mGTMedia}
                                                 src={config.BASE_URL + '/' + initialData[i].media}></img> :
                                            <img alt={"reply-message"} style={{width: '100%', height: '100%'}}
                                                 className={classes.mGTMedia}
                                                 src={'https://picsum.photos/234/138'}></img>
                                        }
                                    </div>
                                    <div className={classes.mGTHeader}
                                         onClick={() => clickGalleryComponentOnContainer(1)}>
                                        {value.header}
                                    </div>
                                    <div
                                        className={clsx(classes.mGTSubText, value.button.length === 0 ? classes.mGTSubTextEnd : '')}
                                        onClick={() => clickGalleryComponentOnContainer(2)}>
                                        {value.subText}
                                    </div>

                                    {value.button.length > 0 &&
                                        <div className={classes.mGTNormalButton}
                                             onClick={() => clickGalleryComponentOnContainer(3)}>
                                            {value.button[0].button_name}
                                            <Handle
                                                type="source"
                                                id={customHandleGallery(i, 0)}
                                                position="right"
                                                className={classes.handlerOnBtn}
                                                isValidConnection={() => {
                                                    clickGalleryComponentOnContainer(0)
                                                    return true
                                                }}
                                                style={{background: '#fffFFFFFF'}}
                                            />
                                        </div>}
                                    {value.button.length > 1 &&
                                        <div className={classes.mGTNormalButton}
                                             onClick={() => clickGalleryComponentOnContainer(4)}>
                                            {value.button[1].button_name}
                                            <Handle
                                                type="source"
                                                id={customHandleGallery(i, 1)}
                                                position="right"
                                                className={classes.handlerOnBtn}
                                                isValidConnection={() => {
                                                    clickGalleryComponentOnContainer(1)
                                                    return true
                                                }}
                                                style={{background: '#fffFFFFFF'}}
                                            />
                                        </div>}
                                    {value.button.length > 2 &&
                                        <div className={classes.mGTEndButton}
                                             onClick={() => clickGalleryComponentOnContainer(5)}>
                                            {value.button[2].button_name}
                                            <Handle
                                                type="source"
                                                id={customHandleGallery(i, 2)}
                                                position="right"
                                                className={classes.handlerOnBtn}
                                                isValidConnection={() => {
                                                    clickGalleryComponentOnContainer(2)
                                                    return true
                                                }}
                                                style={{background: '#fffFFFFFF'}}
                                            />
                                        </div>}
                                </div>
                            )
                        })
                        }
                    </div>
                    :

                    <div className={clsx(classes.msgEditableContainer)}  onClick={()=>clickComponentOnContainer()}>
                        <div style={{display: 'flex', flexDirection: 'column', gridGap: '20px', maxWidth: '247px'}}>
                            <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                            index={activeStep}
                                            onChangeIndex={handleStepChange}
                                            enableMouseEvents>
                                {initialData.map((value, i) => {
                                    const maxSteps = initialData.length;
                                    return (
                                        <div key={i} className={classes.messageGenericTemplateMain}>
                                            <div className={classes.mGTMedia}
                                                 onClick={() => clickSubComponentOnContainer()}>
                                                {initialData[i].media ?
                                                    <img alt={"reply-message"}
                                                         style={{width: '100%', height: '100%'}}
                                                         src={config.BASE_URL + '/' + initialData[i].media}></img> :
                                                    <img alt={"reply-message"}
                                                         style={{width: '100%', height: '100%'}}
                                                         src={'https://picsum.photos/234/138'}></img>
                                                }
                                                <div className={classes.imageButtonUrl}>
                                                    <input accept="image/*" hidden id="icon-button-file"
                                                           onChange={(e) => galleryContentUpdate(e, i)}
                                                           type="file"/>
                                                    <label htmlFor="icon-button-file">
                                                        <IconButton color="primary" aria-label="upload picture"
                                                                    component="span">
                                                            <ImageSearchRoundedIcon/>
                                                        </IconButton>
                                                    </label>
                                                    |
                                                    <label htmlFor="icon-button-link">
                                                        <IconButton color="primary" aria-label="upload picture"
                                                                    component="span">
                                                            <InsertLinkRoundedIcon/>
                                                        </IconButton>
                                                    </label>
                                                </div>
                                            </div>

                                            {/* HEADER*/}
                                            <div>
                                                <TextareaAutosize
                                                    className={clsx(classes.mGTHeader, classes.msgTextArea, (activeSubComponentId === 0 && activeComponentId === props.componentPath) ? classes.msgActive : classes.msgRoot)}
                                                    style={{width: '89%'}}
                                                    placeholder="Enter Header"
                                                    onClick={() => clickSubComponentOnContainer()}
                                                    onChange={(e) => {
                                                        let data = {
                                                            'type': 'header',
                                                            'header': e.target.value
                                                        }
                                                        initialData[i].header = e.target.value
                                                        // dispatch(updateGalleryContentData(props.containerPath, props.componentPath, i, initialData[i]))
                                                        dispatch(updateGalleryContentData(props.containerPath, props.componentPath, i, data))
                                                        props.isContentLoaded ? props.setLoadedContent(false) : props.setLoadedContent(true)
                                                    }}
                                                />
                                            </div>

                                            {/* Description */}
                                            <div>
                                                <TextareaAutosize
                                                    className={clsx(classes.mGTSubText, classes.msgTextArea, (activeSubComponentId === 1 && activeComponentId === props.componentPath) ? classes.msgActive : classes.msgRoot)}
                                                    style={{width: '89%'}}
                                                    placeholder="Enter Your Text Here"
                                                    onClick={() => clickSubComponentOnContainer()}
                                                    onChange={(e) => {
                                                        initialData[i].subText = e.target.value
                                                        dispatch(updateGalleryContentData(props.containerPath, props.componentPath, i, initialData[i]))
                                                        props.isContentLoaded ? props.setLoadedContent(false) : props.setLoadedContent(true)
                                                    }}
                                                />
                                            </div>

                                            {/* BUTTONS */}
                                            {value.button_count > 0 &&
                                                <div>
                                                    <TextareaAutosize
                                                        className={
                                                            clsx(value.button_count === 1 ? classes.mGTEndButton : classes.mGTNormalButton,
                                                                classes.msgTextArea,
                                                                activeSubComponentId === 1 ? classes.msgActive : classes.msgRoot)}
                                                        style={{width: '89%'}}
                                                        placeholder="Button 1"
                                                        defaultValue={initialData[i].button[0].button_name}
                                                        onClick={() => clickSubComponentOnContainer()}
                                                        onChange={(e) => {
                                                            initialData[i].button[0].button_name = e.target.value
                                                            dispatch(updateGalleryContentData(props.containerPath, props.componentPath, i, initialData[i]))
                                                            props.isContentLoaded ? props.setLoadedContent(false) : props.setLoadedContent(true)
                                                        }}
                                                    />
                                                </div>}


                                            {value.button_count > 1 &&
                                                <div>
                                                    <TextareaAutosize
                                                        className={
                                                            clsx(value.button_count === 2 ? classes.mGTEndButton : classes.mGTNormalButton,
                                                                classes.msgTextArea,
                                                                activeSubComponentId === 1 ? classes.msgActive : classes.msgRoot)}
                                                        style={{width: '89%'}}
                                                        placeholder="Button 1"
                                                        defaultValue={initialData[i].button[1].button_name}
                                                        onClick={() => clickSubComponentOnContainer()}
                                                        onChange={(e) => {
                                                            initialData[i].button[1].button_name = e.target.value
                                                            dispatch(updateGalleryContentData(props.containerPath, props.componentPath, i, initialData[i]))
                                                            props.isContentLoaded ? props.setLoadedContent(false) : props.setLoadedContent(true)
                                                        }}
                                                    />
                                                </div>}

                                            {value.button_count > 2 &&
                                                <div>
                                                    <TextareaAutosize
                                                        className={
                                                            clsx(value.button_count === 3 ? classes.mGTEndButton : classes.mGTNormalButton,
                                                                classes.msgTextArea,
                                                                activeSubComponentId === 1 ? classes.msgActive : classes.msgRoot)}
                                                        style={{width: '89%'}}
                                                        placeholder="Button 1"
                                                        defaultValue={initialData[i].button[2].button_name}
                                                        onClick={() => clickSubComponentOnContainer()}
                                                        onChange={(e) => {
                                                            initialData[i].button[2].button_name = e.target.value
                                                            dispatch(updateGalleryContentData(props.containerPath, props.componentPath, i, initialData[i]))
                                                            props.isContentLoaded ? props.setLoadedContent(false) : props.setLoadedContent(true)
                                                        }}
                                                    />
                                                </div>}
                                        </div>
                                    )
                                })}
                            </SwipeableViews>

                            <MobileStepper
                                steps={maxSteps}
                                position="static"
                                variant="text"
                                activeStep={activeStep}
                                nextButton={
                                    <Button size="small" onClick={handleNext}
                                            disabled={activeStep === maxSteps - 1}>
                                        Next
                                        {theme.direction === 'rtl' ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                        {theme.direction === 'rtl' ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
                                        Back
                                    </Button>
                                }
                            />
                        </div>

                        <div className={clsx(classes.msgAction, 'myhandler', classes.extraConfig)}>
                            <DragIndicatorRoundedIcon className={'myhandler'}/>
                            <QueueRoundedIcon onClick={() => {
                                let genericTemplate = {
                                    type: 'template',
                                    media: '',
                                    header: 'Enter Header',
                                    subText: 'Enter description',
                                    button_count: 0,
                                    button: []
                                }
                                dispatch(updateInnerContentData(props.containerPath, props.componentPath, genericTemplate))
                                props.isContentLoaded ? props.setLoadedContent(false) : props.setLoadedContent(true)
                            }
                            }/>

                            {contentObject.data[activeStep].button_count < 3 && <AddBoxRoundedIcon onClick={() => {
                                contentObject.data[activeStep].button_count += 1
                                const new_button = {
                                    type: 'button_name',
                                    button_name: 'Button' + ' ' + contentObject.data[activeStep].button_count,
                                    button_type: '', // phone number, web link, post back
                                    button_value: ''
                                }
                                dispatch(updateGalleryContentData(props.containerPath, props.componentPath, activeStep, new_button))
                                props.isContentLoaded ? props.setLoadedContent(false) : props.setLoadedContent(true)
                            }
                            }/>}

                        </div>
                        <div className={clsx(classes.msgAction)}><CloseRoundedIcon/></div>
                    </div>
            }
        </>

    )
}