import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: theme.text.primary.fontSize,
        // margin: theme.layout.margin,
        // color:theme.text.primaryText.color,
        position: 'absolute'
    },
    container: {
        // display: 'flex',
        // gridGap: '10px'
    },
    flowBuilderContainer: {
        height: '100%',
        width: '100%',
        position: 'relative',
        // boxShadow: 'rgb(9 30 66 / 25%) 0px 1px 1px, rgb(9 30 66 / 13%) 0px 0px 1px 1px'
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: "absolute",
        right: '0',
        top: '0',
        bottom: '0',
        zIndex: '9',
        backgroundColor: '#FFF',
        paddingLeft: '25px',
        borderRadius: '10px 0 0 10px',
        boxShadow: '0px 10px 20px rgb(0, 0, 0, 0.32)',
    },
    topBar: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    bodyTemplate: {
        width: '100%',
        height: '85%',
        justifyContent: 'center',
        overflowY: 'scroll',
        margin: '0 auto'
    },
    'bodyTemplate::-webkit-scrollbar': {
        width: '1em'
    },
    'bodyTemplate::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)'
    },
    'bodyTemplate::-webkit-scrollbar-thumb': {
        backgroundColor: 'darkgrey',
        outline: '1px solid slategrey'
    },
    messageContentType: {
        display: 'flex',
        gridGap: '10px',
        padding: '10px',
        flexWrap: 'wrap',
        justifyContent: 'center',
        overflow: 'hidden'
    },
    directionContent: {
        padding: '0'
    },
    directionIcon: {
        '&:hover': {
            width: '36px',
            height: '36px',
        }
    },
    actionType: {
        width: '70%',
        margin: '0px 12px'
    },
    selectEmpty: {
        width: '100%',
    },
    actionItem: {
        width: '38px',
        height: '38px',
        border: `solid 1px ${theme.palette.background.subroot}`,
        borderRadius: '12px',
        padding: '1px',
        '&:hover': {
            width: '38px',
            height: '38px',
            boxShadow: 'rgb(38, 57, 77) 0px 16px 15px -10px',
        }
    },
    sortableContainer: {
        margin: '25px',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    contentContainerIcon: {
        width: '32px',
        height: '32px',
        padding: '4px',
        color: theme.palette.primary.main
    },
    handler: {},
    draggableContainer: {
        width: '200px !important',
        height: '110px',
        position: 'absolute',
        bottom: '87px',
        zIndex: 9
    },
    DraggableItemsList: {
        display: 'flex',
        gridGap: '10px'
    },

    // messenger component css
    msgRoot: {
        '&:hover': {
            border: `solid 1px ${theme.palette.primary.main} !important`,
        }
    },
    flowComponent: {margin: '10px 0px'},
    msgReceivedContainer: {
        width: '218px',
        padding: '12px 8px',
        margin: '0px 28px',
        fontFamily: 'SF UI Text, Regular',
        fontSize: '17px',
        textAlign: 'left',
        backgroundColor: '#F1F0F0',
        borderRadius: '18px',
        minHeight: '20px',
        border: '1px solid #fff !important',
    },
    msgActive: {
        border: `solid 1px ${theme.palette.primary.main} !important`
    },
    msgTextArea: {
        resize: 'none',
        border: 'none',
        overflow: 'auto',
        outline: 'none'
    },
    msgEditableContainer: {
        display: 'flex',
        margin: '30px'
    },
    msgAction: {
        marginTop: '20px',
    },
    msgReceivedImageContainer: {
        width: '218px',
        height: 'fit-content',
        borderRadius: '18px',
    },
    msgImg: {
        borderRadius: '18px',
        width: '234px',
        height: '138px',
        overflow: 'hidden',
        position: 'relative',
        margin: '0 auto 0 0',
    },
    imageButtonUrl: {
        top: '38%',
        left: '29%',
        position: 'absolute',
        backgroundColor: '#fffbfb91',
        borderRadius: '25px',
    },
    msgImgEditableComponent: {
        width: '218px',
        height: '200px',
        borderRadius: '18px',
    },
    imageContainer: {},
    messageTextButtonMain: {
        width: '247px',
        fontFamily: 'SF UI Text, Regular',
        fontSize: '17px',
        textAlign: 'left',
        // margin: '0 auto 0 0',
    },
    mtbText: {
        borderRadius: '18px 18px 0px 0px',
        backgroundColor: '#F1F0F0',
        padding: '12px 8px',
    },
    mtbNormalButton: {
        height: 'fit-content',
        borderRadius: '0px 0px 0px 0px',
        padding: '12px 8px',
        backgroundColor: '#0000',
        textAlign: 'center',
        color: '#0084FF',
        bottom: '0',
        border: 'solid 1px #F1F0F0',
        position: 'relative'
    },
    mtbEndButton: {
        borderRadius: '0px 0px 18px 18px',
        padding: '12px 8px',
        backgroundColor: '#0000',
        textAlign: 'center',
        color: '#0084FF',
        bottom: '0',
        border: 'solid 1px #F1F0F0',
        position: 'relative'
    },
    extraConfig: {
        display: 'flex',
        flexDirection: 'column'
    },
    messageGenericTemplateMain: {
        minWidth: '247px',
        margin: '0 auto 0 0',
    },
    multiMessageGenericTemplateMain: {
        display: 'flex',
        gridGap: '20px',
    },
    mGTMedia: {
        width: '100%',
        height: '139px',
        borderRadius: '18px 18px 0px 0px',
        backgroundImage: "url('https://picsum.photos/200/300')",
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
        overflow: 'hidden'
    },
    mGTMediaButtonUrl: {
        top: '38%',
        left: '29%',
        position: 'absolute',
        backgroundColor: '#fffbfb91',
        borderRadius: '25px'
    },
    mGTTextMain: {
        width: '100%',
        height: 'fit-content',
    },
    mGTHeader: {
        width: '100%',
        fontSize: '14px',
        fontFamily: 'SF UI Text, Medium',
        padding: '12px 8px 0px 8px',
        textAlign: 'left',
        color: '#000000'
    },
    mGTSubText: {
        width: '100%',
        fontSize: '13px',
        fontFamily: 'SF UI Text, Regular',
        padding: '0px 8px 0px 8px',
        textAlign: 'left',
        color: '#666666'
    },
    mGTSubTextEnd: {
        borderRadius: '18px',
    },
    mGTNormalButton: {
        borderRadius: '0px 0px 0px 0px',
        padding: '12px 8px',
        backgroundColor: '#0000',
        textAlign: 'center',
        color: '#0084FF',
        bottom: '0',
        border: 'solid 1px #F1F0F0',
        fontFamily: 'SF UI Text, Regular',
        fontSize: '17px',
        position: 'relative'
    },
    mGTEndButton: {
        borderRadius: '0px 0px 18px 18px',
        padding: '12px 8px',
        backgroundColor: '#0000',
        textAlign: 'center',
        color: '#0084FF',
        bottom: '0',
        border: 'solid 1px #F1F0F0',
        fontFamily: 'SF UI Text, Regular',
        fontSize: '17px',
        position: 'relative'
    },
    templateContent: {
        backgroundImage: '',
        width: '266px',
        height: '201px',
    },
    messengerHeader: {
        backgroundImage: '',
        height: '52px',
        display: 'flex',
        gridGap: '10px',
        fontSize: '26px',
        fontWeight: 'bold',
        fontFamily: 'SF UI Text, Bold',
        marginBottom: '2px',
        padding: '4px 81px'
    },
    handlerOnBtn: {
        margin: '4px 13px'
    },
    InputLink: {
        width: '78%',
        height: '21px',
        overflow: 'hidden',
        backgroundColor: 'rgb(238 238 243)',
        margin: '0px auto',
        padding: '12px 8px',
        fontFamily: 'SF UI Text, Regular',
        fontSize: '17px',
        textAlign: 'left',
        borderRadius: '18px',
        minHeight: '20px',
        border: '1px solid #fff !important',
    }, selectQuickReply: {
        borderRadius: '18px 0px 0px 18px',
        borderLeft: 'solid 1px',
        borderTop: 'solid 1px ',
        borderBottom: 'solid 1px',
        borderRight: 'solid 0px',
        padding: '8px',
        backgroundColor: theme.palette.primary.main,
        "&:focus": {
            'outline': 'none'
        }
    },
    inputQuick: {
        borderRadius: '0px 18px 18px 0px',
        borderLeft: '0',
        borderTop: 'solid 1px ',
        borderBottom: 'solid 1px',
        borderRight: 'solid 1px',
        "&:hover": {
            'border': 'solid 1px'
        },
        "&:focus": {
            'outline': 'none'
        }
    },
    msgQuickContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '30px',
        gridGap: '10px'
    },
    quickReply: {
        borderRadius: '18px 18px 18px 18px',
        border: 'solid 1px',
        "&:hover": {
            'border': 'solid 1px'
        },
        "&:focus": {
            'outline': 'none'
        }
    }, handlerQuickReply: {
        margin: '2px 39px',
        position: "absolute"
    }


}))

export default useStyles
