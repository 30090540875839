import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import {Snackbar} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux"
import {setSnackBar} from "../../redux/actions/snackbar";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function CustomizedSnackbars() {
    const classes = useStyles();
    const dispatch = useDispatch()
    const snackbar = useSelector(state => state.snackbar)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(setSnackBar({status: false, message: "", alert: "null"}))
    };
    if (snackbar.status) {
        return (
            <Snackbar open={snackbar.status} autoHideDuration={3000} onClose={handleClose}>

                <Alert onClose={handleClose} severity={snackbar.alert}>
                    {snackbar.message}
                </Alert>
            </Snackbar>)

    }else{
        return null
    }
}