import {mainContainer} from "./flowCoreStyle";
import {MessageReceivedContainer} from "./messengerComponent";
import {MessengerLogoName} from "./flowContainerAssets";


export const InputObject = (main_content, i) => {
    let obj = {
        "id": main_content.flow_info.id,
        "type": "input",
        "data": {
            label: (
                <>
                    {MessengerLogoName()}
                    {main_content.inner_content.map((value, index) => {
                        return (
                            <MessageReceivedContainer key={index} msgItem={value}
                                                      componentPath={index}
                                                      containerPath={i} flowAsset={true}/>
                        )
                    })}
                </>
            ),
        },
        "sourcePosition": 'right',
        "position": {
            "x": main_content.flow_info.position.x,
            "y": main_content.flow_info.position.y,
        },
        "style": mainContainer,
        "content_type":"node",
    }
    return obj
}


export const DefaultObject = (main_content, i) => {
    let obj = {
        "id": main_content.flow_info.id,
        "type": "default",
        "data": {
            label: (
                <>
                    {MessengerLogoName()}
                    {main_content.inner_content.map((value, index) => {
                        return (
                            <MessageReceivedContainer key={index} msgItem={value}
                                                      componentPath={index}
                                                      containerPath={i} flowAsset={true}/>
                        )
                    })}
                </>
            ),
        },
        "sourcePosition": 'right',
        "targetPosition": 'left',
        "position": {
            "x": main_content.flow_info.position.x,
            "y": main_content.flow_info.position.y,
        },
        "style": mainContainer,
        "content_type":"node",
    }
    return obj
}


export const ButtonOutputObject = (main_content, i) => {
    let obj = {
        "id": main_content.flow_info.id,
        "type": "buttonOutput",
        "data": {
            label: (
                <>
                    {MessengerLogoName()}
                    {main_content.inner_content.map((value, index) => {
                        return (
                            <MessageReceivedContainer key={index} msgItem={value}
                                                      componentPath={index}
                                                      containerPath={i} flowAsset={true}/>
                        )
                    })}
                </>
            ),
        },
        "sourcePosition": 'right',
        "targetPosition": 'left',
        "position": {
            "x": main_content.flow_info.position.x,
            "y": main_content.flow_info.position.y,
        },
        "style": mainContainer,
        "content_type":"node",
    }
    return obj
}


export const EdgeObject = (main_content) => {
    let obj = {
        id: main_content.flow_info.id,
        // type: main_content.flow_info.type,
        // type: 'default !important',
        type: 'buttonEdge',
        source: main_content.flow_info.source,
        target: main_content.flow_info.target,
        // label: 'label with styled bg',
        sourceHandle: main_content.flow_info.sourceHandle,
        targetHandle: main_content.flow_info.targetHandle,
        content_type:"edge",
        animated:true,
        arrowHeadType:'arrow',
        className: 'normal-edge',
    }
    return obj
}