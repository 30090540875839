import React from 'react';
import useStyles from "./flowStyles";
import CallIcon from '@material-ui/icons/Call';
import LinkIcon from '@material-ui/icons/Link';
import {Avatar} from "@material-ui/core";

export default () => {
    const classes = useStyles();
    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType)
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <aside className={classes.draggableContainer}>
            <div className="description">You can drag these nodes to the pane on the right.</div>
            <div className={classes.DraggableItemsList}>
                <div className="dndnode" onDragStart={(event) => onDragStart(event, 'default')} draggable>
                    <Avatar draggable={'false'} varient="square"
                            src="https://upload.wikimedia.org/wikipedia/commons/1/12/Facebook_Messenger_logo_2013.svg"/> Messenger
                </div>
            </div>
        </aside>
    );
};