const config = {
  BASE_URL: "https://stgapi.heapchat.com",
  FB_GRAPH_URL: "https://graph.facebook.com",
  FB_CLIENT_ID: "497590371569877",
  GOOGLE_MAP_API_KEY: "AIzaSyBMkilMblQKEFLu2hi05MQKwnmhFHImTjI",
  Google_CLIENT_ID: "797225923600-vb4bf6dj9lqgoeond39fmpp9rgl9vot5.apps.googleusercontent.com",
  PUBLIC_RECAPTCHA_SITE_KEY: "6LdvsRkbAAAAAFZQRa48YNimQHcMpHrsn6-Mny-r",
};

export default config;
