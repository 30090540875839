import React, {useEffect} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useNavigate} from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import LayersIcon from "@material-ui/icons/Layers";
import BarChartIcon from "@material-ui/icons/BarChart";
import SendIcon from "@material-ui/icons/Send";
import ListSubheader from "@material-ui/core/ListSubheader";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SettingsIcon from "@material-ui/icons/Settings";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {Link, useLocation} from "react-router-dom";
import Logo from "../reuseableComponents/logo";
import {accountSetting} from "../redux/actions/accountSetting";
import {useDispatch, useSelector} from "react-redux";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#ffffff',
        color: theme.text.primary.color,
        minHeight: '100vh',
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        height: '64px',
    },
    drawerPaper: {
        whiteSpace: 'nowrap',
        width: drawerWidth,
        [theme.breakpoints.down('xs')]: {
            width: theme.spacing(7),
            overflow: 'hidden',
        },
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.shorter,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
    },
    logout: {
        backgroundColor: "#ffffff",
        color: '#0477a8'
    },
    colorIcon: {
        color: theme.icon.action.hover,
    },
    listItem: {
        '&:hover': {
            fontWeight: 'bold !important',
            borderRight: `solid 4px ${theme.palette.primary.main}`,
            color: theme.text.primary.main,
            backgroundColor: theme.palette.background.subroot
        }
    },
    selectedListItem: {
        borderRight: `solid 4px ${theme.palette.primary.main}`,
        color: theme.text.primary.main,
        backgroundColor: theme.palette.background.subroot
    }
}));

export default function Navigation(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const location = useLocation();
    const account_setting = useSelector(state => state.accountSetting.account_setting) ?? {value: '1'};
    const [open, setOpen] = React.useState(account_setting.value === '1');

    const handleDrawer = () => {
        setOpen(!open);
        dispatch(accountSetting({value: !open, id: 'system.expandNavigation'}));
    };

    useEffect(async () => {
        dispatch(await accountSetting({id: 'system.expandNavigation', type: 'get'}));
        setOpen(account_setting.value === '1');
    }, [account_setting.id]);

    const navItems = [
        {id: 1, name: "Dashboard", icon: <DashboardIcon className={classes.colorIcon}/>, path: '/dashboard'},
        {id: 2, name: "Contacts", icon: <PeopleIcon className={classes.colorIcon}/>, path: '/contacts'},
        {id: 3, name: "Automation", icon: <LayersIcon className={classes.colorIcon}/>, path: '/automation'},
        {id: 4, name: "Live Chat", icon: <BarChartIcon className={classes.colorIcon}/>, path: '/automation'},
        {id: 5, name: "Broadcast", icon: <SendIcon className={classes.colorIcon}/>, path: '/automation'},
        {id: 6, name: "Product Inventory", icon: <SendIcon className={classes.colorIcon}/>, path: '/automation'},
        {id: 7, name: "Profile", icon: <AssignmentIcon className={classes.colorIcon}/>, path: '/userProfile'},
        {id: 8, name: "Setting", icon: <SettingsIcon className={classes.colorIcon}/>, path: '/settings'},
        {id: 9, name: "Help", icon: <HelpOutlineIcon className={classes.colorIcon}/>, path: '/automation'},
    ];

    const [selected, setSelected] = React.useState(navItems.find(item => item.path === location.pathname)?.id || 1);

    const logout = () => {
        localStorage.clear()
        navigate("/");
    }
    const selectedNavItem = (id) => {
        setSelected(id)
    }

    return (
        <nav className={clsx(classes.drawerPaper, !open && classes.drawerPaperClose)} open={open}>
            <div className={classes.root}>
                <div className={classes.toolbarIcon}>
                    <div>
                        {open && <Logo width={180} height={40}/>}
                        {!open && <img src="favicon.png" width={60} height={40} alt="Heap Chat"/>}
                    </div>

                    {open && <IconButton onClick={handleDrawer}>
                        <ChevronLeftIcon className={classes.colorIcon}/>
                    </IconButton>}
                    {!open && <IconButton className={classes.colorIcon} onClick={handleDrawer}>
                        <ChevronRightIcon/>
                    </IconButton>}
                </div>
                <Divider/>
                {/*<List>{mainListItems}</List>*/}
                <List>
                    <div>
                        {navItems.map((value, i) => {
                            return (
                                <div key={i}>
                                    {value.id === 7 && <><Divider/> <ListSubheader inset>Settings</ListSubheader></>}
                                    <ListItem
                                        className={selected === value.id ? classes.selectedListItem : classes.listItem}
                                        button component={Link} to={value.path}
                                        onClick={() => selectedNavItem(value.id)}>
                                        <ListItemIcon>
                                            {value.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={value.name}/>
                                    </ListItem>
                                </div>
                            )
                        })}
                    </div>
                </List>
                <List>
                    <ListItem className={classes.listItem} button onClick={logout}>
                        <ListItemIcon>
                            <ExitToAppIcon className={classes.colorIcon}/>
                        </ListItemIcon>
                        <ListItemText primary="Logout"/>
                    </ListItem>
                </List>
            </div>
        </nav>
    );
}
