import React, {useEffect, useState} from 'react';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {Container, Input} from "@material-ui/core";
import ContactTable from "../components/contactTable";
import PageBar from "../components/pageBar";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import "../index.css";
import FilterComponent from "../components/filterComponent";
import {
    activeFilterComponent,
    addNewTag,
    loadClientTags,
    searchContactByText
} from "../../../redux/actions/pageContact";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: theme.text.primary.fontSize,
        padding: theme.layout.margin,
        color: theme.text.primary.color,
    },
    table: {
        width: '100%',
        height: '380px'
    },
    togglePage: {
        width: '100%',
    },
    mainContainer: {
        width: '100%',
        display: 'flex',
        gridGap: '10px',
    },
    contactContainer: {
        width: '100%',
        margin: "0 auto"
    },
    toolBar: {
        display: "flex",
        width: '100%',
        gridGap: '10px'
    },
    filterContainer: {
        display: 'flex',
    },
    searchFilterContainer: {
        position: "relative",
        padding: '12px',
        alignItems: 'center'
    },
    searchFilter: {
        width: '32px', height: '32px', position: 'absolute', right: '2px'
    },
    searchInputFilter: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    listFilterContainer: {
        width: '35px',
        padding: '19px 0px',
        position: 'relative'
    },
    listFilter: {
        width: '32px', height: '32px', position: 'absolute', right: '0'
    },
    infoContainer: {
        width: '30%',
        height: '80vh',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
        borderRadius: '30px'
    }
}))


export default function Contacts() {
    const classes = useStyles();
    const [pageIndex, setPageIndex] = useState(0);
    const dispatch = useDispatch();
    const filterComponent = useSelector(state => state.pageContact?.activeFilterComponent)
    const page_id = useSelector(state => state.pageContact?.page?.page_id)

    useEffect(() => {
        dispatch(loadClientTags())
    }, []);

    const onFilterClick = () => {
        dispatch(activeFilterComponent(true))
    }

    const searchContact = (e) => {
        dispatch(searchContactByText(page_id, e.target.value))
    }

    return (
        <>
            <Container maxWidth={false}>
                <Typography sx={{fontSize: 24}} className={classes.header} color="textSecondary">
                    Contacts
                </Typography>
                <div className={classes.mainContainer}>
                    <div className={classes.contactContainer}>
                        <div className={classes.toolBar}>
                            <div className={classes.togglePage}>
                                <PageBar setPageIndex={setPageIndex}/>
                            </div>
                            <div className={classes.filterContainer}>
                                <div className={classes.searchFilterContainer}>
                                    <form className={classes.searchInputFilter} noValidate autoComplete="off">
                                        <Input inputProps={{'aria-label': 'description'}} onChange={(e) => {
                                            searchContact(e)
                                        }}/>
                                        <SearchRoundedIcon className={classes.searchFilter}/>
                                    </form>
                                </div>
                                <div className={classes.listFilterContainer}>
                                    <FilterListRoundedIcon className={classes.listFilter} onClick={() => {
                                        onFilterClick()
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className={classes.table}>
                            {<ContactTable pageIndex={pageIndex}/>}
                        </div>
                    </div>
                    {filterComponent && <FilterComponent/>}
                </div>
            </Container>
        </>
    )
}
