import {postAPI} from "../../config/api/config";
import {setSnackBar} from "./snackbar";
import {ActionTypes} from "../contants/action-types";

export const fetchPromotionList = (page_id) => {
    return async (dispatch, getState) => {
        let promotionData;
        try {
            const userAuth_ = localStorage.getItem("user_auth")
            const response = await postAPI('/api/promotion/list', {page_id: page_id}, JSON.parse(userAuth_).access_token)
            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                promotionData = JSON.parse(response.data).data
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }

        dispatch({
            type: ActionTypes.FETCH_PROMOTION_LIST,
            payload: {
                data: promotionData
            }
        })
    };
};

//New Promotion LIst
export const savePromotionList = (values) => {
    return async (dispatch, getState) => {
        let {promotion, messengerConfig} = getState()
        let promotionData;
        try {
            const userAuth_ = localStorage.getItem("user_auth")
            const userAuth = JSON.parse(userAuth_)
            const data = {
                "keyword_list": values.keyword_list,
                "flow_id": values.flow_id,
                "page_id": messengerConfig.activePageId,
                "status": true
            }
            const response = await postAPI('/api/promotion/create', data, userAuth.access_token)
            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                promotionData = JSON.parse(response.data)
                promotion.promotionList.push(promotionData.data)
                dispatch(setSnackBar({status: true, message: "New Promotion Added Succefully", alert: "success"}))

                dispatch({
                    type: ActionTypes.CREATE_PROMOTION_LIST,
                    payload: {
                        data: promotion.promotionList,
                    }
                })
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    };
};

export const updatePromotionList = () => {
    return async (dispatch, getState) => {
        let {promotion} = getState()
        let promotionData;
        try {
            const userAuth_ = localStorage.getItem("user_auth")
            const promotion_obj = promotion.promotionList.find(obj => obj.uuid === promotion.activePromotionId)
            promotion_obj.keyword_list = promotion_obj.keyword
            const response = await postAPI('/api/promotion/update', promotion_obj, JSON.parse(userAuth_).access_token)
            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                promotionData = JSON.parse(response.data).data
                dispatch({
                    type: ActionTypes.UPDATE_PROMOTION_LIST,
                    payload: {
                        data: promotionData
                    }
                })
                dispatch(setSnackBar({status: true, message: "Promotion changed.", alert: "success"}))
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    };
};

export const updatePromotionKeyword = (keyword) => {
    return async (dispatch, getState) => {
        let {promotion} = getState()
        promotion.promotionList.find(obj => obj.uuid === promotion.activePromotionId).keyword.push(keyword)
        dispatch({
            type: ActionTypes.UPDATE_PROMOTION_LIST,
            payload: {
                data: promotion.promotionList
            }
        })
    };
};

export const updatePromotionFlowId = (flow_id) => {
    return async (dispatch, getState) => {
        let {promotion} = getState()
        promotion.promotionList.find(obj => obj.uuid === promotion.activePromotionId).flow_id = flow_id
        dispatch({
            type: ActionTypes.UPDATE_PROMOTION_LIST,
            payload: {
                data: promotion.promotionList
            }
        })
    };
};


export const setActivePromotion = (promotion_id) => {
    return {
        type: ActionTypes.SET_ACTIVE_PROMOTION_ID,
        payload: {
            data: promotion_id
        }
    }
};

export const removeKeywordPromotion = (uuid, data) => {
    return async (dispatch, getState) => {
        let {promotion} = getState()
        let promotionData;

        let remaining_key = promotion.promotionList.find(obj => obj.uuid === uuid).keyword.filter(value => value !== data)

        try {
            const userAuth_ = localStorage.getItem("user_auth")
            const promotion_obj = promotion.promotionList.find(obj => obj.uuid === uuid)
            promotion_obj.keyword_list = remaining_key
            const response = await postAPI('/api/promotion/update', promotion_obj, JSON.parse(userAuth_).access_token)
            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                promotionData = JSON.parse(response.data).data
                dispatch({
                    type: ActionTypes.UPDATE_PROMOTION_LIST,
                    payload: {
                        data: promotionData
                    }
                })
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    };
};