import {ActionTypes} from "../contants/action-types";
import {postAPI} from "../../config/api/config";

export const accountSetting = async (setting) => {
  return async (dispatch, getState) => {
    try {
      const user_auth = JSON.parse(localStorage.getItem("user_auth"));
      const data = {
        value: setting.value,
        id: setting.id,
        type: setting.type
      }
      const response = await postAPI('/api/account_settings/manage', data, user_auth.access_token)

      const account_setting = JSON.parse(response.data)
      dispatch({
        type: ActionTypes.MANAGE_ACCOUNT_SETTING,
        payload: account_setting
      })
    } catch (e) {
      console.log(e)
    }
  };
}
