import Typography from "@material-ui/core/Typography";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Avatar, Button, Container, Switch} from "@material-ui/core";
import '../index.css'
import {useDispatch, useSelector} from "react-redux";
import {DataGrid} from "@material-ui/data-grid";
import {fetchAutomationFlowList, updateFlowStatus} from "../../../redux/actions/automationFlowList";
import {useNavigate} from "react-router-dom";
import {createNewAutomationFlow, setActiveAutomationFlowId} from "../../../redux/actions/automation";
import SaveIcon from "@material-ui/icons/Save";
import {postAPI} from "../../../config/api/config";
import {setSnackBar} from "../../../redux/actions/snackbar";


const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: theme.text.secondary.fontSize,
        padding: theme.layout.margin,
        color: theme.text.primary.color,
    },
    automationWrapper: {
        height: '100%',
    }, button: {
        margin: theme.spacing(3),
    },
}))


export default function AutomationFlowList() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const flowList = useSelector(state => {
        return state.flowList.automationFlowList || []
    })

    const activeAutomationFlowUuid = useSelector(state => {
        return state.automation?.activeAutomationFlowUuid || ''
    })

    let handleChange = (e, params) => {
        dispatch(updateFlowStatus(params.row.id, e.target.checked))
    }

    const columns = [
        {field: 'id', headerName: 'ID', width: 90},
        {
            field: 'flow_name',
            headerName: 'Title',
            width: 150,
        },
        {
            field: 'createdAt',
            headerName: 'Created',
            width: 150,
        },
        {
            field: 'status',
            headerName: 'Active',
            width: 200,
            renderCell: (params) => <Switch
                checked={params.value}
                onChange={(e) => handleChange(e, params)}
                onClick={(e) => e.stopPropagation()}
                color="primary"
                name="checkedB"
                inputProps={{'aria-label': 'primary checkbox'}}
            />,
        },
    ];

    useEffect(() => {
        dispatch(fetchAutomationFlowList())
    }, [])

    const onNewFlow = async () => {
        let flowDetail;
        try {
            const userAuth_ = localStorage.getItem("user_auth")
            const data = {
                flow_name: "Untitled",
                flow: [{
                    "flow_info": {
                        "id": "1",
                        "type": "input",
                        "position": {
                            "x": 250,
                            "y": 5
                        }
                    },
                    "inner_content": []
                }],
                status: false
            }
            const response = await postAPI('/api/automation_flow_design/create', data, JSON.parse(userAuth_).access_token)

            if (response.status !== 200) {
                dispatch(setSnackBar({status: true, message: "Failed to Connect to Server", alert: "error"}))
            } else {
                flowDetail = JSON.parse(response.data)?.data
                dispatch(createNewAutomationFlow(flowDetail))
                dispatch(setSnackBar({status: true, message: "Flow successfully stored", alert: "success"}))
                navigate(`/playground/${flowDetail.uuid}`)
            }
        } catch (e) {
            dispatch(setSnackBar({status: true, message: e.message, alert: "error"}))
        }
    }

    const onRowClick = ((value) => {

        dispatch(setActiveAutomationFlowId(value.id))
        const uuid = Object.values(flowList).find(obj => obj.id === value.id).uuid
        navigate(`/playground/${uuid}`)
    })

    return (

        <Container maxWidth={false}>
            <div className={classes.automationWrapper}>
                <Typography sx={{fontSize: 18}} className={classes.header} color="textSecondary">
                    Flow List
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        startIcon={<SaveIcon/>}
                        onClick={() => onNewFlow()}
                    >
                        New Flow
                    </Button>
                </Typography>
                <div style={{height: 400, width: '100%'}}>
                    <DataGrid
                        rows={flowList}
                        columns={columns}
                        pageSize={5}
                        checkboxSelection
                        onRowClick={(value) => onRowClick(value)}
                    />
                </div>
            </div>
        </Container>
    )
}
