import {ActionTypes} from "../contants/action-types";

const initialState = {
    activeAutomationFlowId: 1,
    activeAutomationFlowUuid: "",
    contentContainerOpen: false,
    flow_name: 'Untitled',
    status: false,
    flowPlainData: [],
    flowDiagram: [],
    messengerEvent: [],
    activeFlowContainerPath: 0,
    activeFlowComponentPath: 0, //prev 1
    activeFlowSubComponentPath: 0,
    activeFlowGalleryComponentPath: 0,
    activeHandleComponent: '',
};
export const setAutomation = (state = initialState, {type, automationDetail, payload}) => {
    switch (type) {

        case ActionTypes.CREATE_NEW_AUTOMATION_FLOW:
            return {
                ...state,
                activeAutomationFlowId: payload.activeAutomationFlowId,
                activeAutomationFlowUuid: payload.activeAutomationFlowUuid,
                flow_name: payload.flow_name,
                flowPlainData: payload.flowPlainData,
                status: payload.status
            };

        case ActionTypes.UPDATE_CURRENT_AUTOMATION_FLOW:
            return {
                ...state,
                activeAutomationFlowId: payload.activeAutomationFlowId,
                activeAutomationFlowUuid: payload.activeAutomationFlowUuid,
                flow_name: payload.flow_name,
                flowPlainData: payload.flowPlainData,
                status: payload.status
            };

        case ActionTypes.FETCH_AUTOMATION_FLOW:
            return {
                ...state,
                activeAutomationFlowId: payload.activeAutomationFlowId,
                activeAutomationFlowUuid: payload.activeAutomationFlowUuid,
                flow_name: payload.flow_name,
                flowPlainData: payload.flowPlainData,
                status: payload.status
            };

        case ActionTypes.ACTIVE_AUTOMATION_FLOW_ID:
            return {...state, activeAutomationFlowId: payload};

        case ActionTypes.OPEN_CONTENT_CONTROLLER:
            return {...state, contentContainerOpen: payload};

        case ActionTypes.STORE_FLOW_PLAIN_DATA:
            return {...state, flowPlainData: payload};

        //create new Container where new components can be added
        case ActionTypes.CREATE_FLOW_PLAIN_DATA:
            return {...state, flowPlainData: [...state.flowPlainData, payload]};

        //this action will sort the plain data incase of order change in content container
        case ActionTypes.UPDATE_FLOW_PLAIN_DATA:
            automationDetail.flowPlainData[payload.containerPath].inner_content = payload.plain_list
            return {...state, flowPlainData: automationDetail.flowPlainData};

        //this action will update flow container position and other data
        case ActionTypes.UPDATE_FLOW_CONTAINER_DATA:
            return {...state, flowPlainData: payload.flowPlainData};

        // This Action will add new component to the plain data onClick new message type
        case ActionTypes.ADD_FLOW_COMPONENT_PLAIN_DATA:
            return {...state, flowPlainData: payload.flowPlainData};

        case ActionTypes.ADD_FLOW_COMPONENT_QUICK_REPLY_PLAIN_DATA:
            return {...state, flowPlainData: payload.flowPlainData};

        // This Action will add new component to the plain data onClick new message type
        case ActionTypes.UPDATE_INNER_CONTENT_DATA:
            return {...state, flowPlainData: payload.flowPlainData};

        case ActionTypes.UPDATE_GALLERY_CONTENT_DATA:
            // automationDetail.flowPlainData[payload.containerPath].inner_content[payload.componentPath].data[payload.galleryIndex] = payload.data
            return {...state, flowPlainData: payload.flowPlainData};

        case ActionTypes.STORE_FLOW_DIAGRAM:
            return {...state, flowDiagram: payload};

        case ActionTypes.ACTIVE_FLOW_CONTAINER_PATH:
            return {...state, activeFlowContainerPath: payload};

        case ActionTypes.ACTIVE_FLOW_COMPONENT_PATH:
            return {...state, activeFlowComponentPath: payload};

        case ActionTypes.ACTIVE_FLOW_SUB_COMPONENT_PATH:
            return {...state, activeFlowSubComponentPath: payload};

        case ActionTypes.ACTIVE_FLOW_GALLERY_COMPONENT_PATH:
            return {...state, activeFlowGalleryComponentPath: payload};

        case ActionTypes.ACTIVE_HANDLE_COMPONENT:
            return {...state, activeHandleComponent: payload};

        case ActionTypes.UPDATE_FLOW_NAME:
            return {...state, flow_name: payload};

        case ActionTypes.CREATE_MESSENGER_EVENT:
            return {...state, messengerEvent: payload.messengerEventList};

        case ActionTypes.CLEAR_AUTOMATION_HISTORY:
            return {...state, flowPlainData: payload.flowPlainData, flowDiagram: payload.flowDiagram};

        default:
            return state;
    }
};
