import {ActionTypes} from "../contants/action-types";

const initialState = {
    total_flow: 0,
    automationFlowList: [],
};
export const setAutomationFlowList = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.FETCH_AUTOMATION_FLOW_LIST:
            return {...state, total_flow: payload.total_flow, automationFlowList: payload.automationFlowList};

        case ActionTypes.UPDATE_FLOW_STATUS:
            return {...state, automationFlowList: payload.automationFlowList};

        default:
            return state;
    }
};
