import {useDispatch, useSelector} from "react-redux";
import {Chip} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {removeTagCustomer} from "../../../redux/actions/pageContact";


function FaceIcon() {
    return null;
}

export default function ProfileTags() {
    const dispatch = useDispatch()
    const activeCustomerId = useSelector(state => state.pageContact.activeCustomerId)
    const activePage = useSelector(state => state.pageContact.page)
    const user_tag_list = useSelector(state => state.pageContact.page.contact_detail.find((contact) => contact.id === activeCustomerId)?.tag_list) || []
    const tag_list = useSelector(state => state.pageContact?.tag_list || [])
    const [flag, setFlag] = useState(true)
    const [tags, setTags] = useState([])

    const handleDelete = (tag_id) => {
        dispatch(removeTagCustomer(activeCustomerId, tag_id))
        setFlag(false)
    }


    useEffect(() => {
        let assigned_tag = []
        user_tag_list.map((value, index) =>
            assigned_tag.push(tag_list.find(obj => obj.id === value.tag_id))
        )
        setTags(assigned_tag)
        setFlag(true)
    }, [flag, user_tag_list]);

    return (
        <>
            {tags.map((value, i) => (
                <Chip variant="outlined" size="small" icon={<FaceIcon/>} label={value.tag}
                      onDelete={() => handleDelete(value.id)} color="secondary" key={i}/>
            ) )}

        </>
    )
}
