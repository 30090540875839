import React, {useEffect, useRef, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {Autocomplete} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";
import SaveIcon from "@material-ui/icons/Save";
import clsx from "clsx";
import TimelineIcon from '@material-ui/icons/Timeline';
import {fetchAutomationFlowList} from "../../../redux/actions/automationFlowList";
import SwitchFbPages from "../../../primaryComponent/switchFbPages";
import {
    addNewIceBreaker,
    addNewPersistentMenu, publishMessengerConfigurations,
    updateConfiguration,
    updateMessengerConfiguration
} from "../../../redux/actions/messengerConfigurationAction";
import {setSnackBar} from "../../../redux/actions/snackbar";


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    Layout: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }, mainContent: {
        width: '80%',
        margin: '40px auto'
    }, mainContentItems: {
        margin: '10px'
    }, controllerContainer: {
        display: "flex"
    },
    items: {
        margin: '10px'
    },
    previewContainer: {
        height: '100%',
        margin: '30px',
    },
    image: {
        width: '400px',
        height: '200px',
        objectFit: 'contain'
    }

}));

export default function IceBreaker() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const flowList = useSelector(state => {
        return state.flowList.automationFlowList || []
    })
    const icebreakerObj = useSelector(state => state.messengerConfig?.icebreaker) || null;
    const icebreakerList = useSelector(state => state.messengerConfig?.icebreaker?.ice_breakers) || [];
    const [createIcebreaker, setCreateIcebreaker] = useState(false)
    // const [newQuestion, setNewQuestion] = useState('')
    const [selectedFlow, setSelectedFlow] = useState(null)
    const newQuestion = useRef('')

    useEffect(() => {
        dispatch(fetchAutomationFlowList())
        setCreateIcebreaker(false)
        setSelectedFlow(null)
        // setNewQuestion('')
    }, [icebreakerObj])

    const updateObject = (i, question, flow_id) => {
        const data = {
            index: i,
            type: 'postback',
            question: question,
            flow_id: flow_id
        }
        dispatch(updateConfiguration('icebreaker', data))
    }

    const newIceBreaker = () => {
        const data = {
            question: newQuestion.current.value,
            flow_id: selectedFlow
        }
        dispatch(addNewIceBreaker(data))
    }

    const saveIceBreaker = () => {
        dispatch(updateMessengerConfiguration('icebreaker'))
    }

    const publishIceBreaker = () => {
        dispatch(publishMessengerConfigurations('icebreaker'))
    }

    const addMoreIceBreaker = () => {
        if (icebreakerList.length < 4) {
            setCreateIcebreaker(true)
        } else {
            dispatch(setSnackBar({status: true, message: "Ice Breaker is limited to 4.", alert: "error"}))
        }
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={12} sm={4} md={8} component={"div"} elevation={6}>
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Ice Breaker
                    </Typography>
                    <div className={classes.Layout}>
                        <Typography variant="h5" align="center" color="textSecondary" component="p">
                            Quickly build an effective pricing table for your potential customers with this layout.
                            It&apos;s built with default Material-UI components with little customization.
                        </Typography>
                        <SwitchFbPages config_type={"icebreaker"}/>
                        {icebreakerList.length < 4 && <Button variant="contained"
                                                              size="medium"
                                                              color="primary"
                                                              startIcon={<TimelineIcon/>}
                                                              onClick={() => addMoreIceBreaker()}
                                                              className={clsx(classes.items)}>
                            Create New Ice breaker
                        </Button>}

                        <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            className={clsx(classes.items)}
                            startIcon={<SaveIcon/>}
                            onClick={() => publishIceBreaker()}
                        >
                            Publish
                        </Button>

                        <Grid container spacing={3} className={classes.mainContent}
                              justifyContent={"center"}>
                            {createIcebreaker && <Grid item xs={12} direction={"column"}>
                                <div className={classes.mainContentItems}>
                                    <TextField
                                        inputRef={newQuestion}
                                        variant="outlined"
                                        placeholder="Question: Place you question here to start conversation."
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.controllerContainer}>
                                    <div className={classes.mainContentItems}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={flowList}
                                            getOptionLabel={(option) => option?.flow_name || ''}
                                            onChange={(e, value) => {
                                                return value ? setSelectedFlow(value.id) : ''
                                            }}
                                            style={{width: 300}}
                                            renderInput={(params) =>
                                                <TextField {...params}
                                                           placeholder={"Select your flow"}
                                                           variant="outlined"/>}
                                        />
                                    </div>
                                    <div className={classes.mainContentItems}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="medium"
                                            className={clsx(classes.items)}
                                            startIcon={<SaveIcon/>}
                                            onClick={() => newIceBreaker()}
                                        >
                                            Add
                                        </Button>
                                        <Button variant="contained"
                                                size="medium"
                                                color="primary"
                                                startIcon={<TimelineIcon/>}
                                                className={clsx(classes.items)}>
                                            View Flow
                                        </Button>
                                    </div>
                                </div>
                            </Grid>
                            }

                            {icebreakerObj !== null ?
                                icebreakerList.map((value, i) => {
                                    return (
                                        <Grid item xs={12} key={i}>
                                            <div className={classes.mainContentItems}>
                                                <TextField
                                                    value={value.question}
                                                    onChange={(e) => updateObject(i, e.target.value, value.flow_id)}
                                                    variant="outlined"
                                                    placeholder="Question: Place you question here to start conversation."
                                                    fullWidth
                                                />
                                            </div>
                                            <div className={classes.controllerContainer}>
                                                <div className={classes.mainContentItems}>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={flowList}
                                                        getOptionLabel={(option) => option?.flow_name || ''}
                                                        value={icebreakerObj !== null ? flowList.find((option) => option.id === value?.flow_id) || null : null}
                                                        onChange={(e, flowObject) => {
                                                            return value ? updateObject(i, value.question, flowObject.id) : ''
                                                        }}
                                                        style={{width: 300}}
                                                        renderInput={(params) =>
                                                            <TextField {...params}
                                                                       placeholder={"Select your flow"}
                                                                       variant="outlined"/>}
                                                    />
                                                </div>
                                                <div className={classes.mainContentItems}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="medium"
                                                        className={clsx(classes.items)}
                                                        startIcon={<SaveIcon/>}
                                                        onClick={() => saveIceBreaker()}
                                                    >
                                                        Save
                                                    </Button>
                                                    <Button variant="contained"
                                                            size="medium"
                                                            color="primary"
                                                            startIcon={<TimelineIcon/>}
                                                            className={clsx(classes.items)}>
                                                        View Flow
                                                    </Button>
                                                </div>
                                            </div>
                                        </Grid>)
                                }) : ''
                            }
                        </Grid>
                    </div>
                </div>
            </Grid>
            <Grid item xs={false} sm={8} md={4}>
                <div className={classes.previewContainer}>
                    <img src="/welcome_greeting.png" className={classes.image}></img>
                </div>

            </Grid>
        </Grid>
    );
}


