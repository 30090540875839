import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import React, {useEffect, useRef, useState} from "react";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {useDispatch, useSelector} from "react-redux";
import {
    activeFilterComponent,
    addNewTag, deleteClientTag,
    filterSelectedTag, filterUpdateSelectedTag,
    searchContactByTagId
} from "../../../redux/actions/pageContact";
import {Checkbox, FormControlLabel, FormGroup, FormLabel, TextField} from "@material-ui/core";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';

const useStyles = makeStyles((theme) => ({
    rootFilterComponent: {
        minWidth: '320px',
        backgroundColor: 'white',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        height: 'calc(100vh - 40px)',
        overflow: 'hidden',
        padding: '20px',
        zIndex: '10',
        borderRadius: '10px 0 0 10px',
        boxShadow: '0px 10px 20px rgb(0, 0, 0, 0.32)',
    },
    headerActions: {
        margin: '10px 10px 10px auto',
        textAlign: 'right',
    },
    tagLists: {
        height: '70vh',
        overflow: 'auto',
    },
    bodyFilterComponent: {
        width: 'fit-content',
        padding: '10px 20px',
        display: 'flex',
        flexDirection: 'column',
        gridGap: '20px'
    },
    contentContainerIcon: {
        width: '32px',
        height: '32px',
        padding: '4px',
        color: theme.palette.primary.main
    }, addTagsFilter: {
        display: 'flex',
        gridGap: '20px'
    },
    inputField: {
        width: '100%'
    },
    btnBgHC: {
        padding: '10px',
        width: '38px',
        height: '38px',
        border: `solid 1px ${theme.palette.background.subroot}`,
        borderRadius: '12px',
        '&:hover': {
            boxShadow: 'rgb(38, 57, 77) 0px 16px 15px -10px',
        }
    },
    iconHC: {
        width: '32px',
        height: '32px',
        padding: '4px',
        color: theme.palette.primary.main
    }, remove: {
        position: 'absolute',
        right: 0
    }
}))

export default function FilterComponent() {
    const classes = useStyles()
    const dispatch = useDispatch()
    const newTag = useRef('')
    const tag_list = useSelector(state => state.pageContact?.tag_list || [])
    const page = useSelector(state => state.pageContact?.page || [])
    const [tagError, setTagError] = useState({status: false, message: ''})
    const [tagSelected, setSelectedTags] = useState([])
    const [flag, setFlag] = useState(false)
    const tagg = useSelector(state => state.pageContact?.selectedTags || [])


    const closeFilterComponent = () => {
        dispatch(activeFilterComponent(false))
        if (tagSelected.length !== 0) {
            dispatch(searchContactByTagId(page?.page_id, tagSelected))
        }
    }

    const handleChange = (event) => {
        if (event.target.checked) {
            dispatch(filterSelectedTag(event.target.value))
            setFlag(true)
        } else {
            dispatch(filterUpdateSelectedTag(event.target.value))
            dispatch(searchContactByTagId(page?.page_id, tagg))
        }
    };

    useEffect(() => {
        if (flag && tagg.length !== 0) {
            dispatch(searchContactByTagId(page?.page_id, tagg))
            setFlag(false)
        }
    }, [flag])

    const addTag = () => {
        if (newTag.current.value !== '') {
            if (tag_list.find(obj => (obj.tag).toLowerCase() === newTag.current.value.toLowerCase())) {
                setTagError({status: true, message: 'Tag already exist'})
            } else {
                dispatch(addNewTag(newTag.current.value)).then(() => {
                })
                newTag.current.value = ''
            }
        } else {
            setTagError({status: true, message: 'Field cannot be empty'})
        }
    }

    const removeTag = (tag_id) => {
        dispatch(deleteClientTag(tag_id))
    }

    return (
        <div className={classes.rootFilterComponent}>
            <div className={classes.headerActions}><CloseRoundedIcon
                onClick={closeFilterComponent}/></div>
            <div className={classes.bodyFilterComponent}>
                <div>Add New Tags</div>
                <div className={classes.addTagsFilter}>
                    <div style={{width: '80%', marginTop: '3px'}}>

                        <TextField
                            error={tagError.status ? true : false}
                            helperText={tagError.status ? tagError.message : ''}
                            inputRef={newTag}
                            variant="outlined"
                            className={classes.inputField}
                            onChange={(e) => (tagError ? setTagError({status: false, message: ''}) : null)}
                        />

                    </div>
                    <div className={classes.btnBgHC}>
                        <AddRoundedIcon className={classes.iconHC} onClick={addTag}/>
                    </div>
                </div>
                <Divider variant="middle"/>

                <div>TAGS</div>
                <div className={classes.tagLists}>
                    <FormControl required component="fieldset" style={{width: '100%'}}>
                        <FormGroup>
                            {tag_list.map((value, i) => (
                                <div style={{display: "flex"}} key={i}>
                                    <FormControlLabel  value={value.id}
                                                      control={<Checkbox onChange={handleChange} name="gilad"/>}
                                                      label={value.tag}/>
                                    <HighlightOffRoundedIcon className={classes.remove}
                                                             onClick={() => removeTag(value.id)}/>
                                </div>
                            ))}

                        </FormGroup>
                        <FormHelperText>You can display an error</FormHelperText>
                    </FormControl>
                </div>

            </div>
        </div>
    )
}
