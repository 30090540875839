import React, {useCallback, useEffect, useRef, useState} from "react";
import ReactFlow, {addEdge, Background, Controls, ReactFlowProvider, ReactFlowInstance} from 'react-flow-renderer';
import '../index.css'
import {useDispatch, useSelector} from "react-redux";
import {
    activeFlowContainerPath, addFlowPlainData,
    contentControllerOpen, createMessengerEvent,
    setFlowDiagram, updateFlowContainerData,
} from "../../../redux/actions/automation";
import clsx from "clsx";
import useStyles from "./flowStyles";
import ContentContainer from "./contentContainer";
import Sidebar from "./sidebar";
import {ButtonOutputObject, DefaultObject, EdgeObject, Input, InputObject} from "./flowCoreData";
import inputCustomizedNode from "./asset/inputCustomizedNode";
import defaultCustomizedNode from "./asset/defaultCustomizedNode";
import outputCustomizedNode from "./asset/outputCustomizedNode";
import buttonOutputCustomizedNode from "./asset/buttonOutputCustomizedNode";
import ButtonEdge from './asset/ButtonEdge'

export default function AutomationFlowDiagram(props) {
    const classes = useStyles()
    const openContentContainer = useSelector(state => state.automation.contentContainerOpen)
    const dispatch = useDispatch();
    const [isContentLoaded, setLoadedContent] = useState(true)
    const initial_elements = useSelector(state => state.automation?.flowPlainData || [])
    // let elements = useSelector(state => state.automation?.flowDiagram || [])
    let elements = useSelector(state => state.automation?.flowDiagram)
    const [isContentSorted, setContentSorted] = useState(false) // Toggle value to continuous changes in List - Sortable JS
    const reactFlowWrapper = useRef(null);
    const activeAutomationFlowId = useSelector(state => state.automation?.activeAutomationFlowId)

    const style = {
        width: '100%',
    };

    const [reactFlowInstance, setReactFlowInstance] = useState(null);

    let convertFlowComponent = () => {
        const main_flow_list = []
        initial_elements.map((main_content, i) => {
            let type = main_content.flow_info.type;
            if (type === 'input') {
                main_flow_list.push(InputObject(main_content, i))
            } else if (type === 'default') {
                main_flow_list.push(DefaultObject(main_content, i))
            } else if (type === 'buttonOutput') {
                main_flow_list.push(ButtonOutputObject(main_content, i))
            } else if (type === 'edge') {
                main_flow_list.push(EdgeObject(main_content, i))
            }
        })
        return main_flow_list
    }

    useEffect(() => {
        console.log("Trigger FLow Builder")
        dispatch(setFlowDiagram(convertFlowComponent()))
    }, [isContentLoaded, isContentSorted, initial_elements])


    const onLoad = (_reactFlowInstance) => {
        setReactFlowInstance(_reactFlowInstance)
    }

    useEffect(() => {
        if (reactFlowInstance) {
            reactFlowInstance.fitView()
        }
    }, [reactFlowInstance]);


    const onConnect = (connection) => {
        connection['type'] = 'edge'
        let new_flow = addEdge(connection, elements) //returns list of nodes and edges
        let new_edge = new_flow[new_flow.length - 1] // get latest getting added edge
        let edge = {
            flow_info: new_edge,
        }
        dispatch(addFlowPlainData(edge))
        dispatch(createMessengerEvent(new_edge, activeAutomationFlowId))
    }

    const onDragOver = useCallback((event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    }, []);


    const onDrop = useCallback((event) => {
        event.preventDefault();
        let tempObj
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');

        if (typeof type === 'undefined' || !type) {
            return;
        }
        const containerCount = elements.filter(obj => obj.content_type === 'node').length
        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });

        tempObj = {
            flow_info: {
                id: (containerCount + 1).toString(),
                type,
                position
            },
            inner_content: []
        }
        dispatch(addFlowPlainData(tempObj))
    }, [reactFlowInstance, elements])

    const onNodeDragStop = (event, element) => {

        let instance = reactFlowInstance.toObject()
        let position = instance.elements.find(elm => elm.id === element.id).position
        // const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const flow_info = {
            id: element.id,
            type: element.type,
            position: position
        }
        dispatch(updateFlowContainerData(element.id, flow_info))
    }

    const onElementClick = (event, element) => {
        dispatch(activeFlowContainerPath(elements.findIndex(obj => obj.id === element.id)))
        dispatch(contentControllerOpen(true))
    }

    const nodeTypes = {
        input: inputCustomizedNode,
        default: defaultCustomizedNode,
        output: outputCustomizedNode,
        buttonOutput: buttonOutputCustomizedNode,
    };

    const edgeTypes = {
        buttonEdge: ButtonEdge,
    };

    return (
        <>
            <div className={clsx(classes.flowBuilderContainer, 'dndflow')}>

                <ReactFlowProvider>
                    <div className={clsx("reactflow-wrapper")} ref={reactFlowWrapper}>
                        <ReactFlow elements={elements}
                                   style={style}
                                   nodesConnectable={true}
                                   onConnect={onConnect}
                                   onLoad={onLoad}
                                   onDrop={onDrop}
                                   onDragOver={onDragOver}
                                   onNodeDragStop={onNodeDragStop}
                                   onElementClick={onElementClick}
                                   nodeTypes={nodeTypes}
                            // edgeTypes={edgeTypes}
                                   minZoom={0.1}
                        >
                            <Controls/>
                            <Background/>
                        </ReactFlow>
                    </div>
                    <Sidebar/>
                </ReactFlowProvider>
            </div>
            {openContentContainer &&
                <ContentContainer setContentSorted={setContentSorted}
                                  isContentSorted={isContentSorted}
                                  setLoadedContent={setLoadedContent}
                                  isContentLoaded={isContentLoaded}
                />}
        </>
    )
}