import React, {useEffect, useState} from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Logo from "../../../reuseableComponents/logo";
import * as RegisterForm from "../components/index";
import {Navigate} from "react-router-dom";
import CustomizedSnackbars from "../../../reuseableComponents/snackbar/snackbar";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        // backgroundColor:'#eeeeee'
    },
    paper: {
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(6),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#00adb5",
        "&:hover": {
            backgroundColor: "#393e46",
        },
    },
    banner: {
        fontWeight: "bold",
        color: "#00adb5",
        fontSize: 30,
    },
    sub_banner: {
        fontSize: 18,
        color: "#393e46",
        margin: theme.spacing(2, 0),
    },
}));

export default function SignUp(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState({status: false, message: "", type: ""});
    const [Email, setEmail] = useState("");
    const [FormName, RegistrationFormSelect] = useState("EmailForm");

    useEffect(() => {
        console.log(props)
        // if (props.formType === "ProfileForm") {
        // if (props?.location?.state === undefined){
        //     RegistrationFormSelect(FormName)
        // }
        // else if (props?.location?.state?.formType === "ProfileForm") {
        //     // RegistrationFormSelect(props.formType)
        //     RegistrationFormSelect(props.location.state.formType)
        // } else {
        //     RegistrationFormSelect(FormName)
        // }

        if (props.formType === "ProfileForm") {
            RegistrationFormSelect(props.formType)
        } else {
            RegistrationFormSelect(FormName)
        }
    }, [FormName]);


    useEffect(() => {
        const interval = window.setTimeout(() => {
            setOpen({status: false, message: "", type: ""})
        }, 6000);

        return () => {
            clearInterval(interval)
        }
    }, [open])

    if (localStorage.getItem("isLogged") === 'true' && localStorage.getItem("profile_status") === 'true') {
        return <Navigate to="/dashboard"/>;
    }
    // console.log(props.setDisplayBar)
    return (

        <Container component="main" maxWidth="xs" className={classes.mainContainer}>
            <CssBaseline/>
            <div className={classes.paper}>
                <Logo/>
                <Typography component="h1" variant="h5" className={classes.sub_banner}>
                    Sign up
                </Typography>
                {FormName === "EmailForm" && (
                    <MultipleFormRegistration
                        FormName={FormName}
                        method={RegistrationFormSelect}
                        changeEmail={setEmail}
                        setOpen={setOpen}
                        // setDisplayBar={props.setDisplayBar}
                    />
                )}
                {FormName === "OTPVerification" && (
                    <MultipleFormRegistration
                        FormName={FormName}
                        method={RegistrationFormSelect}
                        email={Email}
                        setOpen={setOpen}
                    />
                )}
                {FormName === "SignUpForm" && (
                    <MultipleFormRegistration
                        FormName={FormName}
                        method={RegistrationFormSelect}
                        email={Email}
                        setOpen={setOpen}
                    />
                )}
                {FormName === "ProfileForm" && (
                    <MultipleFormRegistration
                        FormName={FormName}
                        method={RegistrationFormSelect}
                    />
                )}

            </div>
            <Box mt={5}/>
            <CustomizedSnackbars open={open} setOpen={setOpen}/>
        </Container>
    );
}

export const MultipleFormRegistration = (Data) => {
    const FormType = RegisterForm[Data.FormName];
    return <FormType method={Data.method} changeEmail={Data.changeEmail} email={Data.email} setOpen={Data.setOpen}
                     setDisplayBar={Data.setDisplayBar}/>;
};
