import React from 'react';
import * as Yup from "yup";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Grid, makeStyles} from "@material-ui/core";
import TextFieldWrapper from "../../../reuseableComponents/Form_UI/TextFieldWrapper";
import ButtonWrapper from "../../../reuseableComponents/Form_UI/ButtonWrapper";
import Link from "@material-ui/core/Link";
import {Formik} from "formik";
import {useNavigate} from "react-router-dom";
import {postAPI} from "../../../config/api/config";
import {useDispatch} from "react-redux";
import {setSnackBar} from "../../../redux/actions/snackbar";


const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    redirectLink: {
        margin: theme.spacing(4, 0),
    },
}));


const INITIAL_FORM_STATE = {
    email: "",
};

const FORM_VALIDATION = Yup.object({
    email: Yup.string()
        .email("Invalid Email Address Pattern")
        .required("Email is required."),
});


function EmailForm(props) {
    const navigate = useNavigate();
    const classes = useStyles();
    const dispatch = useDispatch()

    const verifyEmail = async (values) => {
        const response = await postAPI('/api/user/sendVerificationCode/', values)
        await props.changeEmail(values.email);
        if (response.status === 200) {
            let res_data = JSON.parse(response.data)
            // await props.setOpen({status: true, message: res_data.message, type: "success"})
            dispatch(setSnackBar({status: true, message: res_data.message, type: "success"}))

            await props.method("OTPVerification");
        } else {
            let res_data = JSON.parse(response.data)
            // props.setOpen({status: true, message: res_data.message, type: "error"})
            dispatch(setSnackBar({status: true, message: res_data.message, alert: "error"}))
        }
    }


    const preventDefault = async (event) => {
        event.preventDefault();
        navigate("/");
    }

    return (
        <Formik
            initialValues={{...INITIAL_FORM_STATE}}
            validationSchema={FORM_VALIDATION}
            onSubmit={async (values) => {
                await verifyEmail(values);
            }}
        >
            <form className={classes.form}>
                <CssBaseline/>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextFieldWrapper name="email" label="Email" id="email"/>
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonWrapper children="Verify Email"/>
                    </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" className={classes.redirectLink}>
                    <Grid item xs={12} sm={7}>
                        <Link component="button" variant="body2" onClick={(e) => preventDefault(e)}>
                            Already have an account? Sign in
                        </Link>
                    </Grid>
                </Grid>
            </form>
        </Formik>
    );
}

export default EmailForm;
