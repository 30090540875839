import {ActionTypes} from "../contants/action-types";

const initialState = {
  account_setting: {},
};
export const accountSettingReducer = (state = initialState, {type, payload}) => {
  switch (type) {
    case ActionTypes.MANAGE_ACCOUNT_SETTING:
      return {...state, account_setting: payload.data};
    default:
      return state;
  }
};
