import {Navigate} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Navigation from "../primaryComponent/navigation";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    content: {
        flex: '2',
        backgroundColor: theme.palette.background.subroot,
        overflow: 'auto',
        display: 'flex',
    },
    container: {
        minWidth: '400px',
    }
}));

export function PrivateRoute({children}) {
    const classes = useStyles();
    const isLoggedIn = localStorage.getItem("isLogged") === 'true';
    const hasProfile = localStorage.getItem("profile_status") === 'true'
    if (isLoggedIn && hasProfile) {
        return (
            <Grid
                container
                className={classes.container}
                direction="row">
                <Navigation/>
                <div className={classes.content}>
                    {children}
                </div>
            </Grid>
        );
    } else {
        return <Navigate to="/"/>;
    }
}

export function PublicRoute({children}) {
    const isLoggedIn = localStorage.getItem("isLogged") === 'true';
    const hasProfile = localStorage.getItem("profile_status") === 'true'
    if (isLoggedIn && hasProfile) {
        return <Navigate to="/dashboard"/>;
    } else {
        return children;
    }
}

export function ConditionalRoute({children}) {
    const authed = localStorage.getItem("isLogged") === 'true';
    if (authed) {
        return children;
    } else {
        return <Navigate to="/"/>;
    }
}
