import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  banner: {
    fontWeight: "bold",
    // color: "#00adb5",
    fontSize: 30,
  },
}));

function Logo(props) {
  const classes = useStyles();
  let width;
  let height;
  if(props.width === undefined){
    width= "240"
  }else{
    width = props.width
  }

  if(props.height === undefined){
    height="50"
  }else{
    height = props.height;
  }

  return (
      <img src="/heap-chat.png" width={width} height={height} alt="Heap Chat" />
  );
}

export default Logo;
