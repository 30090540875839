import {ActionTypes} from "../contants/action-types";

const initialState = {
    target: null,
    quick_replies:{
        limit:20,
        value:''
    },
};
export const setTextCounterPopper = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.SET_TEXT_COUNTER:
            return {...state, target:payload.target};
        default:
            return state;
    }
};