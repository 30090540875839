import {Button, makeStyles} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setUserAuth} from "../../redux/actions/userAccount";
import {LoginFacebook} from "../../config/facebook/facebookMethods";
import {useInitFbSDK} from "../../hooks/fb-hooks";
import {postAPI} from "../../config/api/config";
import {fetchProfileDetail} from "../../helper/profile_detail";
import {fetchInitialData} from "../../helper/fetchInitialData";
import {setSnackBar} from "../../redux/actions/snackbar";

const FacebookButton = props => {

    const useStyles = makeStyles((theme) => ({
        root: {
            color: "#000",
            fontSize: 15,
            padding: theme.spacing(2, props.paddingLeft),
            "&:hover": {
                backgroundColor: "#393e46",
                color: "#FFF",
                boxShadow: "none",
            },
        },
    }));

    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fbUserAccessToken, setFbUserAccessToken] = useState('')
    const [userId, setUserId] = useState()

    const facebookLogin = async (e) => {
        await LoginFacebook(setUserId, setFbUserAccessToken);
    }

    useEffect(() => {
        if (fbUserAccessToken) {
            authorizeFacebook().then(() => {
            });
        }
    }, [fbUserAccessToken]);

    // This function authenticate user with server
    async function authorizeFacebook() {
        try {
            const response = await postAPI("/api/user/auth/facebook", {}, fbUserAccessToken);
            if (response.status === 200) {
                const user_auth = JSON.parse(response.data)
                console.log(user_auth)
                dispatch(setUserAuth(user_auth));
                localStorage.setItem("isLogged", 'true');
                localStorage.setItem("user_auth", JSON.stringify(user_auth));
                localStorage.setItem('profile_status', user_auth.profile_status);

                if (user_auth.profile_status) {
                    dispatch(setUserAuth(user_auth));
                    await fetchProfileDetail(user_auth.id, user_auth.access_token)
                    let pgCheck = await fetchInitialData(dispatch);
                    navigate(pgCheck ? "/dashboard" : "/modules");
                } else {
                    navigate("/profile");
                }

                dispatch(setSnackBar({status: true, message: "Logged in successfully", alert: "success"}))
            } else {
                dispatch(setSnackBar({status: true, message: "Failed to Login", alert: "error"}))
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Button
            variant="outlined"
            size="large"
            fullWidth={true}
            startIcon={<FacebookIcon/>}
            className={classes.root}
            onClick={(e) => facebookLogin(e)}
        >
            Facebook
        </Button>
    );
}

export default FacebookButton;
