import { Button, CssBaseline } from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(1),
    },
    btnContained: {
        backgroundColor: "#00adb5",
        padding: theme.spacing(2,12),
    },
}));


function PrimaryButton(props) {
    const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <Button fullWidth={true} varient="contained" color="primary" size="large" className={classes.btnContained}>
        {props.nameButton}
      </Button>
    </>
  );
}

export default PrimaryButton;
